iframe {
	border: none;
}

/*blank pages*/

#top.avia-blank {
	height: 100%;
	position: absolute;
	width: 100% !important;
	margin: 0;
	display: table;
	vertical-align: middle;
	float: none;
	top: 0;
	left: 0;
}

#top.boxed.avia-blank .container {
	margin: 0 auto;
}

#top.avia-blank #wrap_all {
	display: table-cell;
	float: none;
	vertical-align: middle;
}

#top.avia-blank #main .container_wrap:last-child {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

#top.avia-blank #main .container_wrap:first-child {
	border-top-style: solid;
	border-top-width: 1px;
}

/*default header: main logo and main menu height. increase max value if you want to use a bigger logo*/
#header_main .container,
.main_menu ul:first-child > li a {
	height: 88px;
	line-height: 88px;
}

/*header with social icons: */
.social_header #header_main .container,
.social_header .main_menu ul:first-child > li a {
	height: 116px;
	line-height: 116px;
}

/*header with social icons and bottom nav */
.bottom_nav_header.social_header #header_main .container {
	height: 88px;
	line-height: 88px;
}

.bottom_nav_header.social_header .main_menu ul:first-child > li a {
	height: 35px;
	line-height: 35px;
}

.social_header .phone-info {
	float: right;
	font-weight: bold;
}

.social_header .phone-info span {
	padding: 0px 0 0 10px;
	margin: 0px 0 0 10px;
}

.social_header .phone-info.with_nav span {
	border-left-style: solid;
	border-left-width: 1px;
}

.fixed_header #main {
	padding-top: 88px;
}

/* padding of the main container should be equal height as the header height above*/
.fixed_header.social_header #main {
	padding-top: 147px;
}

/* padding of the main container when a fixed header with social elements is selected*/
.fixed_header #header {
	position: fixed;
}

.html_boxed.fixed_header #header {
	width: auto;
}

.html_boxed #main {
	z-index: 2;
	overflow: hidden;
}

#header {
	position: relative;
	z-index: 400;
	width: 100%;
	background-color: transparent;
	top: 32px;
}

#header_meta {
	position: absolute;
	top: 0;
	border-top: none;
	box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
	z-index: 400;
	min-height: 30px;
	z-index: 500;
	color: #111;
	background-color: #f8f8f8;
	left: 0;
	right: 0;
}

#header_meta .phone-info {
	height: auto;
	line-height: 1;
	font-size: 11px;
	padding: 5px 0;
}

#header_meta a {
	color: #111;
}

#header_meta .container {
	min-height: 30px;
}

#header_main {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	z-index: 1;
	border-top: none;
}

#header.shadow {
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.header_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.95;
	filter: alpha(opacity=95); /* For IE8 and earlier */
	z-index: 0;
}

.nonfixed_header .header_bg {
	opacity: 1;
	filter: alpha(opacity=1);
}

/*logo*/

div .logo {
	float: left;
	position: absolute;
	left: 0;
	z-index: 1;
}

.logo,
.logo a {
	overflow: hidden;
	position: relative;
	display: block;
	height: 100%;
}

.logo img {
	padding: 5px 0;
	display: block;
	width: auto;
	height: auto;
	max-height: 100%;
}

/*menu*/
.main_menu {
	clear: none;
	position: absolute;
	z-index: 100;
	line-height: 30px;
	height: 100%;
	margin: 0;
	right: 0;
}

.main_menu ul {
	margin: 0;
	padding: 0;
}

.main_menu ul:first-child > li > ul {
	border-top-width: 2px;
	border-top-style: solid;
}

.main_menu .pointer_arrow_wrap {
	display: none;
}

.main_menu ul:first-child > li {
	line-height: 30px;
}

.main_menu ul:first-child > li > a {
	display: block;
	text-decoration: none;
	padding: 0 13px;
	font-weight: normal;
	font-size: 12px;
	font-weight: 600;
	font-size: 13px;
}

.main_menu .menu li:first-child > a {
	border-top: none;
}

.main_menu .menu ul li:last-child > a {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.main_menu ul:first-child > li.current-menu-item > a,
.main_menu ul:first-child > li.current_page_item > a {
	font-weight: 600;
}

.main_menu .menu li a strong {
	display: block;
	font-size: 14px;
	font-weight: normal;
	cursor: pointer;
}

.main_menu .menu li {
	position: relative;
	z-index: 20;
}

.main_menu .menu li:hover {
	z-index: 100;
}

#top .main_menu ul:first-child > li > ul > li,
#top .main_menu ul:first-child > li > ul > li li {
	margin: 0;
	padding: 0;
	width: 100%;
}

.main_menu .menu ul {
	display: none;
	margin-left: 0;
	left: 0;
	position: absolute;
	top: 100%;
	width: 208px;
	z-index: 2;
	padding: 0 0;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	margin-top: -1px;
}

/* fixes IE8 menu width issue*/
.main_menu .menu li a {
	max-width: none;
}

.main_menu .menu ul li a {
	border-right-style: solid;
	border-right-width: 1px;
	border-left-style: solid;
	border-left-width: 1px;
}

.main_menu .menu li ul a {
	width: 100%;
	height: auto;
	float: left;
	text-align: left;
	line-height: 23px;
	padding: 8px 15px;
	font-size: 12px;
	min-height: 23px;
	max-width: none;
	text-decoration: none;
}

.main_menu .menu ul ul {
	top: auto;
	padding-top: 0px !important;
}

.main_menu .menu li ul ul {
	left: -207px;
	top: 0px;
	margin: 0;
	border-top-style: solid;
	border-top-width: 1px;
}

.main_menu .menu li:hover ul ul,
.main_menu .menu li:hover ul ul ul,
.main_menu .menu li:hover ul ul ul ul {
	display: none;
}

.main_menu .menu li:hover ul,
.main_menu .menu li li:hover ul,
.main_menu .menu li li li:hover ul,
.main_menu .menu li li li li:hover ul {
	display: block;
}

.current_page_item > a,
.current-menu-item > a {
	font-weight: 600;
}

.avia-menu-fx {
	position: absolute;
	bottom: -1px;
	height: 2px;
	z-index: 10;
	width: 100%;
	left: 0;
	opacity: 0;
	visibility: hidden;
}

li:hover .avia-menu-fx,
.current-menu-item > a > .avia-menu-fx,
li:hover .current_page_item > a > .avia-menu-fx {
	opacity: 1;
	visibility: visible;
}

.avia-menu-fx .avia-arrow-wrap {
	height: 10px;
	width: 10px;
	position: absolute;
	top: -10px;
	left: 50%;
	margin-left: -5px;
	overflow: hidden;
	display: none;
}

.current-menu-item > a > .avia-menu-fx > .avia-arrow-wrap,
.current_page_item > a > .avia-menu-fx > .avia-arrow-wrap {
	display: block;
}

.avia-menu-fx .avia-arrow-wrap .avia-arrow {
	top: 10px;
}

/*mega menu styles*/
.main_menu .avia-bullet {
	display: none;
}

#top #header .menu-item-mega-parent.current-menu-item {
	overflow: visible !important;
}

#top #header .mega_menu_title a {
	font-size: 17px;
	line-height: 1.1em;
	padding: 0;
	margin: 0;
	background: transparent;
	border: none;
}

#top #header .mega_menu_title a:hover {
	text-decoration: underline;
}

#header .avia_mega_div {
	/* use similar styles to .main_menu .menu ul */
	display: none;
	margin: -5px 0 0 0;
	right: 0;
	position: absolute;
	top: 100%;
	z-index: 2;
	padding: 4px 0;
	-moz-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	padding: 5px 0 0;
}

#header .avia_mega_div .units {
	padding: 0 14px 0 15px;
	margin: 0;
	border-right-style: dotted;
	border-right-width: 1px;
}

#header li:hover .avia_mega_div {
	display: block;
}

#top #header .avia_mega_div ul,
#top #header .avia_mega_div li {
	/*reset list styles for mega menus*/
	position: relative;
	display: block;
	top: auto;
	left: auto;
	height: auto;
}

#top #header .avia_mega_div .sub-menu {
	overflow: hidden;
	width: 100%;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-style: none;
	border-width: 0px;
	position: relative;
	top: 0;
	display: block;
	left: 0;
}

/*wrapper arround all columns*/
#top #header .avia_mega_div > .sub-menu {
	display: table;
	padding: 20px 30px 30px;
	border-top-style: solid;
	border-top-width: 2px;
}

#top #header .avia_mega_div > .sub-menu.avia_mega_hr {
	padding-top: 30px;
}

/*columns*/
#top #header .avia_mega_div > .sub-menu > li {
	display: table-cell;
	float: none;
	padding-top: 10px;
	padding-bottom: 0;
	vertical-align: top;
}

#top #header .avia_mega_div > .sub-menu.avia_mega_hr {
	border-top-width: 1px;
	border-top-style: dashed;
}

/*columns inner*/
#top #header .avia_mega_div > .sub-menu > li > ul {
	padding: 0;
}

/*column lists*/
#top #header .avia_mega_div > .sub-menu > li > ul li {
	display: block;
	float: none;
	padding: 0;
	margin: 0;
	list-style-type: circle;
	list-style-position: inside;
}

/*nested column lists*/
#top #header .avia_mega_div > .sub-menu > li > ul ul li {
	margin-left: 15px;
}

/*column lists links*/
#top #header .avia_mega_div > .sub-menu > li > ul > li a {
	width: auto;
	float: none;
	display: block;
	border: none;
	padding: 3px 12px 3px 12px;
	font-weight: normal;
}

#header .avia_mega_div .avia_mega_menu_columns_first {
	padding-left: 0;
}

#header .avia_mega_div .avia_mega_menu_columns_last {
	padding-right: 0;
	border-right-style: none;
	border-right-width: 0;
}

.avia-bullet {
	display: block;
	position: absolute;
	height: 0;
	width: 0;
	top: 51%;
	margin-top: -3px;
	left: -3px;
	border-top: 3px solid transparent !important;
	border-bottom: 3px solid transparent !important;
	border-left: 3px solid green;
}

.avia_mega_div .avia-bullet {
	margin-top: 12px;
	left: 3px;
	display: block;
	top: 0;
}

/*mega text blocks*/
#header .mega_menu_title {
	margin-bottom: 8px;
	font-size: 17px;
	line-height: 1.1em;
	font-weight: 600;
	display: block;
}

#header .avia_mega_text_block {
	line-height: 21px;
}

#top #header .avia_mega_div .sub-menu .avia_mega_text_block a {
	padding: 0;
	display: inline;
	border: none;
	text-decoration: underline;
}

/*ajax search*/

#top #menu-item-search {
	z-index: 100;
}

#top .menu-item-search-dropdown > a,
#searchform #searchsubmit,
.ajax_search_image,
.iconfont {
	font-size: 17px;
}

#top #menu-item-search.menu-item-search-dropdown > a {
	border: none;
}

.avia-search-tooltip {
	position: absolute;
	z-index: 9999999;
	padding: 0;
	width: 300px;
	top: 85% !important;
	margin-left: -120px;
	border-radius: 2px;
	box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2);
	border-width: 1px;
	border-style: solid;
}

.avia-search-tooltip .avia-arrow-wrap {
	width: 20px;
	height: 20px;
	position: absolute;
	top: -20px;
	right: 10px;
	margin-left: -10px;
	overflow: hidden;
}

.avia-arrow {
	height: 10px;
	width: 10px;
	position: absolute;
	top: -6px;
	left: 50%;
	margin-left: -5px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	transform: rotate(45deg);
	border-width: 1px;
	border-style: solid;
	visibility: hidden;
}

.avia-search-tooltip .avia-arrow {
	top: 15px;
}

#top #searchform {
	margin: 0;
	padding: 0;
}

#top #searchform > div {
	position: relative;
}

#top .widget #searchform > div {
	margin: 0;
}

#top #searchform br {
	display: none;
}

#top #s {
	width: 100%;
	position: relative;
	padding: 11px 110px 11px 5px;
	z-index: 1;
	margin: 0;
	box-shadow: none;
}

#top #searchsubmit,
.ajax_load {
	width: 40px;
	height: 39px;
	line-height: 40px;
	padding: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 2;
	margin: 0;
	border-radius: 0;
	min-width: 40px;
}

.avia_mobile #top #searchsubmit,
.avia_mobile .ajax_load {
	height: 41px;
}

#top #searchsubmit {
	width: 105px;
}

.avia-search-tooltip #searchform > div {
	margin: 16px;
}

.ajax_load {
	z-index: 5;
}

.ajax_load_inner {
	background: url("../images/layout/loading.gif") no-repeat scroll center center #fff;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#top #searchform .ajax_search_response {
	line-height: 1.4em;
	font-size: 12px;
	margin: 0;
}

.ajax_search_response h4 {
	padding: 20px 16px 2px 16px;
	font-size: 14px;
	margin: 0;
}

.ajax_search_response h4:first-child {
	padding-top: 0px;
	border: none;
}

#top div .ajax_search_entry {
	display: block;
	text-decoration: none;
	line-height: 1.4em;
	font-size: 12px;
	height: auto;
	padding: 10px 16px;
	border-bottom-style: dashed;
	border-bottom-width: 1px;
	clear: both;
	overflow: hidden;
}

#top div .ajax_search_entry.with_image .ajax_search_image {
	background: transparent;
}

.ajax_search_content {
	overflow: hidden;
	display: block;
}

.ajax_search_title {
	display: block;
	font-weight: bold;
	text-transform: uppercase;
}

.ajax_not_found .ajax_search_title {
	text-transform: none;
}

.ajax_search_image {
	height: 32px;
	line-height: 32px;
	text-align: center;
	width: 32px;
	float: left;
	margin-right: 8px;
	border-radius: 40px;
	overflow: hidden;
	font-size: 15px;
}

.ajax_search_image img {
	display: block;
	border: none;
	max-width: 100%;
	min-height: 32px;
	min-width: 32px;
}

.ajax_search_excerpt {
	font-size: 12px;
	line-height: 1.4em;
	display: block;
	margin-top: 3px;
	font-style: italic;
}

#top div .ajax_search_entry_view_all {
	text-align: center;
	font-weight: bold;
	border: none;
}

#top div .ajax_not_found,
#top div .ajax_search_entry.ajax_not_found:hover {
	border: none;
	background: transparent;
}

/*title container*/

/*avia title big*/
.title_container {
	position: relative;
}

#top .title_container .container {
	padding: 10px 0;
	min-height: 56px;
}

.title_container .main-title {
	margin: 0;
	font-size: 16px;
	position: relative;
	z-index: 2;
	min-height: 36px;
	line-height: 2.3em;
	top: 0;
	font-weight: 400;
}

.title_meta,
#top .portfolio-entry .title_meta {
	display: block;
	clear: both;
	position: relative;
	z-index: 1;
	margin-top: -1em;
	padding: 0;
}

.title_meta p {
	margin: 1.3em 0 0 0;
}

.title_container .breadcrumb {
	z-index: 10;
	line-height: 15px;
	font-size: 11px;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -7px;
}

.breadcrumb a {
	text-decoration: none;
}

.breadcrumb a:hover {
	text-decoration: underline;
}

.breadcrumb-trail .trail-before,
.breadcrumb-trail .trail-end,
.breadcrumb-trail .sep,
.breadcrumb-trail a,
.breadcrumb-trail .bbp-breadcrumb-current {
	display: block;
	float: left;
	padding: 0px 3px;
}

.breadcrumb-trail span,
.bbp-breadcrumb-current a {
	display: inline;
	padding: 0;
	float: none;
}

.breadcrumb .sep {
	display: block;
	overflow: hidden;
	width: 8px;
}

/*prettyphoto enhancements*/

div.pp_default div .pp_content {
	background-color: #f8f8f8;
	padding-bottom: 5px;
}

div.pp_default iframe {
	width: 100%;
}

.pp_default div .pp_description {
	overflow: hidden;
}

div.pp_default div.pp_content_container .pp_details {
	margin-top: 12px;
}

div div.ppt {
	font-size: 12px;
	font-weight: bold;
}

div.pp_loaderIcon {
	margin: -12px 0 0 -16px;
}

.pp_gallery {
	display: none !important;
}

body div.pp_default .pp_description {
	margin: 9px 50px 5px 0;
	color: #666;
}

/*--------------------alternate header styles---------------*/

/*bottom nav menu*/
.bottom_nav_header.social_header .logo {
	position: relative;
}

.bottom_nav_header.social_header .main_menu,
.bottom_nav_header.social_header .main_menu {
	clear: both;
	line-height: 40px;
	width: 100%;
	float: left;
}

.bottom_nav_header.social_header .main_menu > div,
.bottom_nav_header.social_header .main_menu ul:first-child {
	width: 100%;
}

.bottom_nav_header.social_header .main_menu ul:first-child > li:first-child a {
	border-left-style: solid;
	border-left-width: 1px;
}

.bottom_nav_header.social_header .main_menu ul:first-child > li a {
	border-right-style: solid;
	border-right-width: 1px;
	font-size: 12px;
}

.bottom_nav_header.social_header #top .main_menu ul:first-child > li:hover {
	z-index: 1000;
	position: relative;
}

.bottom_nav_header.social_header .main_menu .menu-item-search-dropdown {
	float: right;
}

#header_main_alternate {
	border-top: none;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	z-index: 2;
}

#header_main_alternate .main_menu .menu li ul ul {
	left: 207px;
}

#header_main_alternate .avia_mega_div {
	right: auto;
	left: 0;
}

/*social bookmarks*/

#top .social_bookmarks {
	height: 30px;
	position: absolute;
	z-index: 500;
	top: 100px;
	right: 0;
	left: 0;
	text-align: center;
	display: block;
}

@media (min-width: 768px) {
	#top .social_bookmarks {
		top: 60px;
		left: auto;
	}
}

#top .social_bookmarks li {
	height: 100%;

	padding: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-right-style: solid;
	border-right-width: 1px;
	display: block;
	border: none;
	text-align: center;
	width: 100%;
}

@media (min-width: 768px) {
	#top .social_bookmarks li {
		float: left;
		width: auto;
	}
}

#top .social_bookmarks li:last-child {
	border-right: none;
}

#top .social_bookmarks li a {
	min-width: 30px;
	line-height: 32px;
	display: block;
	margin: 0px;
	outline: none;
	padding: 0 8px;
	min-height: 30px;
	height: 100%;
	overflow: hidden;
	z-index: 2;
	position: relative;
	text-align: center;
	color: #111;
}

#top .social_bookmarks li a:hover {
	text-decoration: none;
}

#top .social_bookmarks_rss:hover a {
	color: #fff;
	background-color: #ffa133;
}

#top .social_bookmarks_facebook:hover a {
	color: #fff;
	background-color: #37589b;
}

#top .social_bookmarks_twitter:hover a {
	color: #fff;
	background-color: #46d4fe;
}

#top .social_bookmarks_mail:hover a {
	color: #fff;
	background-color: #9fae37;
}

#top .social_bookmarks_dribbble:hover a {
	color: #fff;
	background-color: #e44885;
}

#top .social_bookmarks_linkedin:hover a {
	color: #fff;
	background-color: #419cca;
}

#top .social_bookmarks_search:hover a {
	color: #fff;
	background-color: #222222;
}

#top .social_bookmarks_gplus:hover a {
	color: #fff;
	background-color: #de5a49;
}

#top .social_bookmarks_behance:hover a {
	color: #fff;
	background-color: #008cfa;
}

#top .social_bookmarks_flickr:hover a {
	color: #fff;
	background-color: #ff0086;
}

#top .social_bookmarks_forrst:hover a {
	color: #fff;
	background-color: #234317;
}

#top .social_bookmarks_myspace:hover a {
	color: #fff;
	background-color: #000000;
}

#top .social_bookmarks_tumblr:hover a {
	color: #fff;
	background-color: #345574;
}

#top .social_bookmarks_vimeo:hover a {
	color: #fff;
	background-color: #31baff;
}

#top .social_bookmarks_youtube:hover a {
	color: #fff;
	background-color: #a72b1d;
}

#top .social_bookmarks_pinterest:hover a {
	color: #fff;
	background-color: #cb2027;
}

#top .social_bookmarks_skype:hover a {
	color: #fff;
	background-color: #12a5f4;
}

#top .social_bookmarks_instagram:hover a {
	color: #fff;
	background-color: #a67658;
}

#top .social_bookmarks_five_100_px:hover a {
	color: #fff;
	background-color: #222222;
}

#top .social_bookmarks_soundcloud:hover a {
	color: #fff;
	background-color: #f76700;
}

#top .social_bookmarks_xing:hover a {
	color: #fff;
	background-color: #006567;
}

.social_bookmarks li {
	border: none;
}

.social_bookmarks li a {
	border-radius: 100px;
}

/*first level*/
.sub_menu {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 2;
	font-size: 11px;
	margin-top: -4px;
	line-height: 30px;
}

#top .sub_menu > ul {
	float: left;
}

#top .sub_menu > ul,
#top .sub_menu > ul > li {
	background: transparent;
}

.sub_menu li {
	float: left;
	position: relative;
	padding: 0 10px;
	border-right-style: solid;
	border-right-width: 1px;
	line-height: 10px;
}

.sub_menu > ul > li:last-child,
.sub_menu > div > ul > li:last-child {
	border: none;
	padding-right: 0;
}

.sub_menu > ul > li > a,
.sub_menu > div > ul > li > a {
	text-decoration: none;
	font-weight: bold;
	padding: 10px 0;
}

/*second level*/
#top .sub_menu li ul {
	display: none;
	position: absolute;
	width: 170px;
	padding: 4px 0;
	z-index: 101;
	-moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	left: -50%;
	margin: 0;
	border-style: solid;
	border-width: 1px;
	top: 19px;
}

#top .sub_menu li:hover > ul {
	display: block;
}

.sub_menu > ul > li:hover > a {
	text-decoration: underline;
}

.sub_menu li li {
	float: none;
	line-height: 20px;
	border: none;
	padding: 0 0;
	margin: 0;
}

#top .sub_menu li li a {
	width: 100%;
	height: auto;
	text-align: left;
	line-height: 23px;
	padding: 6px 18px;
	font-size: 12px;
	min-height: 23px;
	max-width: none;
	text-decoration: none;
	display: block;
	border-top-style: dashed;
	border-top-width: 1px;
}

#top .sub_menu li li:first-child > a {
	border: none;
}

#top .sub_menu li li a:hover {
	text-decoration: none;
	background: #f8f8f8;
}

/*third level and deeper*/
#top .sub_menu li li ul {
	top: -1px;
	left: -169px;
	background: none;
	padding: 0;
}

.pointer_arrow {
	border-style: solid;
	border-width: 1px;
}

/* ======================================================================================================================================================
#Blog Styles
====================================================================================================================================================== */

.content,
.sidebar {
	padding-top: 25px;
	padding-bottom: 25px;
	-webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: content-box; /* Firefox, other Gecko */
	box-sizing: content-box;
	min-height: 1px;
	z-index: 1;
	@media (min-width: $screen-sm-min) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

/*right sidebar - default*/
#top #main .sidebar {
	margin-left: 0;
	float: none;
	width: 100%;
	overflow: hidden;
	display: block;
	clear: none;
	@media (min-width: $screen-sm-min) {
		width: auto;
		border-left-style: solid;
		border-left-width: 1px;
	}

	.inner_sidebar {
		@media (min-width: $screen-sm-min) {
			margin-left: 50px;
		}
	}
}

.content {
	border-right-style: solid;
	border-right-width: 1px;
	margin-right: -1px;
}

.content .entry-content-wrapper {
	padding-right: 50px;
}

/*
.content .entry-content-wrapper p a{
display: inline-block;
}
*/

.template-blog .blog-meta {
	float: left;
	margin-right: 50px;
}

.multi-big .post_author_timeline,
.single-small .post_author_timeline {
	position: absolute;
	top: 0;
	left: 40px;
	width: 1px;
	height: 100%;
	border-right-width: 1px;
	border-right-style: dashed;
}

.single-post .post_author_timeline {
	display: none;
}

/*left sidebar*/
#top #main .sidebar_left .sidebar {
	border-right-style: solid;
	border-right-width: 1px;
	border-left: none;
}

.sidebar_left .inner_sidebar {
	margin-right: 50px;
	margin-left: 0;
}

.sidebar_left .content {
	float: right;
	border-left-style: solid;
	border-left-width: 1px;
	border-right: none;
	margin-right: -50px;
	margin-left: -1px;
	padding-left: 50px;
}

.sidebar_left .template-blog .blog-meta {
	float: right;
}

.sidebar_left .multi-big .post_author_timeline,
.sidebar_left .single-small .post_author_timeline {
	left: auto;
	right: 90px;
}

.sidebar_left .big-preview {
	padding: 0 131px 10px 0;
}

div .single-big .pagination {
	padding: 1px 50px 10px 1px;
}

/*
.sidebar_left  .big-preview.single-big {
padding: 0 0 10px 0;
}
*/

.sidebar_left .author-extra-border {
	right: auto;
	left: -50px;
}

/*no sidebar*/
.fullsize .content {
	margin: 0;
	border: none;
}

.fullsize .content .entry-content-wrapper {
	padding-right: 0;
}

#top .fullsize .template-blog .post-title {
	text-align: center;
	font-size: 30px;
	padding: 15px 0;
	max-width: 800px;
	margin: 0 auto;
}

#top.single-post .fullsize .template-blog .post_delimiter {
	visibility: hidden;
}

#top .fullsize .template-blog .post-meta-infos {
	text-align: center;
}

#top .fullsize .template-blog .post .entry-content-wrapper {
	text-align: justify;
	font-size: 15px;
	line-height: 25px;
	max-width: 800px;
	margin: 0 auto;
	overflow: visible;
}

#top .fullsize .template-blog .post .entry-content-wrapper > * {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

#top .fullsize .template-blog .post_delimiter {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	width: 3000px;
	left: -1500px;
	position: relative;
	max-width: 3000px;
}

#top .fullsize .template-blog .post_author_timeline {
	display: none;
}

#top .fullsize .template-blog .blog-meta {
	float: none;
	margin: 0 auto;
	display: block;
	position: relative;
	width: 81px;
	overflow: hidden;
	text-align: center;
	z-index: 1000;
}

#top .fullsize .related_entries_container img {
	margin: 0 auto;
}

#top .fullsize .related_title {
	text-align: center;
}

#top .fullsize .related_posts {
	padding: 23px 0 33px 0;
}

#top .fullsize .template-blog .big-preview a,
#top .fullsize .template-blog .small-preview img {
	float: none;
	display: inline-block;
	width: 100%;
	max-width: 100%;
}

#top .fullsize .template-blog .first-quote {
	margin-top: 15px;
}

#top .fullsize .template-blog .big-preview.multi-big {
	margin-bottom: -48px;
	padding: 0;
	width: 100%;
}

#top .fullsize .template-blog .big-preview.multi-big a,
.fullsize div .template-blog .big-preview.multi-big a img {
	width: 100%;
}

#top .fullsize .template-blog .big-preview img {
	width: 100%;
}

.fullsize .big-preview .avia-gallery {
	width: 600px;
	max-width: 100%;
	margin: 0 auto;
}

.fullsize .comment_content {
	padding-right: 0;
}

.fullsize .blog-tags {
	display: block;
}

/*sidebar doenst matter: */

.post {
	clear: both;
	width: 100%;
	float: left;
	position: relative;
}

.template-blog .post .entry-content-wrapper {
	/*
font-size: 14px;
line-height: 1.7em;
*/
	overflow: hidden;
}

.template-blog .post_delimiter {
	margin: 0 0 50px 0;
	padding: 50px 0 0 0;
	/*
border-bottom-style: solid;
border-bottom-width: 1px;
*/
	clear: both;
}

.template-blog .post-entry-last .post_delimiter {
	border: none;
	height: 1px;
}

.rounded-container,
.rounded-container img {
	border-radius: 111px;
	overflow: hidden;
	display: block;
	position: relative;
	z-index: 2;
}

.rounded-container {
	float: left;
	width: 81px;
	height: 81px;
	text-align: center;
	line-height: 81px;
}

.rounded-container .iconfont,
.small-preview .iconfont {
	font-size: 23px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
}

.entry-content-wrapper .post-title {
	font-size: 21px;
	line-height: 1.3em;
}

.post-meta-infos {
	font-size: 12px;
	position: relative;
	top: -8px;
	display: block;
}

.post-meta-infos a {
	text-decoration: none;
}

.post-meta-infos a:hover {
	text-decoration: underline;
}

.text-sep {
	padding: 0 5px;
}

.more-link {
	clear: both;
	display: inline-block;
}

/*previe pic*/

.big-preview {
	display: block;
	padding: 0 50px 10px 131px;
}

.template-page .big-preview {
	display: block;
	padding: 0 0 10px 131px;
	text-align: center;
}

.big-preview a {
	display: block;
	position: relative;
}

.big-preview.single-big {
	padding: 0 50px 10px 0;
}

.fullsize .big-preview.single-big {
	padding: 0 0 10px 0;
}

.post-loop-1 .big-preview {
	position: relative;
	z-index: 4;
}

.small-preview {
	width: 81px;
	height: 81px;
	overflow: hidden;
	border-radius: 4px;
	float: left;
	margin: 6px 0 0 0;
	position: relative;
	text-align: center;
	line-height: 81px;
	position: relative;
	z-index: 4;
	display: block;
}

.small-preview img,
.big-preview img {
	display: block;
	border-radius: 4px;
	position: relative;
	z-index: 4;
}

.single-post .single-small.with-slider .small-preview {
	width: 180px;
	height: 180px;
}

.single-post .single-small.with-slider .post_author_timeline {
	display: none;
}

#top.single-post .fullsize .single-small.with-slider .blog-meta {
	width: 180px;
}

/*pagination*/

.pagination {
	clear: both;
	padding: 10px 0px 50px 0;
	position: relative;
	z-index: 3;
	line-height: 13px;
	overflow: hidden;
}

.pagination span,
.pagination a {
	display: block;
	float: left;
	font-size: 11px;
	line-height: 13px;
	padding: 2px 9px 1px 9px;
	text-decoration: none;
	width: auto;
}

#top .pagination .current,
#top .pagination a,
#top .fullsize .template-blog .pagination a {
	float: left;
	height: 35px;
	width: 35px;
	line-height: 34px;
	text-align: center;
	padding: 0;
	border-radius: 100px;
	margin-right: 3px;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.pagination .current {
	font-size: 11px;
	padding: 1px 9px 1px 9px;
	font-weight: bold;
}

.pagination .pagination-meta {
	float: right;
	line-height: 35px;
}

.template-blog .pagination {
	padding: 1px 50px 10px 24px;
}

/*single post navigation*/

#top .avia-post-nav {
	position: fixed;
	height: 110px;
	top: 50%;
	background: #aaa;
	background: rgba(0, 0, 0, 0.1);
	color: #fff;
	margin-top: -55px;
	padding: 15px;
	text-decoration: none;
	z-index: 200;
}

#top .avia-post-nav:hover {
	background: #222;
	background: rgba(0, 0, 0, 0.8);
}

.avia-post-nav .label {
	position: absolute;
	top: 50%;
	height: 22px;
	line-height: 22px;
	margin-top: -11px;
	font-size: 24px;
}

.avia-post-nav .entry-image {
	height: 80px;
	width: 80px;
	display: block;
}

.avia-post-nav .entry-image img {
	border-radius: 100px;
	display: block;
}

.avia-post-prev {
	left: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.avia-post-prev .label {
	left: 10px;
}

.avia-post-next {
	right: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.avia-post-next .label {
	right: 10px;
}

.avia-post-next.with-image {
	text-align: right;
}

.avia-post-nav .entry-info {
	display: block;
	height: 80px;
	width: 220px;
	display: table;
}

.avia-post-next .entry-info {
	margin: 0 20px 0 1px;
}

.avia-post-prev .entry-info {
	margin: 0 1px 0 20px;
}

.avia-post-nav .entry-info span {
	display: table-cell;
	vertical-align: middle;
	font-size: 13px;
	line-height: 1.65em;
}

.avia-post-nav .entry-info-wrap {
	width: 1px;
	overflow: hidden;
	display: block;
}

.avia-post-nav:hover .entry-info-wrap {
	width: 240px;
}

/* page split pagination */
.pagination_split_post {
	clear: both;
	padding-top: 20px;
}

/*related posts*/
.related_posts {
	position: relative;
	clear: both;
	width: 100%;
	float: left;
	border-top-style: solid;
	border-top-width: 1px;
	padding: 23px 33px 33px 0;
	margin-bottom: 30px;
}

.related_posts:hover {
	z-index: 9999;
}

.related_title {
	margin-bottom: 20px;
}

.related_column {
	float: left;
	padding-right: 3px;
}

.related_posts img,
.related_posts a {
	display: block;
	border-radius: 2px;
	overflow: hidden;
	max-width: 100%;
	margin: 0 auto;
}

.related_posts_default_image {
	border-width: 1px;
	border-style: solid;
	display: block;
	float: left;
	border-radius: 2px;
	min-height: 50px;
	min-width: 100%;
	max-width: 100%;
}

.related_posts_default_image img {
	visibility: hidden;
}

.relThumb {
	text-align: center;
}

.related_image_wrap {
	position: relative;
	display: block;
	float: left;
	width: 100%;
}

.avia-related-tooltip {
	position: absolute;
	z-index: 9999999;
	padding: 0;
	width: 200px;
	border-radius: 2px;
	box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.1);
	border-width: 1px;
	border-style: solid;
	padding: 15px;
	margin-top: -10px;
}

.avia-related-tooltip .avia-arrow-wrap {
	top: auto;
	bottom: 0;
	position: absolute;
	left: 50%;
}

.avia-related-tooltip .avia-arrow-wrap .avia-arrow {
	border-top: none;
	border-left: none;
	top: -4px;
}

.related-format-icon {
	position: absolute;
	text-align: center;
	top: 1px;
	left: 1px;
	bottom: 1px;
	right: 1px;
	opacity: 0;
	filter: alpha(opacity=0);
}

.related-format-icon-inner {
	position: absolute;
	height: 30px;
	font-size: 30px;
	line-height: 30px;
	top: 50%;
	margin-top: -15px;
	left: 0;
	width: 100%;
}

.related-format-icon:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.related-format-visible {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.sidebar_left .related_posts_sep {
	right: auto;
	left: 0;
}

/*post types*/

.avia-post-format-image img {
	border-radius: 3px;
}

.avia-post-format-image {
	margin-bottom: 10px;
}

.entry-content-wrapper.gallery-content .avia-gallery {
	margin-bottom: 10px;
}

/*audio*/

#top #wrap_all .big-preview + .big-preview {
	position: relative;
	top: -60px;
	background: transparent;
	margin: 0 20px -40px 20px;
	z-index: 100;
	width: auto;
}

#top #wrap_all .big-preview.multi-big + .big-preview.multi-big {
	margin: 0 20px -30px 20px;
}

/*blog in flex column*/

.flex_column .template-blog .post .entry-content-wrapper {
	font-size: 13px;
}

#top .flex_column .template-blog .post-title {
	font-size: 17px;
}

.flex_column .template-blog .post_delimiter {
	margin: 0 0 20px 0;
	padding: 20px 0 0 0;
}

.flex_column .template-blog .single-big .pagination {
	padding: 1px 0 10px 0;
}

.flex_column .template-blog .big-preview.single-big {
	padding: 0 0 10px 0;
}

.flex_column .template-blog .post-meta-infos {
	margin-bottom: -13px;
}

/* ======================================================================================================================================================
#Page Styles
====================================================================================================================================================== */

.template-page .entry-content-wrapper h1,
.template-page .entry-content-wrapper h2 {
	letter-spacing: 1px;
}

.entry-content-wrapper h1,
.entry-content-wrapper h2,
.entry-content-wrapper h3,
.entry-content-wrapper h4 {
	text-transform: none;
	color: #000;
}

.extra-mini-title {
	padding-bottom: 20px;
}

.a-tag:hover {
	cursor: pointer;
}

.page-heading-container {
	position: relative;
	margin-bottom: 40px;
	padding: 0 0 44px 0;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	padding-right: 50px;
}

.fullsize .page-heading-container {
	padding-right: 0;
}

.page-thumb img {
	border-radius: 3px;
}

/*template builder page styles*/
.template-page .template-blog .entry-content-wrapper h1,
.template-page .template-blog .entry-content-wrapper h2 {
	text-transform: none;
	letter-spacing: 0;
}

.content .entry-content-wrapper .entry-content-wrapper {
	padding-right: 0;
	padding-left: 0;
}

.content .entry-content-wrapper .entry-content-wrapper .big-preview.single-big {
	padding: 0 0 10px 0;
}

/*search page*/

.template-search #searchform > div {
	max-width: 100%;
	margin-bottom: 0;
}

#top .template-search.content .entry-content-wrapper {
	padding-bottom: 40px;
	padding-left: 55px;
	font-size: 13px;
	clear: both;
}

.template-search .pagination {
	padding: 1px 50px 10px 55px;
}

.template-search .entry-content-wrapper .post-title {
	font-size: 19px;
}

#top .template-search .entry-content-wrapper .post-title a:hover {
	text-decoration: underline;
}

.search-result-counter {
	position: absolute;
	left: 0;
	top: 1px;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
	height: 44px;
	line-height: 24px;
	padding: 10px;
	text-align: center;
	border-radius: 100px;
	width: 44px;
}

#top #search-fail {
	padding-left: 0;
}

.template-search .post-entry {
	position: relative;
	clear: both;
}

/*author page*/

.page-heading-container .author_description {
	overflow: hidden;
}

.template-author .content .post .entry-content-wrapper {
	padding-bottom: 40px;
	font-size: 13px;
}

.template-author .pagination {
	padding: 1px 50px 10px 0;
}

.template-author .entry-content-wrapper .post-title {
	font-size: 19px;
}

#top .template-author .entry-content-wrapper .post-title a:hover {
	text-decoration: underline;
}

.author-extra-border {
	display: block;
	position: absolute;
	bottom: -1px;
	width: 600%;
	right: 0;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.fullsize .author-extra-border {
	right: auto;
	left: -100%;
}

.template-author .post-entry {
	position: relative;
	clear: both;
}

/*archive page*/

.template-archives .tab_inner_content li {
	width: 48%;
	float: left;
	clear: none;
	margin: 0 2% 0 0;
	list-style-position: inside;
}

.template-archives .relThumWrap img,
.template-archives .relThumWrap span {
	width: 100%;
	text-decoration: none;
}

.template-archives .relThumbTitle {
	display: block;
	clear: both;
}

/*tag archive */
#top .fullsize .template-blog .tag-page-post-type-title {
	font-size: 50px;
	text-transform: uppercase;
}

.archive .category-term-description {
	margin-bottom: 25px;
}

/* ======================================================================================================================================================
#Sidebar & Widgets
====================================================================================================================================================== */

.widgettitle {
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 14px;
}

.widget {
	clear: both;
	position: relative;
	padding: 30px 0 30px 0;
	float: none;
}

#footer .widget {
	padding: 0;
	margin: 30px 0 30px 0;
}

#top .widget ul {
	padding: 0;
	margin: 0;
	width: 100%;
	float: left;
}

.widget li {
	clear: both;
}

.widget ul ul li,
.widget ul ol li,
.widget ol ol li,
.widget ol ul li {
	margin-bottom: 0;
}

.sidebar .widget:first-child,
.content .sidebar .widget:first-child {
	padding-top: 0;
	border-top: none;
}

/*nav menu widget*/

.widget_nav_menu a {
	display: block;
	padding: 4px 0px 5px 0;
	text-decoration: none;
}

div .widget_nav_menu {
	padding-bottom: 24px;
}

.widget_nav_menu ul {
	margin: 0;
	padding: 0;
}

#top .widget_nav_menu li {
	position: relative;
	-webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: content-box; /* Firefox, other Gecko */
	box-sizing: content-box;
	margin: 0;
	clear: both;
}

#top .widget_nav_menu ul ul li:before {
	content: "\2219";
	position: absolute;
	top: 5px;
}

#top .sidebar_left .widget_nav_menu ul ul li:before {
	right: -10px;
}

#top .widget_nav_menu ul ul li a {
	padding: 6px 0px 7px 12px;
}

.widget_nav_menu .current-menu-item > a,
.widget_nav_menu .current_page_item > a {
	font-weight: bold;
}

.widget_nav_menu ul:first-child > .current-menu-item,
.widget_nav_menu ul:first-child > .current_page_item,
.widget_nav_menu ul:first-child > .current-menu-ancestor {
	padding-left: 51px;
	left: -51px;
	top: 1px;
	margin-top: -1px;
	padding-top: 1px;
	width: 100%;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.widget_nav_menu ul ul {
	display: block;
}

.widget_nav_menu.widget_nav_hide_child ul ul {
	display: none;
}

#top .widget_nav_menu .current-menu-item > ul,
#top .widget_nav_menu .current_page_item > ul,
#top .widget_nav_menu .current_page_ancestor > ul,
#top .widget_nav_menu .current-menu-ancestor > ul {
	display: block;
	padding: 0 0 0 13px;
}

#top .sidebar_left .widget_nav_menu .current-menu-item > ul,
#top .sidebar_left .widget_nav_menu .current_page_item > ul,
#top .sidebar_left .widget_nav_menu .current_page_ancestor > ul,
#top .sidebar_left .widget_nav_menu .current-menu-ancestor > ul {
	padding: 0 13px 0 0;
}

#top .sidebar_left .widget_nav_menu .sub-menu {
	padding-right: 13px;
}

.widget_nav_menu ul:first-child > .current-menu-item > a,
.widget_nav_menu ul:first-child > .current_page_item > a {
	border: none;
	padding: 6px 7px 7px 0;
}

#top .widget_nav_menu ul ul .sub-menu,
#top .widget_nav_menu ul ul .children {
	padding: 0 0 0 10px;
	overflow: hidden;
	margin: 0;
}

.widget_nav_menu .nested_nav > li:first-child > a {
	border: none;
}

#top .widget_nav_menu .nested_nav {
	padding-bottom: 30px;
}

/*text*/

.textwidget ul {
	margin-left: 0px;
	overflow: hidden;
}

/*combo widget*/
#top .sidebar .widget_tab_comments,
#top #footer .widget_tab_comments {
	display: none;
}

.js_active .avia_combo_widget .tab_content {
	padding: 5px;
}

/*social media count widget*/
.avia_socialcount {
	overflow: hidden;
	padding: 20px 0 0 0;
	text-align: left;
}

#top .social_widget_icon {
	padding: 0;
	margin: 0;
	height: 30px;
	width: 30px;
	line-height: 32px;
	text-align: center;
	float: left;
	font-size: 17px;
	border-radius: 2px;
}

.asc_twitter .social_widget_icon {
	color: #fff;
	background-color: #46d4fe;
	border-color: #46d4fe;
	text-shadow: 1px 1px 1px #17acd8;
}

.asc_rss .social_widget_icon {
	color: #fff;
	background-color: #ffa133;
	border-color: #ffa133;
	text-shadow: 1px 1px 1px #d18021;
}

.avia_socialcount .seperator {
	display: none;
}

div #footer .avia_socialcount {
	padding: 10px 0 0 0;
}

.asc_multi_count {
	float: left;
	width: 50%;
}

.avia_socialcount a,
.avia_socialcount a:hover {
	height: 40px;
	text-decoration: none;
	display: block;
	min-width: 89px;
}

.avia_socialcount strong,
.avia_socialcount span {
	display: block;
	line-height: 1em;
	padding: 0 0 0 36px;
}

.avia_socialcount a strong {
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0;
	padding-top: 4px;
}

.avia_socialcount a span {
	font-size: 10px;
	padding-top: 3px;
}

/*twitter widget*/
#top .widget.tweetbox .tweets {
	list-style-type: none;
	list-style-position: outside;
	border: none;
}

#top .widget.tweetbox .tweet {
	padding: 10px 0;
	line-height: 18px;
	position: relative;
	overflow: hidden;
	font-size: 11px;
	background: none;
	border-top-style: dashed;
	border-top-width: 1px;
	margin: 0;
}

#top .widget.tweetbox .tweet:first-child {
	border: none;
}

.widget.tweetbox .tweet-thumb {
	position: relative;
}

.widget.tweetbox .tweet-thumb a {
	margin-right: 9px;
	padding: 3px;
	float: left;
	border-style: solid;
	border-width: 1px;
	margin-top: 5px;
	display: block;
	width: 36px;
}

#top .widget.tweetbox .tweet-thumb img {
	display: block;
	float: left;
	border: none;
	padding: 0;
	margin: 0;
}

.widget.tweetbox .tweet-text {
	position: relative;
	overflow: hidden;
}

.widget.tweetbox .tweet-time {
	clear: both;
	font-size: 11px;
}

.widget.tweetbox .tweet-text.avatar_no {
	font-size: 12px;
	line-height: 1.7em;
	font-weight: 400;
}

.widget.tweetbox .tweet-text.avatar_no .tweet-time {
	font-style: italic;
	font-weight: normal;
}

.avia_partner_widget {
	overflow: hidden;
	clear: both;
}

.avia_partner_widget a,
.avia_partner_widget a:hover {
	float: left;
	display: block;
	text-decoration: none;
	width: 46%;
	outline: none;
	border: none;
	padding-bottom: 46%;
	position: relative;
	margin-left: 2px;
}

#top .avia_partner_widget {
	max-width: 272px;
}

#top .avia_partner_widget img {
	position: absolute;
	left: 0;
	top: 0;
}

.avia_partner1 {
	margin-right: 1px;
}

.avia_parnter_empty {
	line-height: 1em;
	height: 97%;
	width: 97%;
	display: block;
	font-size: 10px;
	text-align: center;
	position: absolute;
	border-style: solid;
	border-width: 1px;
}

.avia_parnter_empty span {
	width: 100%;
	position: absolute;
	top: 50%;
	margin-top: -5px;
	text-align: center;
	left: 0;
}

/*maps*/

.avia_google_maps_container {
	height: 230px;
	width: 100%;
}

.content .avia_google_maps_container {
	height: 230px;
}

#top .widget .infoWindow input[type="text"] {
	margin: 0 0px 3px 0;
}

.avia_google_maps_container img,
.avia_google_maps_container a img {
	max-width: none;
}

.avia_google_maps_container div,
.avia_google_maps_container img,
.avia_google_maps_container a {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

/*news widget*/

#top .news-content {
	padding: 7px 0 3px 0;
	line-height: 18px;
	position: relative;
	overflow: hidden;
	font-size: 11px;
	display: block;
	clear: both;
	margin: 0;
}

#top .news-wrap {
	border: none;
	list-style-type: none;
	list-style-position: outside;
}

#top .news-wrap li {
	border: none;
	background: none;
	text-indent: 0;
}

.news-link,
.news-link:hover,
#top .widget ul li .news-link:hover {
	display: block;
	position: relative;
	text-decoration: none;
	overflow: hidden;
	z-index: 2;
}

/*default size news/portfolio widget*/
.news-thumb {
	margin-right: 9px;
	padding: 3px;
	float: left;
	margin-top: 4px;
	border-style: solid;
	border-width: 1px;
	min-width: 36px;
	min-height: 36px;
}

.image_size_widget .news-thumb {
	height: 36px;
	width: 36px;
}

.image_size_widget .tweet-thumb img,
.image_size_widget .news-thumb img {
	width: 28px;
	height: 28px;
}

/*bigger size news/portfolio widget*/
.image_size_portfolio_small .news-link {
	float: left;
	margin-right: 10px;
}

.image_size_portfolio_small .news-thumb,
.image_size_portfolio_small .tweet-thumb img,
.image_size_portfolio_small .news-thumb img {
	width: 150px;
	min-height: 100px;
}

.image_size_portfolio_small .news-excerpt {
	overflow: hidden;
	font-size: 13px;
	line-height: 1.65em;
}

.image_size_portfolio_small .news-headline {
	font-size: 14px;
}

#top .news-thumb img {
	display: block;
	float: left;
	border: none;
	padding: 0;
	margin: 0;
}

.news-headline {
	overflow: hidden;
	font-weight: bold;
	margin-top: 2px;
	display: block;
}

.news-time {
	font-weight: normal;
	clear: both;
	font-size: 10px;
	display: block;
}

/*tagcloud*/
.tagcloud br {
	display: none;
}

.tagcloud a {
	font-size: 11px !important;
	padding: 2px 8px;
	margin: 0 1px 1px 0;
	display: block;
	float: left;
	border-style: solid;
	border-width: 1px;
	text-decoration: none;
}

.tagcloud a:hover {
	text-shadow: none;
	text-decoration: underline;
}

.widget_tag_cloud h3 {
	border: none;
}

/*rss*/

.widget_rss li {
	line-height: 1.5em;
	font-size: 11px;
}

.widget_rss li div {
	font-family: "Georgia", "Times New Roman", Helvetica, Arial, sans-serif;
	font-style: italic;
}

.rsswidget {
	display: block;
	font-weight: bold;
}

.rss-date,
.widget_rss cite {
	font-size: 11px;
}

.widget_rss li {
	padding: 7px 0;
}

.widget_rss .widgettitle img {
	display: none;
}

/*recentcomments, recent entries*/

.recentcomments,
.widget_recent_entries li {
	padding: 7px 0;
	display: block;
	font-size: 11px;
	line-height: 1.5em;
}

.recentcomments a,
.widget_recent_entries li a {
	font-style: italic;
	font-family: "Georgia", "Times New Roman", Helvetica, Arial, sans-serif;
}

.recentcomments,
.widget_recent_entries li {
	border-top-width: 3px;
	border-top-style: solid;
}

.recentcomments:first-child,
.widget_recent_entries li:first-child {
	border-top: none;
}

/*sidebar left mods for all widgets*/

.sidebar_left .widget_nav_menu ul:first-child > .current-menu-item,
.sidebar_left .widget_nav_menu ul:first-child > .current_page_item,
.sidebar_left .widget_nav_menu ul:first-child > .current-menu-ancestor {
	padding-right: 52px;
	padding-left: 0;
	left: auto;
	top: 1px;
}

.sidebar_left.sidebar {
	text-align: right;
}

.sidebar_left .news-thumb {
	float: right;
	margin: 4px 0 0 9px;
}

/*TWITTER WIDGET PRO STYLES*/
#top .widget_twitter {
	font-size: 12px;
	line-height: 1.65em;
}

#top .widget_twitter .twitter-avatar {
	float: left;
	margin-right: 13px;
	border-style: solid;
	border-width: 1px;
	padding: 3px;
	display: block;
}

#top .widget_twitter .twitter-avatar a,
#top .widget_twitter .twitter-avatar img {
	display: block;
}

#top .widget_twitter ul {
	overflow: hidden;
	margin: 0;
	padding: 0;
}

#top .widget_twitter li {
	padding: 0 0 8px 0;
	margin: 0 0 8px 0;
	border-bottom-style: solid;
	border-bottom-width: 2px;
}

#top .widget_twitter li:last-child {
	border-bottom: none;
}

#top .widget_twitter .entry-content-wrapper {
	padding: 0;
	margin: 0;
	float: none;
	clear: both;
	position: relative;
	border: none;
	width: 100%;
}

#top .widget_twitter .entry-meta {
	display: block;
	font-size: 11px;
	font-style: italic;
	opacity: 0.8;
	margin-top: 5px;
}

#top .widget_twitter .intent-meta {
	display: block;
	font-size: 11px;
	margin-top: 8px;
}

#top .widget_twitter .intent-meta a {
	text-decoration: none;
	margin-right: 4px;
}

#top .widget_twitter .intent-meta a:hover {
	text-decoration: underline;
}

/* ======================================================================================================================================================
#Footer & #Socket
====================================================================================================================================================== */

#footer {
	padding: 15px 0 30px 0;
	z-index: 1;
}

#footer .widgettitle {
	font-weight: normal;
	font-size: 12px;
	margin: 0 0 11px 0;
	display: block;
}

#socket .container {
	padding: 15px 0;
}

#socket {
	font-size: 11px;
	margin-top: -1px;
	z-index: 1;
}

#socket .menu {
	margin-top: 6px;
}

#socket .sub_menu_socket {
	float: right;
	margin: 0;
}

#socket .sub_menu_socket div {
	overflow: hidden;
}

#socket .sub_menu_socket li {
	float: left;
	display: block;
	padding: 0 10px;
	border-left-style: solid;
	border-left-width: 1px;
	line-height: 10px;
}

#socket .sub_menu_socket li:first-child {
	border: none;
}

#socket .sub_menu_socket li:last-child {
	padding-right: 0;
}

#socket .copyright {
	float: left;
}

#scroll-top-link {
	position: fixed;
	border-radius: 2px;
	height: 50px;
	width: auto;
	line-height: 50px;
	text-decoration: none;
	text-align: center;
	opacity: 0;
	right: 50px;
	bottom: 50px;
	z-index: 10000;
	visibility: hidden;
	padding: 0 15px;
}

#scroll-top-link.avia_pop_class {
	opacity: 0.7;
	visibility: visible;
}

/* ======================================================================================================================================================
#Comment
====================================================================================================================================================== */

h4#comments {
	margin-bottom: 30px;
}

#respond {
	margin-top: 20px;
}

.commentlist ul {
	border-left-style: dashed;
	border-left-width: 1px;
}

.children .children .says {
	border-bottom-style: dashed;
	border-bottom-width: 1px;
}

.miniheading,
.author_name,
#reply-title,
#top .logged-in-as,
.dynamic-column-title {
	font-weight: 600;
	letter-spacing: 1px;
}

#comments span,
.minitext,
.form-allowed-tags,
#reply-title small,
#commentform label {
	font-size: 11px;
	display: block;
	letter-spacing: 0;
	text-transform: none;
	padding-top: 8px;
	line-height: 1.5em;
	font-weight: normal;
}

.comment_meta_container {
	clear: both;
	float: none;
}

#top .commentlist {
	margin: 0;
	padding: 0 0 10px 0px;
	border: none;
}

#top .commentlist .comment {
	list-style-type: none;
	list-style-position: outside;
	width: 100%;
	position: relative;
	display: block;
	background: none;
	min-height: 100px;
	clear: both;
}

#top .commentlist .comment.depth-1 {
	float: left;
}

#top .commentlist .comment > div {
	min-height: 100px;
	float: left;
	width: 100%;
}

.commentlist > .comment {
	border-bottom-style: dashed;
	border-bottom-width: 1px;
	margin-bottom: 30px;
}

.gravatar {
	position: relative;
	z-index: 2;
	border-radius: 200px;
	overflow: hidden;
	float: left;
}

.gravatar img {
	padding: 0;
	margin: 0;
	display: block;
	border-radius: 200px;
}

.comment_content {
	position: relative;
	margin: 0 0 0 85px;
	padding: 0 35px 15px 0;
	z-index: 10;
	overflow: hidden;
}

.author_name a,
.author_name a:hover {
	font-weight: bold;
	text-decoration: none;
}

.comment-edit-link,
#cancel-comment-reply-link {
	display: inline-block;
	font-size: 10px;
}

.author_name,
.comment_title {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.commentmetadata {
	font-size: 11px;
	line-height: 1em;
}

.commentmetadata a {
	text-decoration: none;
}

.commentmetadata a:hover {
	text-decoration: underline;
}

.says {
	display: block;
	height: 1px;
	left: -70px;
	position: absolute;
	text-indent: 200%;
	top: 18px;
	width: 40px;
	overflow: hidden;
}

#top .comment_text {
	clear: both;
	font-size: 13px;
	padding: 0 0 15px 0;
	border: none;
}

#top .comment-reply-link {
	font-size: 11px;
	text-decoration: none;
	line-height: 20px;
}

.side-container-comment-inner {
	text-align: center;
	position: relative;
	overflow: hidden;
	margin-bottom: 15px;
}

.comment-count {
	font-size: 24px;
	line-height: 60px;
	width: 60px;
	display: block;
	text-align: center;
	border-radius: 200px;
	margin: 0 auto;
	position: relative;
	z-index: 100;
}

.side-container-comment-inner .comment-text {
	font-size: 12px;
	text-transform: uppercase;
}

.center-border {
	position: absolute;
	top: 39%;
	width: 42%;
	border-top-style: solid;
	border-top-width: 1px;
	z-index: 1;
}

.center-border-left {
	left: 0;
}

.center-border-right {
	right: 0;
}

/*children*/
#top .commentlist ul {
	margin: 0 0 0 74px;
	clear: both;
}

#top .commentlist .children ul {
	margin: 0 0 0 47px;
}

.children .comment_content {
	margin: 0 0 0 28px;
	padding-bottom: 30px;
	z-index: 1;
}

.children .gravatar {
	position: relative;
	left: -24px;
	z-index: 2;
	width: 45px;
}

#top .children .comment-reply-link {
	left: -42px;
	top: 51px;
}

/*comment page nav*/

.comment_page_nav_links {
	position: relative;
	display: block;
	clear: both;
	overflow: hidden;
	font-size: 11px;
}

.comment_prev_page a {
	float: left;
}

.comment_next_page a {
	float: right;
}

.sidebar_right .comment_container {
	padding-right: 50px;
}

.sidebar_left .comment_container {
	margin-left: 0;
}

.comment_container {
	max-width: 100%;
}

#commentform {
	position: relative;
}

#commentform p {
	position: relative;
	padding: 0 0 10px 0;
	margin: 0;
}

#reply-title small a {
	float: right;
}

#commentform label {
	position: absolute;
	left: 245px;
	font-size: 11px;
	top: 0;
	font-weight: bold;
}

#commentform div input {
	margin: 0;
}

.commentlist #commentform label {
	position: static;
	display: block;
}

.comment-notes,
#commentform .comment-form-comment label {
	display: none;
}

#top .comment-form-url input,
#top .comment-form-email input,
#top .comment-form-author input {
	width: 220px;
}

#top .commentlist .comment-form-url input,
#top .commentlist .comment-form-email input,
#top .commentlist .comment-form-author input {
	width: 70%;
}

#comment {
	width: 602px;
	height: 150px;
	padding: 10px 7px;
	font-size: 12px;
	margin: 0;
}

.form-allowed-tags {
	font-size: 11px;
	line-height: 1.5em;
	margin-bottom: 5px;
}

.form-allowed-tags code {
	display: block;
	padding-top: 5px;
}

.commentlist #respond {
	padding: 0 0 30px 56px;
}

.commentlist #respond #comment {
	width: 90%;
}

.commentlist #respond .form-allowed-tags {
	display: none;
}

#reply-title {
	display: none;
}

#reply-title small {
	display: inline;
}

.commentlist #reply-title {
	display: block;
}

#comment {
	width: 94%;
	font-size: 12px;
}

.personal_data p {
	float: left;
	width: 33%;
}

.personal_data label {
	display: none;
}

/* ======================================================================================================================================================
#CSS ANIMATION
====================================================================================================================================================== */

.small-preview,
.avia-post-nav .entry-info-wrap,
.avia-post-nav,
.avia-menu-fx,
.team-social,
.button,
.related-format-icon,
.avia-slideshow-controls a,
#top .social_bookmarks li a,
.fallback-post-type-icon,
#scroll-top-link,
#advanced_menu_toggle,
#advanced_menu_hide,
.avia-slideshow-button {
	-ms-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.main_menu a,
.pagination a {
	-ms-transition: color 0.15s ease-out;
	-moz-transition: color 0.15s ease-out;
	-webkit-transition: color 0.15s ease-out;
	-o-transition: color 0.15s ease-out;
	transition: color 0.15s ease-out;

	-ms-transition: background 0.15s ease-out;
	-moz-transition: background 0.15s ease-out;
	-webkit-transition: background 0.15s ease-out;
	-o-transition: background 0.15s ease-out;
	transition: background 0.15s ease-out;
}

/* #Media Queries
================================================== */

/* large screens with 1140px with */

@media only screen and (min-width: 1140px) {
}

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 989px) {
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 989px) {
	.responsive #top .mobileMenu {
		position: absolute;
		top: 50%;
		margin: -15px 0 0 0;
		width: 250px;
		right: 0;
		max-width: 250px;
	}

	.responsive .main_menu ul:first-child > li > a {
		padding: 0 10px;
	}

	.responsive #top #header {
		position: relative;
	}

	.responsive #top .header_bg {
		opacity: 1;
		filter: alpha(opacity=1);
	}

	.responsive #top #main {
		padding-top: 0;
	}

	.responsive #main .container_wrap:first-child {
		border-top: none;
	}

	.responsive .mobile_slide_out .logo {
		float: left;
	}

	.responsive .mobile_slide_out .logo img {
		margin: 0;
	}

	.responsive .mobile_menu_tablet .main_menu {
		display: none;
	}
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
	/*blog*/
	.responsive .template-archives .tab_inner_content li {
		width: 98%;
	}

	.responsive .template-blog .blog-meta,
	.responsive .post_author_timeline {
		display: none;
	}

	.responsive #top #main .sidebar.smartphones_sidebar_active {
		display: block;
		text-align: left;
		border-left: none;
		border-right: none;
		border-top-style: dashed;
		border-top-width: 1px;
		width: 100%;
		clear: both;
	}

	.responsive #top #main .sidebar.smartphones_sidebar_active .inner_sidebar {
		margin: 0;
	}

	.responsive .content .entry-content-wrapper {
		padding: 0;
	}

	.responsive .content {
		border: none;
	}

	.responsive .template-blog .post_delimiter {
		margin: 0 0 30px 0;
		padding: 30px 0 0 0;
	}

	.responsive .big-preview {
		padding: 0 0 10px 0;
	}

	.responsive .related_posts {
		padding: 20px 0;
	}

	.responsive .comment_content {
		padding-right: 0;
		padding-left: 0;
	}

	.responsive .fullsize div .template-blog .entry-content-wrapper {
		text-align: left;
		font-size: 14px;
		line-height: 24px;
	}

	.responsive #top .fullsize .template-blog .post .entry-content-wrapper > * {
		max-width: 100%;
	}

	.responsive #top .avia-post-nav {
		display: none;
	}

	/*all templates*/
	.responsive .title_container .breadcrumb {
		position: relative;
		right: auto;
		top: -6px;
		margin: 0;
		left: -2px;
	}

	.responsive .pagination {
		padding-left: 0;
		padding-right: 0;
	}

	.responsive #header .main_menu ul {
		display: none;
	}

	/*header*/
	.responsive #top #header {
		position: relative;
	}

	.responsive #top #main {
		padding-top: 0;
	}

	.responsive #main .container_wrap:first-child {
		border-top: none;
	}

	.responsive .main_menu {
		position: static;
	}

	.responsive .logo {
		position: static;
		height: 80px;
		float: none;
	}

	.responsive .mobile_slide_out .logo {
		float: left;
	}

	.responsive .logo img {
		margin: 0 auto;
		max-width: 100%;
	}

	.responsive #header_main .container {
		height: auto !important;
	}

	.responsive #top .header_bg {
		opacity: 1;
		filter: alpha(opacity=1);
	}

	.responsive #top .mobileMenu {
		margin: 0 0 10px 0;
		width: 100%;
		padding: 10px 4px;
	}

	.responsive.social_header .phone-info {
		text-align: center;
		float: none;
		clear: both;
		margin: 0;
		padding: 0;
		padding-top: 10px;
	}

	.responsive.social_header .phone-info span {
		border: none;
		width: 100%;
		text-align: center;
		float: none;
		clear: both;
		margin: 0;
		padding: 0;
	}

	.responsive #header_meta .social_bookmarks {
		padding-bottom: 2px;
		width: 100%;
	}

	.responsive #header_meta .social_bookmarks li {
		border-style: solid;
		border-width: 1px;
		margin-bottom: -1px;
		margin-left: -1px;
	}

	.responsive #top #header_meta .social_bookmarks li:last-child a {
		border-right-style: solid;
		border-right-width: 1px;
	}

	.responsive #header .sub_menu,
	.responsive #header_meta .sub_menu > ul {
		float: none;
		width: 100%;
		text-align: center;
		margin: 0 auto;
		position: static;
	}

	.responsive #header .social_bookmarks {
		width: 100%;
		text-align: center;
		height: auto;
		line-height: 0.8em;
	}

	.responsive #header_meta .sub_menu > ul li,
	.responsive #header .social_bookmarks li {
		float: none;
		display: inline-block;
	}

	.responsive.bottom_nav_header #header_main .social_bookmarks {
		position: relative;
		top: 0;
		right: 0;
		margin: 10px auto;
		clear: both;
	}

	.responsive #header_main_alternate .mobileMenu {
		margin: 16px 0;
	}

	.responsive.bottom_nav_header.social_header .main_menu > div {
		height: auto;
	}

	.responsive .mobile_slide_out .logo img {
		margin: 0;
	}

	/*related images*/
	.responsive .related_entries_container .av_one_eighth {
		width: 25%;
	}

	.responsive .relThumb5 {
		clear: both;
	}
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.responsive #socket .sub_menu_socket {
		float: none;
		margin-left: -20px;
	}

	/*portfolio*/
	.responsive #top .grid-sort-container.grid-total-odd .grid-entry.grid-loop-1 {
		width: 100%;
	}

	.responsive #top .grid-sort-container .grid-entry {
		width: 50%;
		margin-bottom: 0;
	}

	.responsive #top .portfolio-parity-odd {
		clear: both;
	}

	.responsive #scroll-top-link {
		display: none;
	}

	/*iphones etc scroll better by tapping the status bar at the top of the screen*/
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
	.responsive #socket .sub_menu_socket {
		display: none;
	}

	/*related images*/
	.responsive .related_entries_container .av_one_eighth {
		width: 50%;
	}

	.responsive .related_entries_container .av_one_eighth:nth-child(odd) {
		clear: both;
	}
}

/*Added with version 1.3*/

/*mobile-advanced*/
#mobile-advanced {
	width: 100%;
	right: -100%;
	position: absolute;
	z-index: 1000;
	top: 0;
	height: 100%;
	padding: 90px 0;
	overflow: hidden;
	border-left-style: solid;
	border-left-width: 1px;
	margin-left: -2px;
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	-ms-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	transition: all 0.6s ease;
	-webkit-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-o-transform-origin: 0% 0%;
	transform-origin: 0% 0%;
	display: none;
}

.show_mobile_menu {
	overflow: hidden;
}

.show_mobile_menu #mobile-advanced {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transform: translate(-100%, 0);
	-moz-transform: translate(-100%, 0);
	-ms-transform: translate(-100%, 0);
	-o-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.csstransforms3d .show_mobile_menu #mobile-advanced {
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

#mobile-advanced > li:first-child > a:first-child {
	border-top-style: solid;
	border-top-width: 1px;
}

#mobile-advanced li {
	margin: 0;
	padding: 0;
	position: relative;
	float: none;
	clear: both;
}

#mobile-advanced li > a:before {
	content: "\25B6";
	position: absolute;
	top: 13px;
	margin-left: -10px;
	font-family: "entypo-fontello";
	font-size: 7px;
}

#mobile-advanced ul {
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	left: 0;
	position: relative;
}

#mobile-advanced a,
#mobile-advanced .mega_menu_title {
	padding: 13px 28px;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	width: 100%;
	display: block;
	margin: 0;
	text-decoration: none;
	position: relative;
}

#mobile-advanced ul a {
	padding-left: 40px;
}

#mobile-advanced ul ul a {
	padding-left: 60px;
}

#mobile-advanced ul ul ul a {
	padding-left: 80px;
}

#mobile-advanced ul ul ul ul a {
	padding-left: 100px;
}

/*mega menu fixes*/
#mobile-advanced .avia-bullet {
	display: none;
}

#mobile-advanced .avia_mega_div {
	width: 100%;
	margin: 0;
	float: none;
}

#mobile-advanced .mega_menu_title a {
	padding: 0;
	border: none;
}

#advanced_menu_toggle,
#advanced_menu_hide {
	position: absolute;
	border-radius: 2px;
	height: 47px;
	width: 47px;
	line-height: 47px;
	text-decoration: none;
	text-align: center;
	right: 0;
	top: 50%;
	margin-top: -24px;
	z-index: 10000;
	border-style: solid;
	border-width: 1px;
	font-size: 30px;
	display: none;
}

.bottom_nav_header #advanced_menu_toggle {
	top: 40px;
}

#advanced_menu_hide {
	display: block;
	z-index: 10001;
	visibility: hidden;
	opacity: 0;
	top: 44px;
	right: 23px;
}

.show_mobile_menu #advanced_menu_hide {
	opacity: 1;
	visibility: visible;
}

.av-subheading.av-subheading-top h2 {
	font-size: 18px;
	color: #666;
}

@media only screen and (max-width: 989px) {
	#mobile-advanced,
	.mobile_active.mobile_menu_tablet #advanced_menu_toggle {
		display: block;
	}

	#mobile-advanced .avia-menu-fx {
		display: none;
		visibility: hidden;
	}

	.mobile_active #advanced_menu_toggle {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.mobile_active #advanced_menu_toggle {
		display: block;
	}
}

.bottom h2 {
	padding: 13px;
}

.menu-item.current-menu-item a,
.menu-item a:hover {
	background-color: RGBA(41, 151, 171, 0.2);
}

#header_main {
	margin-bottom: 85px;
}

@media screen and (min-width: 768px) {
	.bottom-skip-links {
		display: none;
	}

	header .main_menu {
		display: block;
	}

	.bottom h2 {
		display: none;
	}

	.main_menu {
		position: absolute;
		top: 121px;
		z-index: 501;
		height: 40px;
	}

	#header_main {
		margin-bottom: 36px;
	}

	.bottom .avia-menu {
		margin: 0 50px;
	}

	.main_menu .menu li {
		float: left;
	}

	ul.menu li {
		float: left;
		display: block;
	}

	ul.menu li a {
		display: inline-block;
		color: #333;
	}
}

ul.list-unstyled,
ul.list-unstyled > li {
	margin: 0;
	padding: 0;
}


.av-share-box {
	.av-share-box-list {
	  margin: 0;
	  li {
		padding: 0 !important;
	  }
	}
  }
html {
	min-width: 910px;
}
html.responsive {
	min-width: 0px;
}
.boxed#top {
	margin: 0 auto;
	overflow: visible;
	/* position:relative; z-index: 3; */
}
.container {
	position: relative;
	width: 910px;
	margin: 0 auto;
	padding: 0px;
	clear: both;
}
/*no z-index for container_wrap or fixed bgs start disapearing when other elements transition -> weird chrome bug*/
.container_wrap {
	clear: both;
	position: relative;
	/* z-index: 1; */
	border-top-style: solid;
	border-top-width: 1px;
}
.unit,
.units {
	float: left;
	display: inline;
	margin-left: 50px;
	position: relative;
	z-index: 1;
	min-height: 1px;
}
.row {
	position: relative;
	margin-bottom: 20px;
	clear: both;
}
.boxed .container .stretch_full {
	position: relative;
	left: -50px;
}
.boxed#top {
	width: 1010px;
}
.boxed .container {
	margin: 0px 50px;
}
#wrap_all {
	width: 100%;
	position: relative;
	z-index: 2;
}
/* Nested Column Classes */
body .unit.alpha,
body .units.alpha,
body div .first {
	margin-left: 0;
	clear: left;
}
body .unit.alpha,
body .units.alpha {
	width: 100%;
}
/* Base Grid */
.container .one.unit,
.container .one.units {
	width: 30px;
}
.container .two.units {
	width: 110px;
}
.container .three.units {
	width: 190px;
}
.container .four.units {
	width: 270px;
}
.container .five.units {
	width: 350px;
}
.container .six.units {
	width: 430px;
}
.container .seven.units {
	width: 510px;
}
.container .eight.units {
	width: 590px;
}

/* origneel 670 */
.container .nine.units {
	width: 550px;
}
.container .ten.units {
	width: 750px;
}
.container .eleven.units {
	width: 830px;
}
.container .twelve.units {
	margin-left: 0;
	width: 910px;
}
#top.boxed .stretch_full {
	width: 1010px;
}
/* Offsets */
#top .offset-by-one {
	padding-left: 80px;
}
#top .offset-by-two {
	padding-left: 160px;
}
#top .offset-by-three {
	padding-left: 240px;
}
#top .offset-by-four {
	padding-left: 320px;
}
#top .offset-by-five {
	padding-left: 400px;
}
#top .offset-by-six {
	padding-left: 480px;
}
#top .offset-by-seven {
	padding-left: 560px;
}
#top .offset-by-eight {
	padding-left: 640px;
}
#top .offset-by-nine {
	padding-left: 720px;
}
#top .offset-by-ten {
	padding-left: 800px;
}
#top .offset-by-eleven {
	padding-left: 880px;
}
/* #Desktop - Large
================================================== */
@media only screen and (min-width: 1140px) {
	.responsive .boxed#top {
		width: 1130px;
	}
	.responsive .container {
		width: 1030px;
	}
	.responsive .container .one.unit,
	.responsive .container .one.units {
		width: 40px;
	}
	.responsive .container .two.units {
		width: 130px;
	}
	.responsive .container .three.units {
		width: 220px;
	}
	.responsive .container .four.units {
		width: 310px;
	}
	.responsive .container .five.units {
		width: 400px;
	}
	.responsive .container .six.units {
		width: 490px;
	}
	.responsive .container .seven.units {
		width: 580px;
	}
	.responsive .container .eight.units {
		width: 670px;
	}
	/* origneel 760 */
	.responsive .container .nine.units {
		width: 680px;
	}
	.responsive .container .ten.units {
		width: 850px;
	}
	.responsive .container .eleven.units {
		width: 940px;
	}
	.responsive .container .twelve.units {
		width: 1030px;
	}
	.responsive #top.boxed .stretch_full {
		width: 1130px;
	}
	.responsive #top .offset-by-one {
		padding-left: 90px;
	}
	.responsive #top .offset-by-two {
		padding-left: 180px;
	}
	.responsive #top .offset-by-three {
		padding-left: 270px;
	}
	.responsive #top .offset-by-four {
		padding-left: 360px;
	}
	.responsive #top .offset-by-five {
		padding-left: 450px;
	}
	.responsive #top .offset-by-six {
		padding-left: 540px;
	}
	.responsive #top .offset-by-seven {
		padding-left: 630px;
	}
	.responsive #top .offset-by-eight {
		padding-left: 720px;
	}
	.responsive #top .offset-by-nine {
		padding-left: 810px;
	}
	.responsive #top .offset-by-ten {
		padding-left: 900px;
	}
	.responsive #top .offset-by-eleven {
		padding-left: 990px;
	}
}
/* #Desktop - Extra Large
================================================== */
@media only screen and (min-width: 1340px) {
	.responsive_large .boxed#top {
		width: 1310px;
	}
	.responsive_large .container {
		width: 1210px;
	}
	.responsive_large .container .one.unit,
	.responsive_large .container .one.units {
		width: 55px;
	}
	.responsive_large .container .two.units {
		width: 160px;
	}
	.responsive_large .container .three.units {
		width: 265px;
	}
	.responsive_large .container .four.units {
		width: 370px;
	}
	.responsive_large .container .five.units {
		width: 475px;
	}
	.responsive_large .container .six.units {
		width: 580px;
	}
	.responsive_large .container .seven.units {
		width: 685px;
	}
	.responsive_large .container .eight.units {
		width: 790px;
	}
	.responsive_large .container .nine.units {
		width: 895px;
	}
	.responsive_large .container .ten.units {
		width: 1000px;
	}
	.responsive_large .container .eleven.units {
		width: 1105px;
	}
	.responsive_large .container .twelve.units {
		width: 1210px;
	}
	.responsive_large #top.boxed .stretch_full {
		width: 1310px;
	}
	.responsive_large #top .offset-by-one {
		padding-left: 105px;
	}
	.responsive_large #top .offset-by-two {
		padding-left: 210px;
	}
	.responsive_large #top .offset-by-three {
		padding-left: 315px;
	}
	.responsive_large #top .offset-by-four {
		padding-left: 420px;
	}
	.responsive_large #top .offset-by-five {
		padding-left: 525px;
	}
	.responsive_large #top .offset-by-six {
		padding-left: 630px;
	}
	.responsive_large #top .offset-by-seven {
		padding-left: 735px;
	}
	.responsive_large #top .offset-by-eight {
		padding-left: 840px;
	}
	.responsive_large #top .offset-by-nine {
		padding-left: 945px;
	}
	.responsive_large #top .offset-by-ten {
		padding-left: 1050px;
	}
	.responsive_large #top .offset-by-eleven {
		padding-left: 1155px;
	}
}
/* #Tablet (Portrait)
================================================== */
/* Note: Design for a width of 768px, Gutter: 30px, Unit: 32px */
@media only screen and (min-width: 768px) and (max-width: 989px) {
	.responsive #top {
		overflow-x: hidden;
	}
	.responsive .boxed#top {
		width: 782px;
	}
	.responsive .container {
		width: 682px;
	}
	.responsive .container .one.unit,
	.responsive .container .one.units {
		width: 11px;
	}
	.responsive .container .two.units {
		width: 72px;
	}
	.responsive .container .three.units {
		width: 133px;
	}
	.responsive .container .four.units {
		width: 194px;
	}
	.responsive .container .five.units {
		width: 255px;
	}
	.responsive .container .six.units {
		width: 316px;
	}
	.responsive .container .seven.units {
		width: 377px;
	}
	.responsive .container .eight.units {
		width: 438px;
	}

	/* origneel 499 */
	.responsive .container .nine.units {
		width: 335px;
	}
	.responsive .container .ten.units {
		width: 560px;
	}
	.responsive .container .eleven.units {
		width: 621px;
	}
	.responsive .container .twelve.units {
		width: 682px;
	}
	.responsive #top.boxed .stretch_full {
		width: 782px;
	}
	.responsive #top .offset-by-one {
		padding-left: 61px;
	}
	.responsive #top .offset-by-two {
		padding-left: 122px;
	}
	.responsive #top .offset-by-three {
		padding-left: 183px;
	}
	.responsive #top .offset-by-four {
		padding-left: 244px;
	}
	.responsive #top .offset-by-five {
		padding-left: 305px;
	}
	.responsive #top .offset-by-six {
		padding-left: 366px;
	}
	.responsive #top .offset-by-seven {
		padding-left: 427px;
	}
	.responsive #top .offset-by-eight {
		padding-left: 488px;
	}
	.responsive #top .offset-by-nine {
		padding-left: 549px;
	}
	.responsive #top .offset-by-ten {
		padding-left: 610px;
	}
	.responsive #top .offset-by-eleven {
		padding-left: 671px;
	}
}
/*  #Mobile (Portrait)
================================================== */
/* Note: Design for a width of 320px */
@media only screen and (max-width: 767px) {
	.responsive #top {
		overflow-x: hidden;
	}
	.responsive .boxed#top {
		width: 95%;
	}
	.responsive .container {
		width: auto;
		padding: 0 0px;
		margin: 0 15px;
	}
	.responsive .units,
	.responsive .unit {
		margin: 0;
	}
	.responsive .container .one.unit,
	.responsive .container .one.units,
	.responsive .container .two.units,
	.responsive .container .three.units,
	.responsive .container .four.units,
	.responsive .container .five.units,
	.responsive .container .six.units,
	.responsive .container .seven.units,
	.responsive .container .eight.units,
	.responsive .container .nine.units,
	.responsive .container .ten.units,
	.responsive .container .eleven.units,
	.responsive .container .twelve.units,
	.responsive #top .flex_column {
		margin: 0;
		margin-bottom: 20px;
		width: 100%;
	}
	.responsive #top.boxed .stretch_full {
		width: 318px;
	}
	/* Offsets */
	.responsive #top .offset-by-one,
	.responsive #top .offset-by-two,
	.responsive #top .offset-by-three,
	.responsive #top .offset-by-four,
	.responsive #top .offset-by-five,
	.responsive #top .offset-by-six,
	.responsive #top .offset-by-seven,
	.responsive #top .offset-by-eight,
	.responsive #top .offset-by-nine,
	.responsive #top .offset-by-ten,
	.responsive #top .offset-by-eleven {
		padding-left: 0;
	}
}
/* #Mobile (Landscape)
================================================== */
/* Note: Design for a width of 480px */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.responsive #top {
		overflow-x: hidden;
	}
	.responsive .boxed#top {
		width: 478px;
	}
	.responsive .container {
		width: 428px;
		margin: 0 auto;
	}
	.responsive .units,
	.responsive .unit {
		margin: 0;
	}
	.responsive .container .one.unit,
	.responsive .container .one.units,
	.responsive .container .two.units,
	.responsive .container .three.units,
	.responsive .container .four.units,
	.responsive .container .five.units,
	.responsive .container .six.units,
	.responsive .container .seven.units,
	.responsive .container .eight.units,
	.responsive .container .nine.units,
	.responsive .container .ten.units,
	.responsive .container .eleven.units,
	.responsive .container .twelve.units,
	.responsive #top .flex_column {
		width: 100%;
		margin-left: 0;
		margin-bottom: 20px;
	}
	.responsive #top.boxed .stretch_full {
		width: 478px;
	}
}
/* #Clearing
================================================== */
/* Self Clearing Goodness */
.container:after {
	content: "\0020";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
/* Use clearfix class on parent to clear nested units,
    or wrap each row of units in a <div class="row"> */
.clearfix:before,
.clearfix:after,
.flex_column:before,
.flex_column:after {
	content: "\0020";
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}
.flex_column:after,
.clearfix:after {
	clear: both;
}
/* You can also use a <br class="clear" /> to clear units */
.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}
/* Columns for better content separation
================================================== */
body div .first,
body div .no_margin {
	margin-left: 0;
}
div .flex_column {
	z-index: 1;
	float: left;
	position: relative;
	min-height: 1px;
	width: 100%;
}
div .av_one_fifth {
	margin-left: 6%;
	width: 15.2%;
}
div .av_one_fourth {
	margin-left: 6%;
	width: 20.5%;
}
div .av_one_third {
	margin-left: 6%;
	width: 29.333333333333332%;
}
div .av_two_fifth {
	margin-left: 6%;
	width: 36.4%;
}
div .av_one_half {
	margin-left: 6%;
	width: 47%;
}
div .av_three_fifth {
	margin-left: 6%;
	width: 57.599999999999994%;
}
div .av_two_third {
	margin-left: 6%;
	width: 64.66666666666666%;
}
div .av_three_fourth {
	margin-left: 6%;
	width: 73.5%;
}
div .av_four_fifth {
	margin-left: 6%;
	width: 78.8%;
}
div .av_one_sixth {
	margin-left: 6%;
	width: 11.666666666666666%;
}
div .av_one_seventh {
	margin-left: 6%;
	width: 9.142857142857142%;
}
div .av_one_eighth {
	margin-left: 6%;
	width: 7.25%;
}
div .av_one_nineth {
	margin-left: 6%;
	width: 5.777777777777778%;
}
div .av_one_tenth {
	margin-left: 6%;
	width: 4.6%;
}
/* Columns for better content separation (no margin)
================================================== */
body div .no_margin {
	margin-left: 0;
}
.no_margin.av_one_fifth {
	width: 20%;
}
.no_margin.av_one_fourth {
	width: 25%;
}
.no_margin.av_one_third {
	width: 33.3%;
}
.no_margin.av_two_fifth {
	width: 40%;
}
.no_margin.av_one_half {
	width: 50%;
}
.no_margin.av_three_fifth {
	width: 60%;
}
.no_margin.av_two_third {
	width: 66.6%;
}
.no_margin.av_three_fourth {
	width: 75%;
}
.no_margin.av_four_fifth {
	width: 80%;
}
.no_margin.av_one_sixth {
	width: 16.666666666666668%;
}
.no_margin.av_one_seventh {
	width: 14.285714285714286%;
}
.no_margin.av_one_eighth {
	width: 12.5%;
}
.no_margin.av_one_nineth {
	width: 11.11111111111111%;
}
.no_margin.av_one_tenth {
	width: 10%;
}

.list-inline {
	padding-left: 0;
	list-style: none;
	> li {
		display: inline-block;
	}
}

li[class^="container"],
.banner-container {
	overflow: hidden;
	.banner a {
		padding: 0;
	}
	.banner img {
		width: 100%;
		height: auto;
		border: 1px solid #e1e1e1;
	}
}

ul[class^="group"] {
	margin: 0;
	padding: 0;
	transition: margin 1s ease-in-out;
}

.banner-container {
	font-size: 0;
	line-height: 0;
	display: block;
	clear: both;
}

.banner-container ul.list-unstyled > li {
	display: inline-block;
	margin: 0 15px 15px 0;
}

.banner-container img {
	max-width: 100%;
}

.banner-container a {
	display: inline-block;
	padding: 0px;
}

.banner-container a:focus {
	background-color: transparent !important;
	padding: 0px;
	border: none;
}

ul.banner-container.section-heading {
	margin: 0;
	li {
		padding: 0;
	}
}

//@import social-map

//Additions from Inslag

h3 {
	font-size: 24px;
	&.h3-oranje {
		color: #ed7004;
	}
}

.txt-oranje {
	color: #ed7004;
}

.rsswidget,
h3.widgettitle,
strong.widgettitle {
	font-size: 12px;
}

#menu-item-search {
	display: none;
}

ul#suggestions {
	display: none;
	background: #eee;
	border: 1px solid #ccc;
	position: absolute;
	z-index: 999;
	li {
		padding: 2px 10px;
		&.selected {
			background: white;
		}
	}
}

i.av_font_icon {
	min-width: 15px;
}

.ad120x60_aside {
	width: 120px;
	float: left;
	margin-right: 10px;
}

.zapp {
	clear: left;
}

.fa-input {
	font-family: FontAwesome, Arial, sans-serif;
}

//fix om de rechter aside iets groter te maken zodat de banners passen
@media only screen and (min-width: 1340px) {
	.responsive_large .container .nine.units {
		width: 860px;
	}
}

html.html_boxed {
	background: #ffffff url(/assets/images/bg.jpg) top center no-repeat fixed;
	background-size: cover;
}

.featured {
	background-color: #ffffff;
	padding: 2%;
	border: 2px solid #333;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.8) inset;
}

hr {
	margin-bottom: 5px;
}

.logo {
	font-size: 24px;
}

.list-unstyled {
	margin: 0;
	padding: 0;
	list-style: none;
	> li {
		list-style: none;
		display: inline-block;
	}
}

.clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.hidden {
	display: none;
	position: relative;
	visibility: hidden;
	width: 0;
	height: 0;
}

.btn {
	padding: 10px 15px !important;
	display: inline-block;
	transition: all 0.3s ease-in-out;
	&.btn-primary {
		background-color: $brand-primary;
		border: 1px solid darken($brand-primary, 5%);
		color: #fff !important;
		&:hover,
		&:focus,
		&:active {
			text-decoration: none !important;
			background-color: darken($brand-primary, 5%) !important;
			border-color: darken($brand-primary, 15%) !important;
		}
	}
}

.button-container {
	margin-bottom: 30px;
	clear: both;
	li {
		width: 50%;
		display: block;
		float: left;
	}
	.btn {
		width: 100%;
		text-align: center;
	}
}

html {
  margin: 0;
  padding: 0;
}
body {
  font-size: 10pt;
  font-family: Arial, sans-serif;
  padding: 0;
  margin: 0;
}
.absolute {
  position: absolute;
}
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}
td {
  font-size: 9pt;
  padding-right: 4mm;
  vertical-align: top;
}
.icon {
  font-family: fontawesome, sans-serif;
}

/* Succescriterium 2.4.7 (niveau AA): Focus zichtbaar */
a:focus {
  background-color: #666 !important;
  color: #fff !important;
  padding: 3px;
  text-decoration: none;
  border: 1px dotted #000;
}

.skipLinks {
  position: relative;
  top: 79px;
  width: 100%;
  display: block;
  text-align: center;
}
a.skipLink {
  display: block;
  padding: 10px 0;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .skipLinks {
    position: absolute;
    z-index: 9999;
    top: 0px;
  }
  a.skipLink {
    background-color: #666;
    color: #fff !important;
    padding: 15px;
    text-decoration: none;
    border: 1px dotted #000;
    display: block;
    font-size: 1.3em;
    left: -1000em;
    position: absolute;
    z-index: 9999;
  }
}
a.skipLink:focus,
a.skipLink:active {
  top: 1em;
  left: 0;
  z-index: 1000;
  color: #fff;
}

html.html_boxed {
  background: #b8b8b8 top left repeat scroll;
}

.socket_color .rounded-container,
#top .socket_color .pagination a:hover,
.socket_color .small-preview,
.socket_color .fallback-post-type-icon {
  background: #888;
  color: #111;
}

.socket_color .button:hover,
.socket_color .ajax_controlls a:hover,
.socket_color #submit:hover,
.socket_color .big_button:hover,
.socket_color .contentSlideControlls a:hover,
.socket_color #submit:hover,
.socket_color input[type="submit"]:hover {
  background-color: #eee;
  color: #111;
  border-color: #ccc;
}

.socket_color .timeline-bullet {
  background-color: #161616;
  border-color: #111;
}

.socket_color .main_menu ul:first-child > li > ul,
#top .socket_color .avia_mega_div > .sub-menu {
  border-top-color: #fff;
}

.socket_color .av-icon-display {
  background-color: #000;
  color: #888;
}

.socket_color .ajax_search_image {
  background-color: #fff;
  color: #111;
}

#top .socket_color .avia-color-theme-color {
  color: #fff;
  border-color: #ddd;
}

.socket_color .avia-color-theme-color-subtle:hover {
  background-color: #111;
  color: #fff;
}

.socket_color .avia-icon-list .iconlist_icon {
  background-color: #050505;
}

.socket_color .sidebar_tab_icon {
  background-color: #161616;
}

.socket_color div .sidebar_active_tab,
div .socket_color .tabcontainer.noborder_tabs .active_tab_content,
div .socket_color .tabcontainer.noborder_tabs .active_tab {
  color: #eee;
  background-color: #111;
}

#top .socket_color .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .socket_color .avia-smallarrow-slider .avia-slideshow-dots a:hover {
  background-color: #888;
}

.socket_color .mejs-controls .mejs-time-rail .mejs-time-current,
.socket_color
  .mejs-controls
  .mejs-volume-button
  .mejs-volume-slider
  .mejs-volume-current,
.socket_color
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.socket_color .theme-color-bar .bar {
  background: #fff;
  background-image: linear-gradient(
    -45deg,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff
  );
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  -webkit-background-size: 6px 5px;
}

.socket_color .mejs-controls .mejs-time-rail .mejs-time-float {
  background: linear-gradient(#ffffff, #ffffff);
  color: #fff;
}

.socket_color .mejs-controls .mejs-time-rail .mejs-time-float-corner {
  border: solid 4px #fff;
  border-color: #fff transparent transparent;
}

.socket_color .bbp-reply-content,
.socket_color .bbp-topic-content,
.socket_color .bbp-body .super-sticky .page-numbers,
.socket_color .bbp-body .sticky .page-numbers {
  background: #111;
}

.socket_color .bbp-admin-links {
  color: #161616;
}

.avia_transform .socket_color .bbp-replies .bbp-reply-author:before,
.avia_transform .forum-search .socket_color .bbp-reply-author:before,
.avia_transform .forum-search .socket_color .bbp-topic-author:before {
  background-color: #111;
  border-color: #161616;
}

#scroll-top-link {
  color: #eee;
  border: 1px solid #161616;
}

.footer_color .rounded-container,
#top .footer_color .pagination a:hover,
.footer_color .small-preview,
.footer_color .fallback-post-type-icon {
  background: #919191;
  color: #333;
}

.footer_color .button:hover,
.footer_color .ajax_controlls a:hover,
.footer_color #submit:hover,
.footer_color .big_button:hover,
.footer_color .contentSlideControlls a:hover,
.footer_color #submit:hover,
.footer_color input[type="submit"]:hover {
  background-color: #fff;
  color: #333;
  border-color: #ddd;
}

.footer_color .timeline-bullet {
  background-color: #555;
  border-color: #333;
}

.footer_color .main_menu ul:first-child > li > ul,
#top .footer_color .avia_mega_div > .sub-menu {
  border-top-color: #aaa;
}

.footer_color .av-icon-display {
  background-color: #222;
  color: #919191;
}

.footer_color .ajax_search_image {
  background-color: #aaa;
  color: #333;
}

#top .footer_color .avia-color-theme-color {
  color: #fff;
  border-color: #888;
}

.footer_color .avia-color-theme-color-subtle:hover {
  background-color: #333;
  color: #eee;
}

.footer_color .avia-icon-list .iconlist_icon {
  background-color: #444;
}

.footer_color .sidebar_tab_icon {
  background-color: #555;
}

.footer_color div .sidebar_active_tab,
div .footer_color .tabcontainer.noborder_tabs .active_tab_content,
div .footer_color .tabcontainer.noborder_tabs .active_tab {
  color: #aaa;
  background-color: #333;
}

#top .footer_color .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .footer_color .avia-smallarrow-slider .avia-slideshow-dots a:hover {
  background-color: #919191;
}

.footer_color .pricing-table li.avia-pricing-row,
.footer_color .pricing-table li.avia-heading-row,
.footer_color .pricing-table li.avia-pricing-row .pricing-extra {
  background-color: #aaa;
  color: #fff;
  border-color: #ccc;
}

.footer_color .pricing-table li.avia-heading-row,
.footer_color .pricing-table li.avia-heading-row .pricing-extra {
  background-color: #bbb;
  color: #fff;
  border-color: #ccc;
}

.footer_color .mejs-controls .mejs-time-rail .mejs-time-current,
.footer_color
  .mejs-controls
  .mejs-volume-button
  .mejs-volume-slider
  .mejs-volume-current,
.footer_color
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.footer_color .theme-color-bar .bar {
  background: #aaa;
  background-image: linear-gradient(
    -45deg,
    #aaaaaa,
    #cccccc,
    #cccccc,
    #aaaaaa,
    #aaaaaa,
    #cccccc,
    #cccccc
  );
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  -webkit-background-size: 6px 5px;
}

.footer_color .mejs-controls .mejs-time-rail .mejs-time-float {
  background: linear-gradient(#cccccc, #aaaaaa);
  color: #fff;
}

.footer_color .mejs-controls .mejs-time-rail .mejs-time-float-corner {
  border: solid 4px #aaa;
  border-color: #aaa transparent transparent;
}

.footer_color .bbp-reply-content,
.footer_color .bbp-topic-content,
.footer_color .bbp-body .super-sticky .page-numbers,
.footer_color .bbp-body .sticky .page-numbers {
  background: #333;
}

.footer_color .bbp-admin-links {
  color: #555;
}

.avia_transform .footer_color .bbp-replies .bbp-reply-author:before,
.avia_transform .forum-search .footer_color .bbp-reply-author:before,
.avia_transform .forum-search .footer_color .bbp-topic-author:before {
  background-color: #333;
  border-color: #555;
}

.alternate_color .rounded-container,
#top .alternate_color .pagination a:hover,
.alternate_color .small-preview,
.alternate_color .fallback-post-type-icon {
  background: #a2a2a2;
  color: #fff;
}

.alternate_color .av-icon-display {
  background-color: #f8f8f8;
  color: #a2a2a2;
}

.alternate_color .avia-color-theme-color-subtle:hover {
  background-color: #fff;
  color: #444;
}

.alternate_color .av-masonry {
  background-color: #e7e7e7;
}

.alternate_color div .sidebar_active_tab,
div .alternate_color .tabcontainer.noborder_tabs .active_tab_content,
div .alternate_color .tabcontainer.noborder_tabs .active_tab {
  color: #888;
  background-color: #fff;
}

#top .alternate_color .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .alternate_color .avia-smallarrow-slider .avia-slideshow-dots a:hover {
  background-color: #a2a2a2;
}

.main_color .rounded-container,
#top .main_color .pagination a:hover,
.main_color .small-preview,
.main_color .fallback-post-type-icon {
  background: #8d8d8d;
  color: #f8f8f8;
}

.main_color .special-heading-inner-border {
  border-color: #666;
}

.main_color .button:hover,
.main_color .ajax_controlls a:hover,
.main_color #submit:hover,
.main_color .big_button:hover,
.main_color .contentSlideControlls a:hover,
.main_color #submit:hover,
.main_color input[type="submit"]:hover {
  background-color: #55adbd;
  color: #f8f8f8;
  border-color: #338b9b;
}

.main_color .timeline-bullet {
  background-color: #e1e1e1;
  border-color: #f8f8f8;
}

.main_color .av-icon-display {
  background-color: #fff;
  color: #8d8d8d;
}

.main_color .avia-color-theme-color-subtle:hover {
  background-color: #f8f8f8;
  color: #222;
}

.main_color div .sidebar_active_tab,
div .main_color .tabcontainer.noborder_tabs .active_tab_content,
div .main_color .tabcontainer.noborder_tabs .active_tab {
  color: #666;
  background-color: #f8f8f8;
}

#top .main_color .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .main_color .avia-smallarrow-slider .avia-slideshow-dots a:hover {
  background-color: #8d8d8d;
}

.avia_transform .main_color .bbp-replies .bbp-reply-author:before,
.avia_transform .forum-search .main_color .bbp-reply-author:before,
.avia_transform .forum-search .main_color .bbp-topic-author:before {
  background-color: #f8f8f8;
  border-color: #e1e1e1;
}

#scroll-top-link:hover {
  background-color: #fff;
  color: #2997ab;
  border: 1px solid #e1e1e1;
}

#mobile-advanced {
  background-color: #f8f8f8;
  color: #2997ab;
}

#mobile-advanced,
#mobile-advanced li > a,
#mobile-advanced .mega_menu_title {
  color: #666;
  border-color: #e1e1e1;
}

.avia-datepicker-div {
  background: #f8f8f8;
  border: 1px solid #e1e1e1;
}

.avia-datepicker-div a {
  color: #8d8d8d;
  background-color: #fff;
}

.avia-datepicker-div a.ui-state-hover {
  color: #fff;
  background-color: #8d8d8d;
}

.header_color .rounded-container,
#top .header_color .pagination a:hover,
.header_color .small-preview,
.header_color .fallback-post-type-icon {
  background: gray;
  color: #fff;
}

.header_color .special-heading-inner-border {
  border-color: #333;
}

.header_color .av-icon-display {
  background-color: #fcfcfc;
  color: gray;
}

.header_color .avia-color-theme-color-subtle:hover {
  background-color: #fff;
  color: #000;
}

.header_color .av-masonry {
  background-color: #ebebeb;
}

.header_color div .sidebar_active_tab,
div .header_color .tabcontainer.noborder_tabs .active_tab_content,
div .header_color .tabcontainer.noborder_tabs .active_tab {
  color: #333;
  background-color: #fff;
}

#top .header_color .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .header_color .avia-smallarrow-slider .avia-slideshow-dots a:hover {
  background-color: gray;
}

#advanced_menu_toggle,
#advanced_menu_hide {
  background-color: #fff;
  color: #333;
  border-color: #e1e1e1;
}

.socket_color .summary div {
  border-color: #000;
}

.socket_color .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #161616;
}

.footer_color .summary div {
  border-color: #222;
}

.footer_color .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #555;
}

#top .footer_color .quantity input.plus,
#top .footer_color .quantity input.minus {
  background-color: #111;
  color: #919191;
  border-color: #555;
}

.alternate_color .summary div {
  border-color: #f8f8f8;
}

#top .alternate_color .quantity input.plus,
#top .alternate_color .quantity input.minus {
  background-color: #e7e7e7;
  color: #a2a2a2;
  border-color: #e1e1e1;
}

#top .main_color .quantity input.plus,
#top .main_color .quantity input.minus {
  background-color: #eee;
  color: #8d8d8d;
  border-color: #e1e1e1;
}

.added_to_cart_notification,
.added_to_cart_notification .avia-arrow {
  background-color: #f8f8f8;
  color: #8d8d8d;
  border-color: #e1e1e1;
}

.header_color .summary div {
  border-color: #fcfcfc;
}

#top .header_color .quantity input.plus,
#top .header_color .quantity input.minus {
  background-color: #ebebeb;
  color: gray;
  border-color: #e1e1e1;
}

.bbp-topics .bbp-body .bbp-topic-title:before {
  content: "\E83b";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .topic-voices-multi .bbp-topic-title:before {
  content: "\E83c";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .super-sticky .bbp-topic-title:before {
  content: "\E808";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .sticky .bbp-topic-title:before {
  content: "\E809";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .status-closed .bbp-topic-title:before {
  content: "\E824";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .super-sticky.status-closed .bbp-topic-title:before {
  content: "\E809\E824";
  font-family: entypo-fontello;
}

.bbp-topics .bbp-body .sticky.status-closed .bbp-topic-title:before {
  content: "\E808\E824";
  font-family: entypo-fontello;
}

#top .ls-nav-prev:before {
  content: "\E87c";
  font-family: entypo-fontello;
}

#top .ls-nav-next:before {
  content: "\E87d";
  font-family: entypo-fontello;
}

#top .ls-nav-stop:before {
  content: "\E899";
  font-family: entypo-fontello;
}

.image-overlay .image-overlay-inside:before {
  content: "\E869";
  font-family: entypo-fontello;
}

.image-overlay.overlay-type-extern .image-overlay-inside:before {
  content: "\f064";
  font-family: FontAwesome, Arial, sans-serif;
}

.vlak {
  background-color: #2997ab;
  width: 100%;
  color: #fff;
  padding: 30px;
}

.main_color .ajax_search_image {
  background-color: #2997ab;
  color: #f8f8f8;
}

.socket_color,
.socket_color div,
.socket_color header,
.socket_color main,
.socket_color aside,
.socket_color footer,
.socket_color article,
.socket_color nav,
.socket_color section,
.socket_color span,
.socket_color applet,
.socket_color object,
.socket_color iframe,
.socket_color h1,
.socket_color h2,
.socket_color h3,
.socket_color h4,
.socket_color h5,
.socket_color h6,
.socket_color p,
.socket_color blockquote,
.socket_color pre,
.socket_color a,
.socket_color abbr,
.socket_color acronym,
.socket_color address,
.socket_color big,
.socket_color cite,
.socket_color code,
.socket_color del,
.socket_color dfn,
.socket_color em,
.socket_color img,
.socket_color ins,
.socket_color kbd,
.socket_color q,
.socket_color s,
.socket_color samp,
.socket_color small,
.socket_color strike,
.socket_color strong,
.socket_color sub,
.socket_color sup,
.socket_color tt,
.socket_color var,
.socket_color b,
.socket_color u,
.socket_color i,
.socket_color center,
.socket_color dl,
.socket_color dt,
.socket_color dd,
.socket_color ol,
.socket_color ul,
.socket_color li,
.socket_color fieldset,
.socket_color form,
.socket_color label,
.socket_color legend,
.socket_color table,
.socket_color caption,
.socket_color tbody,
.socket_color tfoot,
.socket_color thead,
.socket_color tr,
.socket_color th,
.socket_color td,
.socket_color article,
.socket_color aside,
.socket_color canvas,
.socket_color details,
.socket_color embed,
.socket_color figure,
.socket_color fieldset,
.socket_color figcaption,
.socket_color footer,
.socket_color header,
.socket_color hgroup,
.socket_color menu,
.socket_color nav,
.socket_color output,
.socket_color ruby,
.socket_color section,
.socket_color summary,
.socket_color time,
.socket_color mark,
.socket_color audio,
.socket_color video,
#top .socket_color .pullquote_boxed,
.responsive #top .socket_color .avia-testimonial,
.responsive #top.avia-blank #main .socket_color.container_wrap:first-child,
#top .socket_color.fullsize .template-blog .post_delimiter,
.socket_color#header .avia_mega_div > .sub-menu.avia_mega_hr,
.socket_color .avia-icon-list .iconlist-timeline,
.socket_color .pricing-table.avia-desc-col .avia-heading-row,
.socket_color .pricing-table.avia-desc-col .avia-pricing-row,
.socket_color .thumbnail_container img,
#top .socket_color #main .order_details {
  border-color: #161616;
}

.socket_color,
.socket_color .site-background,
.socket_color .first-quote,
.socket_color .related_image_wrap,
.socket_color .gravatar img .socket_color .hr_content,
.socket_color .news-thumb,
.socket_color .post-format-icon,
.socket_color .ajax_controlls a,
.socket_color .tweet-text.avatar_no,
.socket_color .big-preview,
.socket_color .toggler,
.socket_color .toggler.activeTitle:hover,
.socket_color #js_sort_items,
.socket_color.inner-entry,
.socket_color .grid-entry-title,
.socket_color .related-format-icon,
.grid-entry .socket_color .avia-arrow,
.socket_color .avia-gallery-big,
.socket_color .avia-gallery-big,
.socket_color .avia-gallery img,
.socket_color .grid-content,
.socket_color tr:nth-child(even),
.socket_color .avia-data-table .avia-heading-row .avia-desc-col,
.socket_color .avia-data-table .avia-highlight-col,
.socket_color .pricing-table > li:nth-child(even),
body .socket_color .pricing-table.avia-desc-col li {
  background-color: #111;
  color: #eee;
}

.socket_color .heading-color,
.socket_color a.iconbox_icon:hover,
.socket_color h1,
.socket_color h2,
.socket_color h3,
.socket_color h4,
.socket_color h5,
.socket_color h6,
.socket_color .sidebar .current_page_item > a,
.socket_color .sidebar .current-menu-item > a,
.socket_color .pagination .current,
.socket_color .pagination a:hover,
.socket_color strong.avia-testimonial-name,
.socket_color .heading,
.socket_color .toggle_content strong,
.socket_color .toggle_content strong a,
.socket_color .tab_content strong,
.socket_color .tab_content strong a,
.socket_color .asc_count,
.socket_color .avia-testimonial-content strong,
.socket_color div .news-headline,
.socket_color a,
.socket_color .widget_first,
.socket_color strong,
.socket_color b,
.socket_color b a,
.socket_color strong a,
.socket_color #js_sort_items a:hover,
.socket_color #js_sort_items a.active_sort,
.socket_color .av-sort-by-term a.active_sort,
.socket_color .special_amp,
.socket_color .taglist a.activeFilter,
.socket_color #commentform .required,
#top .socket_color .av-no-color.av-icon-style-border a.av-icon-char,
#top .socket_color .av-masonry-entry.format-quote:hover .av-icon-display,
#top .socket_color .main_menu .menu .avia_mega_div ul .current-menu-item > a,
.socket_color .ajax_search_title,
.socket_color .required,
.socket_color .bbp-author-name,
.footer_color a:hover,
.footer_color h1 a:hover,
.footer_color h2 a:hover,
.footer_color h3 a:hover,
.footer_color h4 a:hover,
.footer_color h5 a:hover,
.footer_color h6 a:hover,
.footer_color .template-search a.news-content:hover,
.footer_color .template-archives .tabcontainer a:hover,
.footer_color dropcap2,
.footer_color dropcap3,
.footer_color avia_button,
.footer_color avia_button:hover,
.footer_color .on-primary-color,
.footer_color .on-primary-color:hover,
.alternate_color dropcap2,
.alternate_color dropcap3,
.alternate_color avia_button,
.alternate_color avia_button:hover,
.alternate_color .on-primary-color,
.alternate_color .on-primary-color:hover,
.main_color dropcap2,
.main_color dropcap3,
.main_color avia_button,
.main_color avia_button:hover,
.main_color .on-primary-color,
.main_color .on-primary-color:hover,
.header_color dropcap2,
.header_color dropcap3,
.header_color avia_button,
.header_color avia_button:hover,
.header_color .on-primary-color,
.header_color .on-primary-color:hover,
#mobile-advanced li > a:hover:before,
.socket_color #shop_header a:hover,
#top .socket_color .widget_layered_nav ul li.chosen a,
#top .socket_color .widget_layered_nav ul li.chosen small,
#top .socket_color .price,
.socket_color .stock,
#top #wrap_all .socket_color ins,
.socket_color .sort-param > li:hover > span,
.socket_color .sort-param > li:hover > a,
.socket_color .sort-param > li:hover ul,
.socket_color .product-sorting strong,
.socket_color .sort-param .current-param a,
.footer_color #shop_header a:hover,
#top .footer_color .widget_layered_nav ul li.chosen a,
#top .footer_color .widget_layered_nav ul li.chosen small,
.footer_color .dynamic-title a:hover,
#top .footer_color .sort-param a:hover,
.alternate_color #shop_header a:hover,
#top .alternate_color .widget_layered_nav ul li.chosen a,
#top .alternate_color .widget_layered_nav ul li.chosen small,
.main_color #shop_header a:hover,
#top .main_color .widget_layered_nav ul li.chosen a,
#top .main_color .widget_layered_nav ul li.chosen small,
.header_color #shop_header a:hover,
#top .header_color .widget_layered_nav ul li.chosen a,
#top .header_color .widget_layered_nav ul li.chosen small {
  color: #fff;
}

.socket_color .meta-color,
.socket_color .sidebar,
.socket_color .sidebar a,
.socket_color .minor-meta,
.socket_color .minor-meta a,
.socket_color .text-sep,
.socket_color blockquote,
.socket_color .post_nav a,
.socket_color .comment-text,
.socket_color .side-container-inner,
.socket_color .news-time,
.socket_color .pagination a,
.socket_color .pagination span,
.socket_color .tweet-text.avatar_no .tweet-time,
#top .socket_color .extra-mini-title,
.socket_color .team-member-job-title,
.socket_color .team-social a,
.socket_color #js_sort_items a,
.grid-entry-excerpt,
.socket_color .avia-testimonial-subtitle,
.socket_color .commentmetadata a,
.socket_color .social_bookmarks a,
.socket_color .meta-heading > *,
.socket_color .slide-meta,
.socket_color .slide-meta a,
.socket_color .taglist,
.socket_color .taglist a,
.socket_color .phone-info,
.socket_color .phone-info a,
.socket_color .av-sort-by-term a,
.socket_color .sub_menu > ul > li > a,
.socket_color .sub_menu > div > ul > li > a,
.socket_color .main_menu ul:first-child > li > a,
#top .socket_color .main_menu .menu ul .current_page_item > a,
#top .socket_color .main_menu .menu ul .current-menu-item > a,
#top .socket_color .sub_menu li ul a,
.socket_color .ajax_search_excerpt,
.socket_color .iconlist_content,
.socket_color .sidebar_tab,
.socket_color .tabcontainer .tab,
.socket_color table caption,
.socket_color tr:nth-child(even),
.socket_color .pricing-table > li:nth-child(even),
.socket_color .bbp-meta,
.socket_color .bbp-author-role,
.socket_color .bbp-author-ip,
.socket_color .bbp-pagination-count,
.socket_color .bbp-topics .bbp-body .bbp-topic-title:before,
.alternate_color .breadcrumb,
.alternate_color .breadcrumb a,
#top .alternate_color.title_container .main-title,
#top .alternate_color.title_container .main-title a,
#top .alternate_color .main_menu .menu ul li > a:hover,
.alternate_color .main_menu ul:first-child > li a:hover,
.alternate_color .main_menu ul:first-child > li.current-menu-item > a,
.alternate_color .main_menu ul:first-child > li.current_page_item > a,
.alternate_color .main_menu ul:first-child > li.active-parent-item > a,
.alternate_color .sub_menu > ul > li > a:hover,
.alternate_color .sub_menu > div > ul > li > a:hover,
#top .alternate_color .sub_menu ul li a:hover,
.alternate_color .sub_menu ul:first-child > li.current-menu-item > a,
.alternate_color .sub_menu ul:first-child > li.current_page_item > a,
.alternate_color .sub_menu ul:first-child > li.active-parent-item > a,
.alternate_color .template-archives .tabcontainer a,
#top .alternate_color .tabcontainer .tab:hover,
.socket_color .woocommerce-tabs .tabs a,
.socket_color .product_meta,
.socket_color .quantity input.qty,
.socket_color .cart_dropdown .dropdown_widget,
.socket_color .avia_select_fake_val,
.socket_color address,
.socket_color .product > a .socket_color .product_excerpt,
.socket_color .term_description,
#top .socket_color .price .from,
#top #wrap_all .socket_color del,
.socket_color .dynamic-title .dynamic-heading,
.socket_color .dynamic-title a,
.socket_color .sort-param a,
.alternate_color .cart_dropdown .dropdown_widget li a,
#top .alternate_color .avia_cart_buttons .button,
#top .alternate_color .dropdown_widget .buttons .button,
.alternate_color .cart_dropdown_first .cart_dropdown_link {
  color: #888;
}

.socket_color .special-heading-inner-border,
#top .socket_color a:hover .avia-bullet {
  border-color: #eee;
}

.socket_color .meta-heading .special-heading-inner-border,
.alternate_color .special-heading-inner-border,
.socket_color .avia-bullet {
  border-color: #888;
}

.socket_color a:hover,
.socket_color h1 a:hover,
.socket_color h2 a:hover,
.socket_color h3 a:hover,
.socket_color h4 a:hover,
.socket_color h5 a:hover,
.socket_color h6 a:hover,
.socket_color .template-search a.news-content:hover,
.socket_color .breadcrumb,
.socket_color .breadcrumb a,
#top .socket_color.title_container .main-title,
#top .socket_color.title_container .main-title a,
#top .socket_color .main_menu .menu ul li > a:hover,
.socket_color .main_menu ul:first-child > li a:hover,
.socket_color .main_menu ul:first-child > li.current-menu-item > a,
.socket_color .main_menu ul:first-child > li.current_page_item > a,
.socket_color .main_menu ul:first-child > li.active-parent-item > a,
.socket_color .sub_menu > ul > li > a:hover,
.socket_color .sub_menu > div > ul > li > a:hover,
#top .socket_color .sub_menu ul li a:hover,
.socket_color .sub_menu ul:first-child > li.current-menu-item > a,
.socket_color .sub_menu ul:first-child > li.current_page_item > a,
.socket_color .sub_menu ul:first-child > li.active-parent-item > a,
.socket_color .template-archives .tabcontainer a,
#top .socket_color .tabcontainer .tab:hover,
.socket_color .template-archives .tabcontainer a:hover,
.footer_color .heading-color,
.footer_color a.iconbox_icon:hover,
.footer_color h1,
.footer_color h2,
.footer_color h3,
.footer_color h4,
.footer_color h5,
.footer_color h6,
.footer_color .sidebar .current_page_item > a,
.footer_color .sidebar .current-menu-item > a,
.footer_color .pagination .current,
.footer_color .pagination a:hover,
.footer_color strong.avia-testimonial-name,
.footer_color .heading,
.footer_color .toggle_content strong,
.footer_color .toggle_content strong a,
.footer_color .tab_content strong,
.footer_color .tab_content strong a,
.footer_color .asc_count,
.footer_color .avia-testimonial-content strong,
.footer_color div .news-headline,
.footer_color .ajax_search_title,
.footer_color .bbp-author-name,
.socket_color .cart_dropdown .dropdown_widget li a,
#top .socket_color .avia_cart_buttons .button,
#top .socket_color .dropdown_widget .buttons .button,
.socket_color .cart_dropdown_first .cart_dropdown_link,
.socket_color .dynamic-title a:hover,
#top .socket_color .sort-param a:hover,
.footer_color .sort-param > li:hover > span,
.footer_color .sort-param > li:hover > a,
.footer_color .sort-param > li:hover ul,
.footer_color .product-sorting strong {
  color: #eee;
}

.socket_color .primary-background,
.socket_color .primary-background a,
div .socket_color .button,
.socket_color #submit,
.socket_color input[type="submit"],
.socket_color .small-preview:hover,
.socket_color .avia-menu-fx,
.socket_color .avia-menu-fx .avia-arrow,
.socket_color.iconbox_top .iconbox_icon,
.socket_color .avia-data-table th.avia-highlight-col,
.socket_color .avia-color-theme-color,
.socket_color .avia-color-theme-color:hover,
.socket_color .image-overlay .image-overlay-inside:before,
.socket_color .comment-count,
.socket_color .av_dropcap2,
.socket_color .av-masonry-entry:hover .av-icon-display {
  background-color: #fff;
  color: #111;
  border-color: #ddd;
}

.socket_color table,
.socket_color .widget_nav_menu ul:first-child > .current-menu-item,
.socket_color .widget_nav_menu ul:first-child > .current_page_item,
.socket_color .widget_nav_menu ul:first-child > .current-menu-ancestor,
.socket_color .pagination .current,
.socket_color .pagination a,
.socket_color.iconbox_top .iconbox_content,
.socket_color .av_promobox,
.socket_color .toggle_content,
.socket_color .toggler:hover,
.socket_color .related_posts_default_image,
.socket_color .search-result-counter,
.socket_color .container_wrap_meta,
.socket_color .avia-content-slider .slide-image,
.socket_color .avia-slider-testimonials .avia-testimonial-content,
.socket_color .avia-testimonial-arrow-wrap .avia-arrow,
.socket_color .news-thumb,
.socket_color .portfolio-preview-content,
.socket_color .portfolio-preview-content .avia-arrow,
.socket_color tr:nth-child(odd),
.socket_color .pricing-table > li:nth-child(odd),
.socket_color .pricing-extra {
  background: #000;
}

#top .socket_color .post_timeline li:hover .timeline-bullet,
.socket_color .sidebar_tab:hover .sidebar_tab_icon,
.main_color .av-masonry {
  background-color: #eee;
}

.socket_color blockquote,
.socket_color .avia-bullet,
.socket_color .av-no-color.av-icon-style-border a.av-icon-char,
.socket_color .sort-param .current-param .avia-bullet,
#top .footer_color a:hover .avia-bullet,
.main_color .summary div {
  border-color: #fff;
}

.socket_color .header_bg,
.socket_color .main_menu ul,
.socket_color .main_menu .menu ul li a,
.socket_color .pointer_arrow_wrap .pointer_arrow,
.socket_color .avia_mega_div,
.socket_color .avia-tt,
.socket_color .avia-tt .avia-arrow,
.socket_color .avia-tt .avia-arrow {
  background-color: #111;
  color: #888;
}

.socket_color .main_menu .menu ul li a:hover,
#top .socket_color .ajax_search_entry:hover,
.socket_color .av-masonry,
.socket_color .progress,
.socket_color .bbp-topics .bbp-header,
.socket_color .bbp-topics .bbp-header,
.socket_color .bbp-forums .bbp-header,
.socket_color .bbp-topics-front ul.super-sticky,
.socket_color .bbp-topics ul.super-sticky,
.socket_color .bbp-topics ul.sticky,
.socket_color .bbp-forum-content ul.sticky,
.socket_color .bbp-body .page-numbers,
.socket_color .widget_display_stats dt,
.socket_color .widget_display_stats dd,
.socket_color .woocommerce-tabs .tabs .active,
.socket_color div.product .woocommerce-tabs .panel,
.socket_color .activeslideThumb,
.socket_color #payment li,
.socket_color .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.socket_color .avia_cart,
.socket_color form.login,
.socket_color form.register,
.socket_color .col-1,
.socket_color .col-2,
.socket_color .variations_form,
.socket_color .dynamic-title,
.socket_color .single-product-main-image .thumbnails a,
.socket_color .quantity input.qty,
.socket_color .avia_cart_buttons,
#top .socket_color .dropdown_widget .buttons,
div .dropdown_widget .cart_list li:hover,
.socket_color .woocommerce-info,
.socket_color #payment,
.socket_color .sort-param > li > span,
.socket_color .sort-param > li > a,
.socket_color .sort-param ul {
  background-color: #000;
}

.socket_color .sub_menu li ul a,
.socket_color #payment,
.socket_color .sub_menu ul li,
.socket_color .sub_menu ul,
#top .socket_color .sub_menu li li a:hover,
#top .socket_color .invers-color .input-text,
#top .socket_color .invers-color input[type="text"],
#top .socket_color .invers-color input[type="input"],
#top .socket_color .invers-color input[type="password"],
#top .socket_color .invers-color input[type="email"],
#top .socket_color .invers-color input[type="number"],
#top .socket_color .invers-color input[type="url"],
#top .socket_color .invers-color input[type="tel"],
#top .socket_color .invers-color input[type="search"],
#top .socket_color .invers-color textarea,
#top .socket_color .invers-color select,
.socket_color .av-masonry-pagination,
.socket_color .av-masonry-pagination:hover,
.socket_color .hr-short .hr-inner-style,
.socket_color .hr-short .hr-inner,
html,
#scroll-top-link,
.footer_color .av-masonry,
.socket_color div.product .woocommerce-tabs ul.tabs li.active a,
.socket_color .cart_dropdown .dropdown_widget .widget_shopping_cart_content,
.socket_color .cart_dropdown_link,
.socket_color .inner_product_header,
.socket_color .avia-arrow,
#top .socket_color .variations select,
#top .socket_color .variations input,
#top .socket_color #reviews input[type="text"],
.socket_color #reviews .comment-text,
.socket_color #reviews #comment,
.socket_color .single-product-main-image .images a,
#top .socket_color .shop_table.cart .input-text,
#top .socket_color form.login .input-text,
#top .socket_color form.register .input-text {
  background-color: #111;
}

.socket_color .ajax_load,
#top .socket_color .sidebar_active_tab .sidebar_tab_icon,
#top .footer_color .post_timeline li:hover .timeline-bullet,
.footer_color .sidebar_tab:hover .sidebar_tab_icon,
.alternate_color .sub_menu li ul a,
.alternate_color #payment,
.alternate_color .sub_menu ul li,
.alternate_color .sub_menu ul,
#top .alternate_color .sub_menu li li a:hover,
#top .alternate_color .invers-color .input-text,
#top .alternate_color .invers-color input[type="text"],
#top .alternate_color .invers-color input[type="input"],
#top .alternate_color .invers-color input[type="password"],
#top .alternate_color .invers-color input[type="email"],
#top .alternate_color .invers-color input[type="number"],
#top .alternate_color .invers-color input[type="url"],
#top .alternate_color .invers-color input[type="tel"],
#top .alternate_color .invers-color input[type="search"],
#top .alternate_color .invers-color textarea,
#top .alternate_color .invers-color select,
.alternate_color .av-masonry-pagination,
.alternate_color .av-masonry-pagination:hover,
.alternate_color .hr-short .hr-inner-style,
.alternate_color .hr-short .hr-inner,
.main_color .main_menu .menu ul li a:hover,
#top .main_color .ajax_search_entry:hover,
.main_color .progress,
.main_color .bbp-topics .bbp-header,
.main_color .bbp-topics .bbp-header,
.main_color .bbp-forums .bbp-header,
.main_color .bbp-topics-front ul.super-sticky,
.main_color .bbp-topics ul.super-sticky,
.main_color .bbp-topics ul.sticky,
.main_color .bbp-forum-content ul.sticky,
.main_color .bbp-body .page-numbers,
.main_color .widget_display_stats dt,
.main_color .widget_display_stats dd,
.header_color .sub_menu li ul a,
.header_color #payment,
.header_color .sub_menu ul li,
.header_color .sub_menu ul,
#top .header_color .sub_menu li li a:hover,
#top .header_color .invers-color .input-text,
#top .header_color .invers-color input[type="text"],
#top .header_color .invers-color input[type="input"],
#top .header_color .invers-color input[type="password"],
#top .header_color .invers-color input[type="email"],
#top .header_color .invers-color input[type="number"],
#top .header_color .invers-color input[type="url"],
#top .header_color .invers-color input[type="tel"],
#top .header_color .invers-color input[type="search"],
#top .header_color .invers-color textarea,
#top .header_color .invers-color select,
.header_color .av-masonry-pagination,
.header_color .av-masonry-pagination:hover,
.header_color .hr-short .hr-inner-style,
.header_color .hr-short .hr-inner,
#main,
.html_stretched #wrap_all,
.socket_color .widget_layered_nav ul li.chosen,
.socket_color
  .widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
#top .socket_color a.remove,
#top .socket_color .onsale,
.alternate_color div.product .woocommerce-tabs ul.tabs li.active a,
.alternate_color .cart_dropdown .dropdown_widget .widget_shopping_cart_content,
.alternate_color .cart_dropdown_link,
.alternate_color .inner_product_header,
.alternate_color .avia-arrow,
#top .alternate_color .variations select,
#top .alternate_color .variations input,
#top .alternate_color #reviews input[type="text"],
.alternate_color #reviews .comment-text,
.alternate_color #reviews #comment,
.alternate_color .single-product-main-image .images a,
#top .alternate_color .shop_table.cart .input-text,
#top .alternate_color form.login .input-text,
#top .alternate_color form.register .input-text,
.main_color .woocommerce-tabs .tabs .active,
.main_color div.product .woocommerce-tabs .panel,
.main_color .activeslideThumb,
.main_color #payment li,
.main_color .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.main_color .avia_cart,
.main_color form.login,
.main_color form.register,
.main_color .col-1,
.main_color .col-2,
.main_color .variations_form,
.main_color .dynamic-title,
.main_color .single-product-main-image .thumbnails a,
.main_color .quantity input.qty,
.main_color .avia_cart_buttons,
#top .main_color .dropdown_widget .buttons,
div .dropdown_widget .cart_list li:hover,
.main_color .woocommerce-info,
.main_color #payment,
.main_color .sort-param > li > span,
.main_color .sort-param > li > a,
.main_color .sort-param ul,
.header_color div.product .woocommerce-tabs ul.tabs li.active a,
.header_color .cart_dropdown .dropdown_widget .widget_shopping_cart_content,
.header_color .cart_dropdown_link,
.header_color .inner_product_header,
.header_color .avia-arrow,
#top .header_color .variations select,
#top .header_color .variations input,
#top .header_color #reviews input[type="text"],
.header_color #reviews .comment-text,
.header_color #reviews #comment,
.header_color .single-product-main-image .images a,
#top .header_color .shop_table.cart .input-text,
#top .header_color form.login .input-text,
#top .header_color form.register .input-text {
  background-color: #fff;
}

.socket_color .avia-color-theme-color-subtle,
div .socket_color .tabcontainer .active_tab_content,
div .socket_color .tabcontainer .active_tab {
  background-color: #000;
  color: #eee;
}

#top .socket_color .input-text,
#top .socket_color input[type="text"],
#top .socket_color input[type="input"],
#top .socket_color input[type="password"],
#top .socket_color input[type="email"],
#top .socket_color input[type="number"],
#top .socket_color input[type="url"],
#top .socket_color input[type="tel"],
#top .socket_color input[type="search"],
#top .socket_color textarea,
#top .socket_color select,
#top .socket_color .chzn-container-single .chzn-single,
#top .socket_color .quantity input.plus,
#top .socket_color .quantity input.minus {
  background-color: #000;
  color: #888;
  border-color: #161616;
}

#top .avia-smallarrow-slider .avia-slideshow-dots a,
.main_menu .menu ul li a:hover,
#top .header_color .ajax_search_entry:hover,
.header_color .progress,
.header_color .bbp-topics .bbp-header,
.header_color .bbp-topics .bbp-header,
.header_color .bbp-forums .bbp-header,
.header_color .bbp-topics-front ul.super-sticky,
.header_color .bbp-topics ul.super-sticky,
.header_color .bbp-topics ul.sticky,
.header_color .bbp-forum-content ul.sticky,
.header_color .bbp-body .page-numbers,
.header_color .widget_display_stats dt,
.header_color .widget_display_stats dd,
.header_color .woocommerce-tabs .tabs .active,
.header_color div.product .woocommerce-tabs .panel,
.header_color .activeslideThumb,
.header_color #payment li,
.header_color .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.header_color .avia_cart,
.header_color form.login,
.header_color form.register,
.header_color .col-1,
.header_color .col-2,
.header_color .variations_form,
.header_color .dynamic-title,
.header_color .single-product-main-image .thumbnails a,
.header_color .quantity input.qty,
.header_color .avia_cart_buttons,
#top .header_color .dropdown_widget .buttons,
div .dropdown_widget .cart_list li:hover,
.header_color .woocommerce-info,
.header_color #payment,
.header_color .sort-param > li > span,
.header_color .sort-param > li > a,
.header_color .sort-param ul {
  background-color: #fcfcfc;
}

.socket_color .pricing-table li.avia-pricing-row,
.socket_color .pricing-table li.avia-heading-row,
.socket_color .pricing-table li.avia-pricing-row .pricing-extra,
.socket_color .pricing-table li.avia-heading-row,
.socket_color .pricing-table li.avia-heading-row .pricing-extra {
  background-color: #fff;
  color: #111;
  border-color: #fff;
}

.footer_color,
.footer_color div,
.footer_color header,
.footer_color main,
.footer_color aside,
.footer_color footer,
.footer_color article,
.footer_color nav,
.footer_color section,
.footer_color span,
.footer_color applet,
.footer_color object,
.footer_color iframe,
.footer_color h1,
.footer_color h2,
.footer_color h3,
.footer_color h4,
.footer_color h5,
.footer_color h6,
.footer_color p,
.footer_color blockquote,
.footer_color pre,
.footer_color a,
.footer_color abbr,
.footer_color acronym,
.footer_color address,
.footer_color big,
.footer_color cite,
.footer_color code,
.footer_color del,
.footer_color dfn,
.footer_color em,
.footer_color img,
.footer_color ins,
.footer_color kbd,
.footer_color q,
.footer_color s,
.footer_color samp,
.footer_color small,
.footer_color strike,
.footer_color strong,
.footer_color sub,
.footer_color sup,
.footer_color tt,
.footer_color var,
.footer_color b,
.footer_color u,
.footer_color i,
.footer_color center,
.footer_color dl,
.footer_color dt,
.footer_color dd,
.footer_color ol,
.footer_color ul,
.footer_color li,
.footer_color fieldset,
.footer_color form,
.footer_color label,
.footer_color legend,
.footer_color table,
.footer_color caption,
.footer_color tbody,
.footer_color tfoot,
.footer_color thead,
.footer_color tr,
.footer_color th,
.footer_color td,
.footer_color article,
.footer_color aside,
.footer_color canvas,
.footer_color details,
.footer_color embed,
.footer_color figure,
.footer_color fieldset,
.footer_color figcaption,
.footer_color footer,
.footer_color header,
.footer_color hgroup,
.footer_color menu,
.footer_color nav,
.footer_color output,
.footer_color ruby,
.footer_color section,
.footer_color summary,
.footer_color time,
.footer_color mark,
.footer_color audio,
.footer_color video,
#top .footer_color .pullquote_boxed,
.responsive #top .footer_color .avia-testimonial,
.responsive #top.avia-blank #main .footer_color.container_wrap:first-child,
#top .footer_color.fullsize .template-blog .post_delimiter,
.footer_color#header .avia_mega_div > .sub-menu.avia_mega_hr,
.footer_color .avia-icon-list .iconlist-timeline,
.footer_color .pricing-table.avia-desc-col .avia-heading-row,
.footer_color .pricing-table.avia-desc-col .avia-pricing-row,
.footer_color .thumbnail_container img,
#top .footer_color #main .order_details {
  border-color: #555;
}

.footer_color,
.footer_color .site-background,
.footer_color .first-quote,
.footer_color .related_image_wrap,
.footer_color .gravatar img .footer_color .hr_content,
.footer_color .news-thumb,
.footer_color .post-format-icon,
.footer_color .ajax_controlls a,
.footer_color .tweet-text.avatar_no,
.footer_color .big-preview,
.footer_color .toggler,
.footer_color .toggler.activeTitle:hover,
.footer_color #js_sort_items,
.footer_color.inner-entry,
.footer_color .grid-entry-title,
.footer_color .related-format-icon,
.grid-entry .footer_color .avia-arrow,
.footer_color .avia-gallery-big,
.footer_color .avia-gallery-big,
.footer_color .avia-gallery img,
.footer_color .grid-content,
.footer_color tr:nth-child(even),
.footer_color .avia-data-table .avia-heading-row .avia-desc-col,
.footer_color .avia-data-table .avia-highlight-col,
.footer_color .pricing-table > li:nth-child(even),
body .footer_color .pricing-table.avia-desc-col li {
  background-color: #333;
  color: #aaa;
}

.footer_color .meta-color,
.footer_color .sidebar,
.footer_color .sidebar a,
.footer_color .minor-meta,
.footer_color .minor-meta a,
.footer_color .text-sep,
.footer_color blockquote,
.footer_color .post_nav a,
.footer_color .comment-text,
.footer_color .side-container-inner,
.footer_color .news-time,
.footer_color .pagination a,
.footer_color .pagination span,
.footer_color .tweet-text.avatar_no .tweet-time,
#top .footer_color .extra-mini-title,
.footer_color .team-member-job-title,
.footer_color .team-social a,
.footer_color #js_sort_items a,
.grid-entry-excerpt,
.footer_color .avia-testimonial-subtitle,
.footer_color .commentmetadata a,
.footer_color .social_bookmarks a,
.footer_color .meta-heading > *,
.footer_color .slide-meta,
.footer_color .slide-meta a,
.footer_color .taglist,
.footer_color .taglist a,
.footer_color .phone-info,
.footer_color .phone-info a,
.footer_color .av-sort-by-term a,
.footer_color .sub_menu > ul > li > a,
.footer_color .sub_menu > div > ul > li > a,
.footer_color .main_menu ul:first-child > li > a,
#top .footer_color .main_menu .menu ul .current_page_item > a,
#top .footer_color .main_menu .menu ul .current-menu-item > a,
#top .footer_color .sub_menu li ul a,
.footer_color .ajax_search_excerpt,
.footer_color .iconlist_content,
.footer_color .sidebar_tab,
.footer_color .tabcontainer .tab,
.footer_color table caption,
.footer_color tr:nth-child(even),
.footer_color .pricing-table > li:nth-child(even),
.footer_color .bbp-meta,
.footer_color .bbp-author-role,
.footer_color .bbp-author-ip,
.footer_color .bbp-pagination-count,
.footer_color .bbp-topics .bbp-body .bbp-topic-title:before,
#footer .widgettitle,
.footer_color .woocommerce-tabs .tabs a,
.footer_color .product_meta,
.footer_color .quantity input.qty,
.footer_color .cart_dropdown .dropdown_widget,
.footer_color .avia_select_fake_val,
.footer_color address,
.footer_color .product > a .footer_color .product_excerpt,
.footer_color .term_description,
#top .footer_color .price .from,
#top #wrap_all .footer_color del,
.footer_color .dynamic-title .dynamic-heading,
.footer_color .dynamic-title a,
.footer_color .sort-param a {
  color: #e0e0e0;
}

.footer_color .special-heading-inner-border,
.footer_color blockquote,
.footer_color .avia-bullet,
.footer_color .av-no-color.av-icon-style-border a.av-icon-char,
.footer_color .sort-param .current-param .avia-bullet {
  border-color: #aaa;
}

.footer_color .meta-heading .special-heading-inner-border,
.footer_color .avia-bullet {
  border-color: #919191;
}

.footer_color a,
.footer_color .widget_first,
.footer_color strong,
.footer_color b,
.footer_color b a,
.footer_color strong a,
.footer_color #js_sort_items a:hover,
.footer_color #js_sort_items a.active_sort,
.footer_color .av-sort-by-term a.active_sort,
.footer_color .special_amp,
.footer_color .taglist a.activeFilter,
.footer_color #commentform .required,
#top .footer_color .av-no-color.av-icon-style-border a.av-icon-char,
.footer_color .breadcrumb,
.footer_color .breadcrumb a,
#top .footer_color.title_container .main-title,
#top .footer_color.title_container .main-title a,
#top .footer_color .av-masonry-entry.format-quote:hover .av-icon-display,
#top .footer_color .main_menu .menu ul li > a:hover,
.footer_color .main_menu ul:first-child > li a:hover,
.footer_color .main_menu ul:first-child > li.current-menu-item > a,
.footer_color .main_menu ul:first-child > li.current_page_item > a,
.footer_color .main_menu ul:first-child > li.active-parent-item > a,
#top .footer_color .main_menu .menu .avia_mega_div ul .current-menu-item > a,
.footer_color .sub_menu > ul > li > a:hover,
.footer_color .sub_menu > div > ul > li > a:hover,
#top .footer_color .sub_menu ul li a:hover,
.footer_color .sub_menu ul:first-child > li.current-menu-item > a,
.footer_color .sub_menu ul:first-child > li.current_page_item > a,
.footer_color .sub_menu ul:first-child > li.active-parent-item > a,
.footer_color .required,
.footer_color .template-archives .tabcontainer a,
#top .footer_color .tabcontainer .tab:hover,
.footer_color .cart_dropdown .dropdown_widget li a,
#top .footer_color .avia_cart_buttons .button,
#top .footer_color .dropdown_widget .buttons .button,
.footer_color .cart_dropdown_first .cart_dropdown_link,
#top .footer_color .price,
.footer_color .stock,
#top #wrap_all .footer_color ins,
.footer_color .sort-param .current-param a {
  color: #aaa;
}

.footer_color .primary-background,
.footer_color .primary-background a,
div .footer_color .button,
.footer_color #submit,
.footer_color input[type="submit"],
.footer_color .small-preview:hover,
.footer_color .avia-menu-fx,
.footer_color .avia-menu-fx .avia-arrow,
.footer_color.iconbox_top .iconbox_icon,
.footer_color .avia-data-table th.avia-highlight-col,
.footer_color .avia-color-theme-color,
.footer_color .avia-color-theme-color:hover,
.footer_color .image-overlay .image-overlay-inside:before,
.footer_color .comment-count,
.footer_color .av_dropcap2,
.footer_color .av-masonry-entry:hover .av-icon-display {
  background-color: #aaa;
  color: #fff;
  border-color: #888;
}

.footer_color table,
.footer_color .widget_nav_menu ul:first-child > .current-menu-item,
.footer_color .widget_nav_menu ul:first-child > .current_page_item,
.footer_color .widget_nav_menu ul:first-child > .current-menu-ancestor,
.footer_color .pagination .current,
.footer_color .pagination a,
.footer_color.iconbox_top .iconbox_content,
.footer_color .av_promobox,
.footer_color .toggle_content,
.footer_color .toggler:hover,
.footer_color .related_posts_default_image,
.footer_color .search-result-counter,
.footer_color .container_wrap_meta,
.footer_color .avia-content-slider .slide-image,
.footer_color .avia-slider-testimonials .avia-testimonial-content,
.footer_color .avia-testimonial-arrow-wrap .avia-arrow,
.footer_color .news-thumb,
.footer_color .portfolio-preview-content,
.footer_color .portfolio-preview-content .avia-arrow,
.footer_color tr:nth-child(odd),
.footer_color .pricing-table > li:nth-child(odd),
.footer_color .pricing-extra {
  background: #222;
}

.footer_color .header_bg,
.footer_color .main_menu ul,
.footer_color .main_menu .menu ul li a,
.footer_color .pointer_arrow_wrap .pointer_arrow,
.footer_color .avia_mega_div,
.footer_color .avia-tt,
.footer_color .avia-tt .avia-arrow,
.footer_color .avia-tt .avia-arrow {
  background-color: #333;
  color: #919191;
}

.footer_color .main_menu .menu ul li a:hover,
#top .footer_color .ajax_search_entry:hover,
.footer_color .progress,
.footer_color .bbp-topics .bbp-header,
.footer_color .bbp-topics .bbp-header,
.footer_color .bbp-forums .bbp-header,
.footer_color .bbp-topics-front ul.super-sticky,
.footer_color .bbp-topics ul.super-sticky,
.footer_color .bbp-topics ul.sticky,
.footer_color .bbp-forum-content ul.sticky,
.footer_color .bbp-body .page-numbers,
.footer_color .widget_display_stats dt,
.footer_color .widget_display_stats dd,
.footer_color .woocommerce-tabs .tabs .active,
.footer_color div.product .woocommerce-tabs .panel,
.footer_color .activeslideThumb,
.footer_color #payment li,
.footer_color .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.footer_color .avia_cart,
.footer_color form.login,
.footer_color form.register,
.footer_color .col-1,
.footer_color .col-2,
.footer_color .variations_form,
.footer_color .dynamic-title,
.footer_color .single-product-main-image .thumbnails a,
.footer_color .quantity input.qty,
.footer_color .avia_cart_buttons,
#top .footer_color .dropdown_widget .buttons,
div .dropdown_widget .cart_list li:hover,
.footer_color .woocommerce-info,
.footer_color #payment,
.footer_color .sort-param > li > span,
.footer_color .sort-param > li > a,
.footer_color .sort-param ul {
  background-color: #222;
}

.footer_color .sub_menu li ul a,
.footer_color #payment,
.footer_color .sub_menu ul li,
.footer_color .sub_menu ul,
#top .footer_color .sub_menu li li a:hover,
#top .footer_color .invers-color .input-text,
#top .footer_color .invers-color input[type="text"],
#top .footer_color .invers-color input[type="input"],
#top .footer_color .invers-color input[type="password"],
#top .footer_color .invers-color input[type="email"],
#top .footer_color .invers-color input[type="number"],
#top .footer_color .invers-color input[type="url"],
#top .footer_color .invers-color input[type="tel"],
#top .footer_color .invers-color input[type="search"],
#top .footer_color .invers-color textarea,
#top .footer_color .invers-color select,
.footer_color .av-masonry-pagination,
.footer_color .av-masonry-pagination:hover,
.footer_color .hr-short .hr-inner-style,
.footer_color .hr-short .hr-inner,
.footer_color div.product .woocommerce-tabs ul.tabs li.active a,
.footer_color .cart_dropdown .dropdown_widget .widget_shopping_cart_content,
.footer_color .cart_dropdown_link,
.footer_color .inner_product_header,
.footer_color .avia-arrow,
#top .footer_color .variations select,
#top .footer_color .variations input,
#top .footer_color #reviews input[type="text"],
.footer_color #reviews .comment-text,
.footer_color #reviews #comment,
.footer_color .single-product-main-image .images a,
#top .footer_color .shop_table.cart .input-text,
#top .footer_color form.login .input-text,
#top .footer_color form.register .input-text {
  background-color: #333;
}

.footer_color .header_bg,
.footer_color {
  background: #333;
}

.footer_color .ajax_load,
#top .footer_color .sidebar_active_tab .sidebar_tab_icon,
.footer_color .widget_layered_nav ul li.chosen,
.footer_color
  .widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
#top .footer_color a.remove,
#top .footer_color .onsale {
  background-color: #aaa;
}

.footer_color .avia-color-theme-color-subtle,
div .footer_color .tabcontainer .active_tab_content,
div .footer_color .tabcontainer .active_tab {
  background-color: #222;
  color: #aaa;
}

#top .footer_color .input-text,
#top .footer_color input[type="text"],
#top .footer_color input[type="input"],
#top .footer_color input[type="password"],
#top .footer_color input[type="email"],
#top .footer_color input[type="number"],
#top .footer_color input[type="url"],
#top .footer_color input[type="tel"],
#top .footer_color input[type="search"],
#top .footer_color textarea,
#top .footer_color select,
#top .footer_color .chzn-container-single .chzn-single {
  background-color: #222;
  color: #919191;
  border-color: #555;
}

.alternate_color,
.alternate_color div,
.alternate_color header,
.alternate_color main,
.alternate_color aside,
.alternate_color footer,
.alternate_color article,
.alternate_color nav,
.alternate_color section,
.alternate_color span,
.alternate_color applet,
.alternate_color object,
.alternate_color iframe,
.alternate_color h1,
.alternate_color h2,
.alternate_color h3,
.alternate_color h4,
.alternate_color h5,
.alternate_color h6,
.alternate_color p,
.alternate_color blockquote,
.alternate_color pre,
.alternate_color a,
.alternate_color abbr,
.alternate_color acronym,
.alternate_color address,
.alternate_color big,
.alternate_color cite,
.alternate_color code,
.alternate_color del,
.alternate_color dfn,
.alternate_color em,
.alternate_color img,
.alternate_color ins,
.alternate_color kbd,
.alternate_color q,
.alternate_color s,
.alternate_color samp,
.alternate_color small,
.alternate_color strike,
.alternate_color strong,
.alternate_color sub,
.alternate_color sup,
.alternate_color tt,
.alternate_color var,
.alternate_color b,
.alternate_color u,
.alternate_color i,
.alternate_color center,
.alternate_color dl,
.alternate_color dt,
.alternate_color dd,
.alternate_color ol,
.alternate_color ul,
.alternate_color li,
.alternate_color fieldset,
.alternate_color form,
.alternate_color label,
.alternate_color legend,
.alternate_color table,
.alternate_color caption,
.alternate_color tbody,
.alternate_color tfoot,
.alternate_color thead,
.alternate_color tr,
.alternate_color th,
.alternate_color td,
.alternate_color article,
.alternate_color aside,
.alternate_color canvas,
.alternate_color details,
.alternate_color embed,
.alternate_color figure,
.alternate_color fieldset,
.alternate_color figcaption,
.alternate_color footer,
.alternate_color header,
.alternate_color hgroup,
.alternate_color menu,
.alternate_color nav,
.alternate_color output,
.alternate_color ruby,
.alternate_color section,
.alternate_color summary,
.alternate_color time,
.alternate_color mark,
.alternate_color audio,
.alternate_color video,
#top .alternate_color .pullquote_boxed,
.responsive #top .alternate_color .avia-testimonial,
.responsive #top.avia-blank #main .alternate_color.container_wrap:first-child,
#top .alternate_color.fullsize .template-blog .post_delimiter,
.alternate_color#header .avia_mega_div > .sub-menu.avia_mega_hr,
.alternate_color .avia-icon-list .iconlist-timeline,
.alternate_color .pricing-table.avia-desc-col .avia-heading-row,
.alternate_color .pricing-table.avia-desc-col .avia-pricing-row,
.main_color,
.main_color div,
.main_color header,
.main_color main,
.main_color aside,
.main_color footer,
.main_color article,
.main_color nav,
.main_color section,
.main_color span,
.main_color applet,
.main_color object,
.main_color iframe,
.main_color h1,
.main_color h2,
.main_color h3,
.main_color h4,
.main_color h5,
.main_color h6,
.main_color p,
.main_color blockquote,
.main_color pre,
.main_color a,
.main_color abbr,
.main_color acronym,
.main_color address,
.main_color big,
.main_color cite,
.main_color code,
.main_color del,
.main_color dfn,
.main_color em,
.main_color img,
.main_color ins,
.main_color kbd,
.main_color q,
.main_color s,
.main_color samp,
.main_color small,
.main_color strike,
.main_color strong,
.main_color sub,
.main_color sup,
.main_color tt,
.main_color var,
.main_color b,
.main_color u,
.main_color i,
.main_color center,
.main_color dl,
.main_color dt,
.main_color dd,
.main_color ol,
.main_color ul,
.main_color li,
.main_color fieldset,
.main_color form,
.main_color label,
.main_color legend,
.main_color table,
.main_color caption,
.main_color tbody,
.main_color tfoot,
.main_color thead,
.main_color tr,
.main_color th,
.main_color td,
.main_color article,
.main_color aside,
.main_color canvas,
.main_color details,
.main_color embed,
.main_color figure,
.main_color fieldset,
.main_color figcaption,
.main_color footer,
.main_color header,
.main_color hgroup,
.main_color menu,
.main_color nav,
.main_color output,
.main_color ruby,
.main_color section,
.main_color summary,
.main_color time,
.main_color mark,
.main_color audio,
.main_color video,
#top .main_color .pullquote_boxed,
.responsive #top .main_color .avia-testimonial,
.responsive #top.avia-blank #main .main_color.container_wrap:first-child,
#top .main_color.fullsize .template-blog .post_delimiter,
.main_color#header .avia_mega_div > .sub-menu.avia_mega_hr,
.main_color .avia-icon-list .iconlist-timeline,
.main_color .pricing-table.avia-desc-col .avia-heading-row,
.main_color .pricing-table.avia-desc-col .avia-pricing-row,
.header_color,
.header_color div,
.header_color header,
.header_color main,
.header_color aside,
.header_color footer,
.header_color article,
.header_color nav,
.header_color section,
.header_color span,
.header_color applet,
.header_color object,
.header_color iframe,
.header_color h1,
.header_color h2,
.header_color h3,
.header_color h4,
.header_color h5,
.header_color h6,
.header_color p,
.header_color blockquote,
.header_color pre,
.header_color a,
.header_color abbr,
.header_color acronym,
.header_color address,
.header_color big,
.header_color cite,
.header_color code,
.header_color del,
.header_color dfn,
.header_color em,
.header_color img,
.header_color ins,
.header_color kbd,
.header_color q,
.header_color s,
.header_color samp,
.header_color small,
.header_color strike,
.header_color strong,
.header_color sub,
.header_color sup,
.header_color tt,
.header_color var,
.header_color b,
.header_color u,
.header_color i,
.header_color center,
.header_color dl,
.header_color dt,
.header_color dd,
.header_color ol,
.header_color ul,
.header_color li,
.header_color fieldset,
.header_color form,
.header_color label,
.header_color legend,
.header_color table,
.header_color caption,
.header_color tbody,
.header_color tfoot,
.header_color thead,
.header_color tr,
.header_color th,
.header_color td,
.header_color article,
.header_color aside,
.header_color canvas,
.header_color details,
.header_color embed,
.header_color figure,
.header_color fieldset,
.header_color figcaption,
.header_color footer,
.header_color header,
.header_color hgroup,
.header_color menu,
.header_color nav,
.header_color output,
.header_color ruby,
.header_color section,
.header_color summary,
.header_color time,
.header_color mark,
.header_color audio,
.header_color video,
#top .header_color .pullquote_boxed,
.responsive #top .header_color .avia-testimonial,
.responsive #top.avia-blank #main .header_color.container_wrap:first-child,
#top .header_color.fullsize .template-blog .post_delimiter,
.header_color#header .avia_mega_div > .sub-menu.avia_mega_hr,
.header_color .avia-icon-list .iconlist-timeline,
.header_color .pricing-table.avia-desc-col .avia-heading-row,
.header_color .pricing-table.avia-desc-col .avia-pricing-row,
.alternate_color .thumbnail_container img,
#top .alternate_color #main .order_details,
.main_color .thumbnail_container img,
#top .main_color #main .order_details,
.header_color .thumbnail_container img,
#top .header_color #main .order_details {
  border-color: #e1e1e1;
}

.alternate_color,
.alternate_color .site-background,
.alternate_color .first-quote,
.alternate_color .related_image_wrap,
.alternate_color .gravatar img .alternate_color .hr_content,
.alternate_color .news-thumb,
.alternate_color .post-format-icon,
.alternate_color .ajax_controlls a,
.alternate_color .tweet-text.avatar_no,
.alternate_color .big-preview,
.alternate_color .toggler,
.alternate_color .toggler.activeTitle:hover,
.alternate_color #js_sort_items,
.alternate_color.inner-entry,
.alternate_color .grid-entry-title,
.alternate_color .related-format-icon,
.grid-entry .alternate_color .avia-arrow,
.alternate_color .avia-gallery-big,
.alternate_color .avia-gallery-big,
.alternate_color .avia-gallery img,
.alternate_color .grid-content,
.alternate_color tr:nth-child(even),
.alternate_color .avia-data-table .avia-heading-row .avia-desc-col,
.alternate_color .avia-data-table .avia-highlight-col,
.alternate_color .pricing-table > li:nth-child(even),
body .alternate_color .pricing-table.avia-desc-col li {
  background-color: #fff;
  color: #888;
}

.alternate_color .heading-color,
.alternate_color a.iconbox_icon:hover,
.alternate_color h1,
.alternate_color h2,
.alternate_color h3,
.alternate_color h4,
.alternate_color h5,
.alternate_color h6,
.alternate_color .sidebar .current_page_item > a,
.alternate_color .sidebar .current-menu-item > a,
.alternate_color .pagination .current,
.alternate_color .pagination a:hover,
.alternate_color strong.avia-testimonial-name,
.alternate_color .heading,
.alternate_color .toggle_content strong,
.alternate_color .toggle_content strong a,
.alternate_color .tab_content strong,
.alternate_color .tab_content strong a,
.alternate_color .asc_count,
.alternate_color .avia-testimonial-content strong,
.alternate_color div .news-headline,
.alternate_color .ajax_search_title,
.alternate_color .bbp-author-name,
.alternate_color .sort-param > li:hover > span,
.alternate_color .sort-param > li:hover > a,
.alternate_color .sort-param > li:hover ul,
.alternate_color .product-sorting strong {
  color: #444;
}

.alternate_color .meta-color,
.alternate_color .sidebar,
.alternate_color .sidebar a,
.alternate_color .minor-meta,
.alternate_color .minor-meta a,
.alternate_color .text-sep,
.alternate_color blockquote,
.alternate_color .post_nav a,
.alternate_color .comment-text,
.alternate_color .side-container-inner,
.alternate_color .news-time,
.alternate_color .pagination a,
.alternate_color .pagination span,
.alternate_color .tweet-text.avatar_no .tweet-time,
#top .alternate_color .extra-mini-title,
.alternate_color .team-member-job-title,
.alternate_color .team-social a,
.alternate_color #js_sort_items a,
.grid-entry-excerpt,
.alternate_color .avia-testimonial-subtitle,
.alternate_color .commentmetadata a,
.alternate_color .social_bookmarks a,
.alternate_color .meta-heading > *,
.alternate_color .slide-meta,
.alternate_color .slide-meta a,
.alternate_color .taglist,
.alternate_color .taglist a,
.alternate_color .phone-info,
.alternate_color .phone-info a,
.alternate_color .av-sort-by-term a,
.alternate_color .sub_menu > ul > li > a,
.alternate_color .sub_menu > div > ul > li > a,
.alternate_color .main_menu ul:first-child > li > a,
#top .alternate_color .main_menu .menu ul .current_page_item > a,
#top .alternate_color .main_menu .menu ul .current-menu-item > a,
#top .alternate_color .sub_menu li ul a,
.alternate_color .ajax_search_excerpt,
.alternate_color .iconlist_content,
.alternate_color .sidebar_tab,
.alternate_color .tabcontainer .tab,
.alternate_color table caption,
.alternate_color tr:nth-child(even),
.alternate_color .pricing-table > li:nth-child(even),
.alternate_color .bbp-meta,
.alternate_color .bbp-author-role,
.alternate_color .bbp-author-ip,
.alternate_color .bbp-pagination-count,
.alternate_color .bbp-topics .bbp-body .bbp-topic-title:before,
.alternate_color .woocommerce-tabs .tabs a,
.alternate_color .product_meta,
.alternate_color .quantity input.qty,
.alternate_color .cart_dropdown .dropdown_widget,
.alternate_color .avia_select_fake_val,
.alternate_color address,
.alternate_color .product > a .alternate_color .product_excerpt,
.alternate_color .term_description,
#top .alternate_color .price .from,
#top #wrap_all .alternate_color del,
.alternate_color .dynamic-title .dynamic-heading,
.alternate_color .dynamic-title a,
.alternate_color .sort-param a {
  color: #a2a2a2;
}

.alternate_color .meta-heading .special-heading-inner-border,
.alternate_color .avia-bullet {
  border-color: #a2a2a2;
}

.alternate_color a,
.alternate_color .widget_first,
.alternate_color strong,
.alternate_color b,
.alternate_color b a,
.alternate_color strong a,
.alternate_color #js_sort_items a:hover,
.alternate_color #js_sort_items a.active_sort,
.alternate_color .av-sort-by-term a.active_sort,
.alternate_color,
.alternate_color .taglist a.activeFilter,
.alternate_color #commentform .required,
#top .alternate_color .av-no-color.av-icon-style-border a.av-icon-char,
#top .alternate_color .av-masonry-entry.format-quote:hover .av-icon-display,
#top .alternate_color .main_menu .menu .avia_mega_div ul .current-menu-item > a,
.alternate_color .required,
.main_color a,
.main_color .widget_first,
.main_color strong,
.main_color b,
.main_color b a,
.main_color strong a,
.main_color #js_sort_items a:hover,
.main_color #js_sort_items a.active_sort,
.main_color .av-sort-by-term a.active_sort,
.main_color .special_amp,
.main_color .taglist a.activeFilter,
.main_color #commentform .required,
#top .main_color .av-no-color.av-icon-style-border a.av-icon-char,
#top .main_color .av-masonry-entry.format-quote:hover .av-icon-display,
#top .main_color .main_menu .menu .avia_mega_div ul .current-menu-item > a,
.main_color .required,
.avia-datepicker-div a.ui-state-active,
.avia-datepicker-div a.ui-state-highlight,
.header_color a,
.header_color .widget_first,
.header_color strong,
.header_color b,
.header_color b a,
.header_color strong a,
.header_color #js_sort_items a:hover,
.header_color #js_sort_items a.active_sort,
.header_color .av-sort-by-term a.active_sort,
.header_color .special_amp,
.header_color .taglist a.activeFilter,
.header_color #commentform .required,
#top .header_color .av-no-color.av-icon-style-border a.av-icon-char,
#top .header_color .av-masonry-entry.format-quote:hover .av-icon-display,
.main_menu .menu .avia_mega_div ul .current-menu-item > a,
.header_color .required,
#mobile-advanced li > a:before,
#top .alternate_color .price,
.alternate_color .stock,
#top #wrap_all .alternate_color ins,
.alternate_color .sort-param .current-param a,
#top .main_color .price,
.main_color .stock,
#top #wrap_all .main_color ins,
.main_color .sort-param .current-param a,
#top .header_color .price,
.header_color .stock,
#top #wrap_all .header_color ins,
.header_color .sort-param .current-param a {
  color: #555;
}

.alternate_color a:hover,
.alternate_color h1 a:hover,
.alternate_color h2 a:hover,
.alternate_color h3 a:hover,
.alternate_color h4 a:hover,
.alternate_color h5 a:hover,
.alternate_color h6 a:hover,
.alternate_color .template-search a.news-content:hover,
.alternate_color .template-archives .tabcontainer a:hover,
.main_color a:hover,
.main_color h1 a:hover,
.main_color h2 a:hover,
.main_color h3 a:hover,
.main_color h4 a:hover,
.main_color h5 a:hover,
.main_color h6 a:hover,
.main_color .template-search a.news-content:hover,
.main_color .template-archives .tabcontainer a:hover,
.header_color a:hover,
.header_color h1 a:hover,
.header_color h2 a:hover,
.header_color h3 a:hover,
.header_color h4 a:hover,
.header_color h5 a:hover,
.header_color h6 a:hover,
.header_color .template-search a.news-content:hover,
.header_color .template-archives .tabcontainer a:hover,
.alternate_color .dynamic-title a:hover,
#top .alternate_color .sort-param a:hover,
.main_color .dynamic-title a:hover,
#top .main_color .sort-param a:hover,
.header_color .dynamic-title a:hover,
#top .header_color .sort-param a:hover {
  color: #55adbd;
}

.alternate_color .primary-background,
.alternate_color .primary-background a,
div .alternate_color .button,
.alternate_color #submit,
.alternate_color input[type="submit"],
.alternate_color .small-preview:hover,
.alternate_color .avia-menu-fx,
.alternate_color .avia-menu-fx .avia-arrow,
.alternate_color.iconbox_top .iconbox_icon,
.alternate_color .avia-data-table th.avia-highlight-col,
.alternate_color .avia-color-theme-color,
.alternate_color .avia-color-theme-color:hover,
.alternate_color .image-overlay .image-overlay-inside:before,
.alternate_color .comment-count,
.alternate_color .av_dropcap2,
.alternate_color .av-masonry-entry:hover .av-icon-display,
.main_color .primary-background,
.main_color .primary-background a,
div .main_color .button,
.main_color #submit,
.main_color input[type="submit"],
.main_color .small-preview:hover,
.main_color .avia-menu-fx,
.main_color .avia-menu-fx .avia-arrow,
.main_color.iconbox_top .iconbox_icon,
.main_color .avia-data-table th.avia-highlight-col,
.main_color .avia-color-theme-color,
.main_color .avia-color-theme-color:hover,
.main_color .image-overlay .image-overlay-inside:before,
.main_color .comment-count,
.main_color .av_dropcap2,
.main_color .av-masonry-entry:hover .av-icon-display,
.header_color .primary-background,
.header_color .primary-background a,
div .header_color .button,
.header_color #submit,
.header_color input[type="submit"],
.header_color .small-preview:hover,
.header_color .avia-menu-fx,
.header_color .avia-menu-fx .avia-arrow,
.header_color.iconbox_top .iconbox_icon,
.header_color .avia-data-table th.avia-highlight-col,
.header_color .avia-color-theme-color,
.header_color .avia-color-theme-color:hover,
.header_color .image-overlay .image-overlay-inside:before,
.header_color .comment-count,
.header_color .av_dropcap2,
.header_color .av-masonry-entry:hover .av-icon-display {
  background-color: #2997ab;
  color: #fff;
  border-color: #077589;
}

.alternate_color .button:hover,
.alternate_color .ajax_controlls a:hover,
.alternate_color #submit:hover,
.alternate_color .big_button:hover,
.alternate_color .contentSlideControlls a:hover,
.alternate_color #submit:hover,
.alternate_color input[type="submit"]:hover,
.header_color .button:hover,
.header_color .ajax_controlls a:hover,
.header_color #submit:hover,
.header_color .big_button:hover,
.header_color .contentSlideControlls a:hover,
.header_color #submit:hover,
.header_color input[type="submit"]:hover {
  background-color: #55adbd;
  color: #fff;
  border-color: #338b9b;
}

.alternate_color .timeline-bullet,
.header_color .timeline-bullet {
  background-color: #e1e1e1;
  border-color: #fff;
}

.alternate_color table,
.alternate_color .widget_nav_menu ul:first-child > .current-menu-item,
.alternate_color .widget_nav_menu ul:first-child > .current_page_item,
.alternate_color .widget_nav_menu ul:first-child > .current-menu-ancestor,
.alternate_color .pagination .current,
.alternate_color .pagination a,
.alternate_color.iconbox_top .iconbox_content,
.alternate_color .av_promobox,
.alternate_color .toggle_content,
.alternate_color .toggler:hover,
.alternate_color .related_posts_default_image,
.alternate_color .search-result-counter,
.alternate_color .container_wrap_meta,
.alternate_color .avia-content-slider .slide-image,
.alternate_color .avia-slider-testimonials .avia-testimonial-content,
.alternate_color .avia-testimonial-arrow-wrap .avia-arrow,
.alternate_color .news-thumb,
.alternate_color .portfolio-preview-content,
.alternate_color .portfolio-preview-content .avia-arrow,
.alternate_color tr:nth-child(odd),
.alternate_color .pricing-table > li:nth-child(odd),
.alternate_color .pricing-extra,
.main_color .bbp-reply-content,
.main_color .bbp-topic-content,
.main_color .bbp-body .super-sticky .page-numbers,
.main_color .bbp-body .sticky .page-numbers {
  background: #f8f8f8;
}

#top .alternate_color .post_timeline li:hover .timeline-bullet,
.alternate_color .sidebar_tab:hover .sidebar_tab_icon,
#top .main_color .post_timeline li:hover .timeline-bullet,
.main_color .sidebar_tab:hover .sidebar_tab_icon,
#top .header_color .post_timeline li:hover .timeline-bullet,
.header_color .sidebar_tab:hover .sidebar_tab_icon {
  background-color: #55adbd;
}

.alternate_color blockquote,
.alternate_color .avia-bullet,
.alternate_color .av-no-color.av-icon-style-border a.av-icon-char,
.main_color blockquote,
.main_color .avia-bullet,
.main_color .av-no-color.av-icon-style-border a.av-icon-char,
.header_color blockquote,
.header_color .avia-bullet,
.header_color .av-no-color.av-icon-style-border a.av-icon-char,
.alternate_color .sort-param .current-param .avia-bullet,
.main_color .sort-param .current-param .avia-bullet,
.header_color .sort-param .current-param .avia-bullet {
  border-color: #2997ab;
}

.alternate_color .main_menu ul:first-child > li > ul,
#top .alternate_color .avia_mega_div > .sub-menu,
.main_color .main_menu ul:first-child > li > ul,
#top .main_color .avia_mega_div > .sub-menu,
.main_menu ul:first-child > li > ul,
#top .header_color .avia_mega_div > .sub-menu {
  border-top-color: #2997ab;
}

.alternate_color .header_bg,
.alternate_color .main_menu ul,
.alternate_color .main_menu .menu ul li a,
.alternate_color .pointer_arrow_wrap .pointer_arrow,
.alternate_color .avia_mega_div,
.alternate_color .avia-tt,
.alternate_color .avia-tt .avia-arrow,
.alternate_color .avia-tt .avia-arrow {
  background-color: #fff;
  color: #a2a2a2;
}

.alternate_color .main_menu .menu ul li a:hover,
#top .alternate_color .ajax_search_entry:hover,
.alternate_color .progress,
.alternate_color .bbp-topics .bbp-header,
.alternate_color .bbp-topics .bbp-header,
.alternate_color .bbp-forums .bbp-header,
.alternate_color .bbp-topics-front ul.super-sticky,
.alternate_color .bbp-topics ul.super-sticky,
.alternate_color .bbp-topics ul.sticky,
.alternate_color .bbp-forum-content ul.sticky,
.alternate_color .bbp-body .page-numbers,
.alternate_color .widget_display_stats dt,
.alternate_color .widget_display_stats dd,
.main_color .sub_menu li ul a,
.main_color #payment,
.main_color .sub_menu ul li,
.main_color .sub_menu ul,
#top .main_color .sub_menu li li a:hover,
#top .main_color .invers-color .input-text,
#top .main_color .invers-color input[type="text"],
#top .main_color .invers-color input[type="input"],
#top .main_color .invers-color input[type="password"],
#top .main_color .invers-color input[type="email"],
#top .main_color .invers-color input[type="number"],
#top .main_color .invers-color input[type="url"],
#top .main_color .invers-color input[type="tel"],
#top .main_color .invers-color input[type="search"],
#top .main_color .invers-color textarea,
#top .main_color .invers-color select,
.main_color .av-masonry-pagination,
.main_color .av-masonry-pagination:hover,
.main_color .hr-short .hr-inner-style,
.main_color .hr-short .hr-inner,
.alternate_color .woocommerce-tabs .tabs .active,
.alternate_color div.product .woocommerce-tabs .panel,
.alternate_color .activeslideThumb,
.alternate_color #payment li,
.alternate_color .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.alternate_color .avia_cart,
.alternate_color form.login,
.alternate_color form.register,
.alternate_color .col-1,
.alternate_color .col-2,
.alternate_color .variations_form,
.alternate_color .dynamic-title,
.alternate_color .single-product-main-image .thumbnails a,
.alternate_color .quantity input.qty,
.alternate_color .avia_cart_buttons,
#top .alternate_color .dropdown_widget .buttons,
div .dropdown_widget .cart_list li:hover,
.alternate_color .woocommerce-info,
.alternate_color #payment,
.alternate_color .sort-param > li > span,
.alternate_color .sort-param > li > a,
.alternate_color .sort-param ul,
.main_color div.product .woocommerce-tabs ul.tabs li.active a,
.main_color .cart_dropdown .dropdown_widget .widget_shopping_cart_content,
.main_color .cart_dropdown_link,
.main_color .inner_product_header,
.main_color .avia-arrow,
#top .main_color .variations select,
#top .main_color .variations input,
#top .main_color #reviews input[type="text"],
.main_color #reviews .comment-text,
.main_color #reviews #comment,
.main_color .single-product-main-image .images a,
#top .main_color .shop_table.cart .input-text,
#top .main_color form.login .input-text,
#top .main_color form.register .input-text {
  background-color: #f8f8f8;
}

.alternate_color .ajax_search_image,
#mobile-advanced li > a:hover,
.header_color .ajax_search_image {
  background-color: #2997ab;
  color: #fff;
}

.alternate_color .ajax_load,
#top .alternate_color .sidebar_active_tab .sidebar_tab_icon,
.main_color .ajax_load,
#top .main_color .sidebar_active_tab .sidebar_tab_icon,
.header_color .ajax_load,
#top .header_color .sidebar_active_tab .sidebar_tab_icon,
.alternate_color .widget_layered_nav ul li.chosen,
.alternate_color
  .widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
#top .alternate_color a.remove,
#top .alternate_color .onsale,
.main_color .widget_layered_nav ul li.chosen,
.main_color
  .widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
#top .main_color a.remove,
#top .main_color .onsale,
.header_color .widget_layered_nav ul li.chosen,
.header_color
  .widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
#top .header_color a.remove,
#top .header_color .onsale {
  background-color: #2997ab;
}

#top .alternate_color .avia-color-theme-color,
#top .main_color .avia-color-theme-color,
#top .header_color .avia-color-theme-color {
  color: #fff;
  border-color: #077589;
}

.alternate_color .avia-color-theme-color-subtle,
div .alternate_color .tabcontainer .active_tab_content,
div .alternate_color .tabcontainer .active_tab {
  background-color: #f8f8f8;
  color: #888;
}

.alternate_color .avia-icon-list .iconlist_icon,
.main_color .avia-icon-list .iconlist_icon,
.header_color .avia-icon-list .iconlist_icon {
  background-color: #d0d0d0;
}

#top .alternate_color .input-text,
#top .alternate_color input[type="text"],
#top .alternate_color input[type="input"],
#top .alternate_color input[type="password"],
#top .alternate_color input[type="email"],
#top .alternate_color input[type="number"],
#top .alternate_color input[type="url"],
#top .alternate_color input[type="tel"],
#top .alternate_color input[type="search"],
#top .alternate_color textarea,
#top .alternate_color select,
#top .alternate_color .chzn-container-single .chzn-single {
  background-color: #f8f8f8;
  color: #a2a2a2;
  border-color: #e1e1e1;
}

.alternate_color .sidebar_tab_icon,
.main_color .sidebar_tab_icon,
.header_color .sidebar_tab_icon {
  background-color: #e1e1e1;
}

.alternate_color .pricing-table li.avia-pricing-row,
.alternate_color .pricing-table li.avia-heading-row,
.alternate_color .pricing-table li.avia-pricing-row .pricing-extra,
.main_color .pricing-table li.avia-pricing-row,
.main_color .pricing-table li.avia-heading-row,
.main_color .pricing-table li.avia-pricing-row .pricing-extra,
.header_color .pricing-table li.avia-pricing-row,
.header_color .pricing-table li.avia-heading-row,
.header_color .pricing-table li.avia-pricing-row .pricing-extra {
  background-color: #2997ab;
  color: #fff;
  border-color: #4bb9cd;
}

.alternate_color .pricing-table li.avia-heading-row,
.alternate_color .pricing-table li.avia-heading-row .pricing-extra,
.main_color .pricing-table li.avia-heading-row,
.main_color .pricing-table li.avia-heading-row .pricing-extra,
.header_color .pricing-table li.avia-heading-row,
.header_color .pricing-table li.avia-heading-row .pricing-extra {
  background-color: #3aa8bc;
  color: #fff;
  border-color: #4bb9cd;
}

.alternate_color .mejs-controls .mejs-time-rail .mejs-time-current,
.alternate_color
  .mejs-controls
  .mejs-volume-button
  .mejs-volume-slider
  .mejs-volume-current,
.alternate_color
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.alternate_color .theme-color-bar .bar,
.main_color .mejs-controls .mejs-time-rail .mejs-time-current,
.main_color
  .mejs-controls
  .mejs-volume-button
  .mejs-volume-slider
  .mejs-volume-current,
.main_color
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.main_color .theme-color-bar .bar,
.header_color .mejs-controls .mejs-time-rail .mejs-time-current,
.header_color
  .mejs-controls
  .mejs-volume-button
  .mejs-volume-slider
  .mejs-volume-current,
.header_color
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.header_color .theme-color-bar .bar {
  background: #2997ab;
  background-image: linear-gradient(
    -45deg,
    #2997ab,
    #4bb9cd,
    #4bb9cd,
    #2997ab,
    #2997ab,
    #4bb9cd,
    #4bb9cd
  );
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  -webkit-background-size: 6px 5px;
}

.alternate_color .mejs-controls .mejs-time-rail .mejs-time-float,
.main_color .mejs-controls .mejs-time-rail .mejs-time-float,
.header_color .mejs-controls .mejs-time-rail .mejs-time-float {
  background: linear-gradient(#4bb9cd, #2997ab);
  color: #fff;
}

.alternate_color .mejs-controls .mejs-time-rail .mejs-time-float-corner,
.main_color .mejs-controls .mejs-time-rail .mejs-time-float-corner,
.header_color .mejs-controls .mejs-time-rail .mejs-time-float-corner {
  border: solid 4px #2997ab;
  border-color: #2997ab transparent transparent;
}

.alternate_color .bbp-reply-content,
.alternate_color .bbp-topic-content,
.alternate_color .bbp-body .super-sticky .page-numbers,
.alternate_color .bbp-body .sticky .page-numbers,
.main_color table,
.main_color .widget_nav_menu ul:first-child > .current-menu-item,
.main_color .widget_nav_menu ul:first-child > .current_page_item,
.main_color .widget_nav_menu ul:first-child > .current-menu-ancestor,
.main_color .pagination .current,
.main_color .pagination a,
.main_color.iconbox_top .iconbox_content,
.main_color .av_promobox,
.main_color .toggle_content,
.main_color .toggler:hover,
.main_color .related_posts_default_image,
.main_color .search-result-counter,
.main_color .container_wrap_meta,
.main_color .avia-content-slider .slide-image,
.main_color .avia-slider-testimonials .avia-testimonial-content,
.main_color .avia-testimonial-arrow-wrap .avia-arrow,
.main_color .news-thumb,
.main_color .portfolio-preview-content,
.main_color .portfolio-preview-content .avia-arrow,
.main_color tr:nth-child(odd),
.main_color .pricing-table > li:nth-child(odd),
.main_color .pricing-extra,
.header_color .bbp-reply-content,
.header_color .bbp-topic-content,
.header_color .bbp-body .super-sticky .page-numbers,
.header_color .bbp-body .sticky .page-numbers {
  background: #fff;
}

.alternate_color .bbp-admin-links,
.main_color .bbp-admin-links,
.header_color .bbp-admin-links {
  color: #e1e1e1;
}

.avia_transform .alternate_color .bbp-replies .bbp-reply-author:before,
.avia_transform .forum-search .alternate_color .bbp-reply-author:before,
.avia_transform .forum-search .alternate_color .bbp-topic-author:before,
.avia_transform .header_color .bbp-replies .bbp-reply-author:before,
.avia_transform .forum-search .header_color .bbp-reply-author:before,
.avia_transform .forum-search .header_color .bbp-topic-author:before {
  background-color: #fff;
  border-color: #e1e1e1;
}

.main_color,
.main_color .site-background,
.main_color .first-quote,
.main_color .related_image_wrap,
.main_color .gravatar img .main_color .hr_content,
.main_color .news-thumb,
.main_color .post-format-icon,
.main_color .ajax_controlls a,
.main_color .tweet-text.avatar_no,
.main_color .big-preview,
.main_color .toggler,
.main_color .toggler.activeTitle:hover,
.main_color #js_sort_items,
.main_color.inner-entry,
.main_color .grid-entry-title,
.main_color .related-format-icon,
.grid-entry .main_color .avia-arrow,
.main_color .avia-gallery-big,
.main_color .avia-gallery-big,
.main_color .avia-gallery img,
.main_color .grid-content,
.main_color tr:nth-child(even),
.main_color .avia-data-table .avia-heading-row .avia-desc-col,
.main_color .avia-data-table .avia-highlight-col,
.main_color .pricing-table > li:nth-child(even),
body .main_color .pricing-table.avia-desc-col li {
  background-color: #f8f8f8;
  color: #444;
}

.main_color .heading-color,
.main_color a.iconbox_icon:hover,
.main_color h1,
.main_color h2,
.main_color h3,
.main_color h4,
.main_color h5,
.main_color h6,
.main_color .sidebar .current_page_item > a,
.main_color .sidebar .current-menu-item > a,
.main_color .pagination .current,
.main_color .pagination a:hover,
.main_color strong.avia-testimonial-name,
.main_color .heading,
.main_color .toggle_content strong,
.main_color .toggle_content strong a,
.main_color .tab_content strong,
.main_color .tab_content strong a,
.main_color .asc_count,
.main_color .avia-testimonial-content strong,
.main_color div .news-headline,
.main_color .ajax_search_title,
.main_color .bbp-author-name,
.avia-datepicker-div .ui-datepicker-month,
.avia-datepicker-div .ui-datepicker-year,
.main_color .sort-param > li:hover > span,
.main_color .sort-param > li:hover > a,
.main_color .sort-param > li:hover ul,
.main_color .product-sorting strong,
.added_to_cart_notification strong {
  color: #222;
}

.main_color .meta-color,
.main_color .sidebar,
.main_color .sidebar a,
.main_color .minor-meta,
.main_color .minor-meta a,
.main_color .text-sep,
.main_color blockquote,
.main_color .post_nav a,
.main_color .comment-text,
.main_color .side-container-inner,
.main_color .news-time,
.main_color .pagination a,
.main_color .pagination span,
.main_color .tweet-text.avatar_no .tweet-time,
#top .main_color .extra-mini-title,
.main_color .team-member-job-title,
.main_color .team-social a,
.main_color #js_sort_items a,
.grid-entry-excerpt,
.main_color .avia-testimonial-subtitle,
.main_color .commentmetadata a,
.main_color .social_bookmarks a,
.main_color .meta-heading > *,
.main_color .slide-meta,
.main_color .slide-meta a,
.main_color .taglist,
.main_color .taglist a,
.main_color .phone-info,
.main_color .phone-info a,
.main_color .av-sort-by-term a,
.main_color .sub_menu > ul > li > a,
.main_color .sub_menu > div > ul > li > a,
.main_color .main_menu ul:first-child > li > a,
#top .main_color .main_menu .menu ul .current_page_item > a,
#top .main_color .main_menu .menu ul .current-menu-item > a,
#top .main_color .sub_menu li ul a,
.main_color .ajax_search_excerpt,
.main_color .iconlist_content,
.main_color .sidebar_tab,
.main_color .tabcontainer .tab,
.main_color table caption,
.main_color tr:nth-child(even),
.main_color .pricing-table > li:nth-child(even),
.main_color .bbp-meta,
.main_color .bbp-author-role,
.main_color .bbp-author-ip,
.main_color .bbp-pagination-count,
.main_color .bbp-topics .bbp-body .bbp-topic-title:before,
.main_color .woocommerce-tabs .tabs a,
.main_color .product_meta,
.main_color .quantity input.qty,
.main_color .cart_dropdown .dropdown_widget,
.main_color .avia_select_fake_val,
.main_color address,
.main_color .product > a .main_color .product_excerpt,
.main_color .term_description,
#top .main_color .price .from,
#top #wrap_all .main_color del,
.main_color .dynamic-title .dynamic-heading,
.main_color .dynamic-title a,
.main_color .sort-param a {
  color: #333;
}

.main_color .meta-heading .special-heading-inner-border,
.main_color .avia-bullet {
  border-color: #8d8d8d;
}

.main_color .breadcrumb,
.main_color .breadcrumb a,
#top .main_color.title_container .main-title,
#top .main_color.title_container .main-title a,
#top .main_color .main_menu .menu ul li > a:hover,
.main_color .main_menu ul:first-child > li a:hover,
.main_color .main_menu ul:first-child > li.current-menu-item > a,
.main_color .main_menu ul:first-child > li.current_page_item > a,
.main_color .main_menu ul:first-child > li.active-parent-item > a,
.main_color .sub_menu > ul > li > a:hover,
.main_color .sub_menu > div > ul > li > a:hover,
#top .main_color .sub_menu ul li a:hover,
.main_color .sub_menu ul:first-child > li.current-menu-item > a,
.main_color .sub_menu ul:first-child > li.current_page_item > a,
.main_color .sub_menu ul:first-child > li.active-parent-item > a,
.main_color .template-archives .tabcontainer a,
#top .main_color .tabcontainer .tab:hover,
.main_color .cart_dropdown .dropdown_widget li a,
#top .main_color .avia_cart_buttons .button,
#top .main_color .dropdown_widget .buttons .button,
.main_color .cart_dropdown_first .cart_dropdown_link {
  color: #666;
}

.main_color .header_bg,
.main_color .main_menu ul,
.main_color .main_menu .menu ul li a,
.main_color .pointer_arrow_wrap .pointer_arrow,
.main_color .avia_mega_div,
.main_color .avia-tt,
.main_color .avia-tt .avia-arrow,
.main_color .avia-tt .avia-arrow {
  background-color: #f8f8f8;
  color: #8d8d8d;
}

.main_color .avia-color-theme-color-subtle,
div .main_color .tabcontainer .active_tab_content,
div .main_color .tabcontainer .active_tab {
  background-color: #fff;
  color: #666;
}

#top .main_color .input-text,
#top .main_color input[type="text"],
#top .main_color input[type="input"],
#top .main_color input[type="password"],
#top .main_color input[type="email"],
#top .main_color input[type="number"],
#top .main_color input[type="url"],
#top .main_color input[type="tel"],
#top .main_color input[type="search"],
#top .main_color textarea,
#top .main_color select,
#top .main_color .chzn-container-single .chzn-single {
  background-color: #fff;
  color: #111;
  border-color: #e1e1e1;
}

.avia-datepicker-div .ui-datepicker-buttonpane button,
.avia_desktop #advanced_menu_toggle:hover,
.avia_desktop #advanced_menu_hide:hover {
  background-color: #2997ab;
  color: #fff;
  border-color: #2997ab;
}

.header_color,
.header_color .site-background,
.header_color .first-quote,
.header_color .related_image_wrap,
.header_color .gravatar img .header_color .hr_content,
.header_color .news-thumb,
.header_color .post-format-icon,
.header_color .ajax_controlls a,
.header_color .tweet-text.avatar_no,
.header_color .big-preview,
.header_color .toggler,
.header_color .toggler.activeTitle:hover,
.header_color #js_sort_items,
.header_color.inner-entry,
.header_color .grid-entry-title,
.header_color .related-format-icon,
.grid-entry .header_color .avia-arrow,
.header_color .avia-gallery-big,
.header_color .avia-gallery-big,
.header_color .avia-gallery img,
.header_color .grid-content,
.header_color tr:nth-child(even),
.header_color .avia-data-table .avia-heading-row .avia-desc-col,
.header_color .avia-data-table .avia-highlight-col,
.header_color .pricing-table > li:nth-child(even),
body .header_color .pricing-table.avia-desc-col li {
  background-color: #fff;
  color: #333;
}

.header_color .heading-color,
.header_color a.iconbox_icon:hover,
.header_color h1,
.header_color h2,
.header_color h3,
.header_color h4,
.header_color h5,
.header_color h6,
.header_color .sidebar .current_page_item > a,
.header_color .sidebar .current-menu-item > a,
.header_color .pagination .current,
.header_color .pagination a:hover,
.header_color strong.avia-testimonial-name,
.header_color .heading,
.header_color .toggle_content strong,
.header_color .toggle_content strong a,
.header_color .tab_content strong,
.header_color .tab_content strong a,
.header_color .asc_count,
.header_color .avia-testimonial-content strong,
.header_color div .news-headline,
.header_color .ajax_search_title,
.header_color .bbp-author-name,
.header_color .sort-param > li:hover > span,
.header_color .sort-param > li:hover > a,
.header_color .sort-param > li:hover ul,
.header_color .product-sorting strong {
  color: #000;
}

.header_color .meta-color,
.header_color .sidebar,
.header_color .sidebar a,
.header_color .minor-meta,
.header_color .minor-meta a,
.header_color .text-sep,
.header_color blockquote,
.header_color .post_nav a,
.header_color .comment-text,
.header_color .side-container-inner,
.header_color .news-time,
.header_color .pagination a,
.header_color .pagination span,
.header_color .tweet-text.avatar_no .tweet-time,
#top .header_color .extra-mini-title,
.header_color .team-member-job-title,
.header_color .team-social a,
.header_color #js_sort_items a,
.grid-entry-excerpt,
.header_color .avia-testimonial-subtitle,
.header_color .commentmetadata a,
.header_color .social_bookmarks a,
.header_color .meta-heading > *,
.header_color .slide-meta,
.header_color .slide-meta a,
.header_color .taglist,
.header_color .taglist a,
.header_color .phone-info,
.header_color .phone-info a,
.header_color .av-sort-by-term a,
.header_color .sub_menu > ul > li > a,
.header_color .sub_menu > div > ul > li > a,
.main_menu ul:first-child > li > a,
.main_menu .menu ul .current_page_item > a,
.main_menu .menu ul .current-menu-item > a,
#top .header_color .sub_menu li ul a,
.header_color .ajax_search_excerpt,
.header_color .iconlist_content,
.header_color .sidebar_tab,
.header_color .tabcontainer .tab,
.header_color table caption,
.header_color tr:nth-child(even),
.header_color .pricing-table > li:nth-child(even),
.header_color .bbp-meta,
.header_color .bbp-author-role,
.header_color .bbp-author-ip,
.header_color .bbp-pagination-count,
.header_color .bbp-topics .bbp-body .bbp-topic-title:before,
.header_color .woocommerce-tabs .tabs a,
.header_color .product_meta,
.header_color .quantity input.qty,
.header_color .cart_dropdown .dropdown_widget,
.header_color .avia_select_fake_val,
.header_color address,
.header_color .product > a .header_color .product_excerpt,
.header_color .term_description,
#top .header_color .price .from,
#top #wrap_all .header_color del,
.header_color .dynamic-title .dynamic-heading,
.header_color .dynamic-title a,
.header_color .sort-param a {
  color: #111;
}

.header_color .meta-heading .special-heading-inner-border,
.header_color .avia-bullet {
  border-color: gray;
}

.header_color table,
.header_color .widget_nav_menu ul:first-child > .current-menu-item,
.header_color .widget_nav_menu ul:first-child > .current_page_item,
.header_color .widget_nav_menu ul:first-child > .current-menu-ancestor,
.header_color .pagination .current,
.header_color .pagination a,
.header_color.iconbox_top .iconbox_content,
.header_color .av_promobox,
.header_color .toggle_content,
.header_color .toggler:hover,
.header_color .related_posts_default_image,
.header_color .search-result-counter,
.header_color .container_wrap_meta,
.header_color .avia-content-slider .slide-image,
.header_color .avia-slider-testimonials .avia-testimonial-content,
.header_color .avia-testimonial-arrow-wrap .avia-arrow,
.header_color .news-thumb,
.header_color .portfolio-preview-content,
.header_color .portfolio-preview-content .avia-arrow,
.header_color tr:nth-child(odd),
.header_color .pricing-table > li:nth-child(odd),
.header_color .pricing-extra {
  background: #fcfcfc;
}

.header_color .breadcrumb,
.header_color .breadcrumb a,
#top .header_color.title_container .main-title,
#top .header_color.title_container .main-title a,
.main_menu .menu ul li > a:hover,
.main_menu ul:first-child > li a:hover,
.main_menu ul:first-child > li.current-menu-item > a,
.main_menu ul:first-child > li.current_page_item > a,
.main_menu ul:first-child > li.active-parent-item > a,
.header_color .sub_menu > ul > li > a:hover,
.header_color .sub_menu > div > ul > li > a:hover,
#top .header_color .sub_menu ul li a:hover,
.header_color .sub_menu ul:first-child > li.current-menu-item > a,
.header_color .sub_menu ul:first-child > li.current_page_item > a,
.header_color .sub_menu ul:first-child > li.active-parent-item > a,
.header_color .template-archives .tabcontainer a,
#top .header_color .tabcontainer .tab:hover,
.header_color .cart_dropdown .dropdown_widget li a,
#top .header_color .avia_cart_buttons .button,
#top .header_color .dropdown_widget .buttons .button,
.header_color .cart_dropdown_first .cart_dropdown_link {
  color: #333;
}

.header_color .header_bg,
.main_menu ul,
.main_menu .menu ul li a,
.header_color .pointer_arrow_wrap .pointer_arrow,
.header_color .avia_mega_div,
.header_color .avia-tt,
.header_color .avia-tt .avia-arrow,
.header_color .avia-tt .avia-arrow {
  background-color: #fff;
  color: gray;
}

.header_color .header_bg,
.header_color {
  background: #fff;
}

.header_color .avia-color-theme-color-subtle,
div .header_color .tabcontainer .active_tab_content,
div .header_color .tabcontainer .active_tab {
  background-color: #fcfcfc;
  color: #333;
}

#top .header_color .input-text,
#top .header_color input[type="text"],
#top .header_color input[type="input"],
#top .header_color input[type="password"],
#top .header_color input[type="email"],
#top .header_color input[type="number"],
#top .header_color input[type="url"],
#top .header_color input[type="tel"],
#top .header_color input[type="search"],
#top .header_color textarea,
#top .header_color select,
#top .header_color .chzn-container-single .chzn-single {
  background-color: #fcfcfc;
  color: gray;
  border-color: #e1e1e1;
}

.alternate_color .widget_price_filter .price_slider_wrapper .ui-widget-content,
.main_color .widget_price_filter .price_slider_wrapper .ui-widget-content,
.header_color .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #e1e1e1;
}

#top .alternate_color a:hover .avia-bullet,
#top .main_color a:hover .avia-bullet,
#top .header_color a:hover .avia-bullet {
  border-color: #55adbd;
}

#top .ls-nav-start:before,
.image-overlay.overlay-type-video .image-overlay-inside:before {
  content: "\E897";
  font-family: entypo-fontello;
}

h1,
h2,
h3,
h4,
h5,
h6,
#top .title_container .main-title,
tr.pricing-row td,
#top .portfolio-title,
.callout .content-area,
.avia-big-box .avia-innerbox,
.av-special-font,
body {
  font-family: "Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

@media screen and (max-width: 767px) {
  .responsive #top .socket_color .tabcontainer .active_tab {
    background-color: #eee;
    color: #111;
  }

  .responsive #top .socket_color .tabcontainer {
    border-color: #161616;
  }

  .responsive #top .socket_color .active_tab_content {
    background-color: #000;
  }

  .responsive #top .footer_color .tabcontainer .active_tab {
    background-color: #fff;
    color: #fff;
  }

  .responsive #top .footer_color .tabcontainer {
    border-color: #555;
  }

  .responsive #top .footer_color .active_tab_content {
    background-color: #222;
  }

  .responsive #top .alternate_color .active_tab_content {
    background-color: #f8f8f8;
  }

  .responsive #top .main_color .active_tab_content {
    background-color: #fff;
  }

  .responsive #top .header_color .active_tab_content {
    background-color: #fcfcfc;
  }

  .responsive #top .alternate_color .tabcontainer .active_tab,
  .responsive #top .main_color .tabcontainer .active_tab,
  .responsive #top .header_color .tabcontainer .active_tab {
    background-color: #55adbd;
    color: #fff;
  }

  .responsive #top .alternate_color .tabcontainer,
  .responsive #top .main_color .tabcontainer,
  .responsive #top .header_color .tabcontainer {
    border-color: #e1e1e1;
  }
}

.av-special-heading {
  padding-bottom: 50px;
  font-size: 50px;
}

.item-heading {
  padding-bottom: 10px;
  font-size: 30px;
}

.absolute .header-image {
  left: 18mm;
  top: 20mm;
}

.absolute .header-image img {
  width: 70mm;
}

.absolute .header-image-two {
  right: 18mm;
  top: 20mm;
}

.absolute .header-image-two img {
  width: 35mm;
}

.absolute .adresblok {
  left: 110mm;
  top: 50mm;
}

.absolute .content {
  left: 20mm;
  top: 50mm;
  width: 170mm;
}

.absolute .laatste-verzoek {
  color: #ed7104;
  font-weight: bold;
}

.absolute .page-break-after {
  page-break-after: always;
}

.absolute p.bold-color {
  color: #ed7104;
  font-weight: bold;
}

.avia_google_maps_container {
  height: 420px;
  width: 100%;
}

.widget_fetch_tweets_widget_by_id .fetch-tweets {
  max-width: 100%;
  background-color: transparent;
}

.verify_code {
  font-size: 54px;
  padding: 10px;
  text-align: center;
  border-width: 2px;
  width: 100%;
  font-weight: bold;
}
.verify_code_verstuur {
  width: auto;
  font-size: 28px;
  margin-left: 185px;
  padding: 4px 20px;
  cursor: pointer;
}
.item-request-verification-as-url {
  position: absolute;
  left: -5000px;
  top: -5000px;
}
.fetch-tweets-profile-image {
  max-width: 32px;
  float: left;
  clear: left;
}
.twitter-profile-image-url {
  max-width: 32px;
}
.fetch-tweets-main {
  margin-left: 42px;
}

.av-share-box {
  .av-share-box-list {
    margin: 0;
    li {
      padding: 0 !important;
    }
  }
}

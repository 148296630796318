body.high-contrast {
	a {
		background: black !important;
		color: white !important;
	}

	* {
		background-color: #000 !important;
		background: #000 !important;
		color: #fff !important;
	}

	a:focus {
		background-color: #666 !important;
		background: #666 !important;
		color: #fff !important;
	}
}

/* ======================================================================================================================================================
GENERIC
====================================================================================================================================================== */

p:empty,
.entry-content-wrapper:empty {
	display: none;
}

.avia-shadow {
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

slide-entry-wrap body .container_wrap .avia-builder-el-no-sibling {
	margin-top: 0;
	margin-bottom: 0;
}

body .container_wrap .avia-builder-el-last {
	margin-bottom: 0;
}

body .container_wrap .avia-builder-el-first {
	margin-top: 0;
}

/* ======================================================================================================================================================
TEXT BLOCK
====================================================================================================================================================== */
.avia_textblock {
	clear: both;
}

.flex_column + .avia_textblock {
	padding-top: 50px;
}

/* ======================================================================================================================================================
Columns
====================================================================================================================================================== */
body .column-top-margin {
	margin-top: 30px;
}

/* ======================================================================================================================================================
HEADING
====================================================================================================================================================== */
.av-special-heading {
	width: 100%;
	clear: both;
	display: block;
	margin-top: 50px;
	overflow: hidden;
	position: relative;
}

.av-special-heading.avia-builder-el-no-sibling {
	margin-top: 0px;
	margin-bottom: 0px;
}

.flex_column + .av-special-heading {
	float: left;
}

body .av-special-heading .av-special-heading-tag {
	padding: 0;
	margin: 0;
	line-height: 1.4em;
	float: left;
}

.meta-heading .av-special-heading-tag {
	font-weight: normal;
}

.special-heading-border {
	position: relative;
	overflow: hidden;
}

.av-special-heading-h1 .special-heading-border {
	height: 3.4em;
}

.av-special-heading-h2 .special-heading-border {
	height: 2.6em;
}

.av-special-heading-h3 .special-heading-border {
	height: 1.9em;
}

.av-special-heading-h4 .special-heading-border {
	height: 1.6em;
}

.av-special-heading-h5 .special-heading-border {
	height: 1.5em;
}

.av-special-heading-h6 .special-heading-border {
	height: 1.4em;
}

.special-heading-inner-border {
	display: block;
	width: 100%;
	margin-left: 15px;
	border-top-style: solid;
	border-top-width: 1px;
	position: relative;
	top: 50%;
	opacity: 0.15;
	filter: alpha(opacity=15);
}

.meta-heading .special-heading-inner-border {
	opacity: 0.2;
	filter: alpha(opacity=20);
}

.custom-color-heading .special-heading-inner-border {
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.custom-color-heading .av-special-heading-tag {
	color: inherit;
}

.av-inherit-size .av-special-heading-tag {
	font-size: 1em;
}

.av-thin-font .av-special-heading-tag,
.modern-quote .av-special-heading-tag {
	font-weight: 300;
}

.av-thin-font strong,
.modern-quote strong {
	font-weight: 600;
}

body .av-special-heading.modern-centered {
	text-align: center;
}

/*quote style*/
body .av-special-heading.blockquote > * {
	white-space: normal;
	float: none;
}

.av-special-heading.classic-quote {
	text-align: center;
}

body .av-special-heading.classic-quote > * {
	display: block;
	font-family: "Droid Serif", Georgia, Times, serif;
	font-weight: normal;
	font-style: italic;
	float: none;
}

body .av-special-heading.blockquote .special-heading-border {
	display: none;
}

/*subheading*/

.av-subheading {
	font-size: 15px;
	line-height: 1.3em;
}

.av-subheading p:first-child {
	margin-top: 0;
}

.av-subheading p:last-child {
	margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
	.av-inherit-size .av-special-heading-tag {
		font-size: 0.8em;
	}
}

/* ======================================================================================================================================================
COLOR SECTION
====================================================================================================================================================== */

.avia-section {
	clear: both;
	width: 100%;
	float: left;
	min-height: 100px;
}

.js_active .av-minimum-height .container {
	opacity: 0;
	-webkit-transition: opacity 0.6s ease-in;
	-moz-transition: opacity 0.6s ease-in;
	-o-transition: opacity 0.6s ease-in;
	-ms-transition: opacity 0.6s ease-in;
	transition: opacity 0.6s ease-in;
}

.avia-section.av-minimum-height .container {
	display: table;
}

.avia-section.av-minimum-height .container .content {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	float: none !important;
}

.av-minimum-height-100 .container {
	height: 1500px;
}

/*will be overwritten by js*/

.avia-full-stretch {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.avia_mobile .avia-full-stretch {
	background-attachment: scroll !important;
}

#top #main .avia-section .template-page {
	width: 100%;
	border: none;
	margin-left: auto;
	padding-left: 0;
	margin-right: auto;
}

.avia-section .template-page .entry-content-wrapper {
	padding-right: 0;
}

.avia-section-small .content,
.avia-section-small .sidebar {
	padding-top: 20px;
	padding-bottom: 20px;
}

.avia-section-large .content,
.avia-section-large .sidebar {
	padding-top: 70px;
	padding-bottom: 70px;
}

.avia-section-no-padding .content,
.avia-section-no-padding .sidebar {
	padding-top: 0;
	padding-bottom: 0;
}

.nonfixed_header .container_wrap:first-child,
.avia-section.avia-full-stretch {
	border-top: none;
}

/* ======================================================================================================================================================
ICON
====================================================================================================================================================== */

/*right icons*/
#top .av_font_icon a {
	color: inherit;
	display: block;
}

.av_font_icon a:hover {
	text-decoration: none;
}

.av_font_icon {
	display: block;
	text-align: center;
}

.avia-icon-pos-left {
	float: left;
	margin-right: 0.5em;
}

.avia-icon-pos-right {
	float: right;
	margin-left: 0.5em;
}

.av-icon-display {
	display: block;
	font-size: 30px;
	line-height: 58px;
	margin: 0 auto 20px auto;
	width: 60px;
	border-radius: 100px;
	border-style: solid;
	border-width: 1px;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/*tooltip*/

#top .avia-icon-tooltip {
	width: 250px;
	font-size: 15px;
	line-height: 1.6em;
}

/*big icon with border*/
.av_font_icon.av-icon-style-border .av-icon-char {
	-webkit-backface-visibility: hidden; /*fix bug in webkit where item shifts a few px on hover*/
}

.av_font_icon.av-icon-style-border .av-icon-char {
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	border-radius: 1000px;
	border-width: 3px;
	border-style: solid;
	display: block;
	margin: 0 auto;
	padding: 30px;
	color: inherit;
	border-color: inherit;
	position: relative;
}

.av_icon_caption {
	font-size: 16px;
	text-transform: uppercase;
	line-height: 1.3em;
	display: block;
	text-align: center;
	margin-top: 8px;
	font-weight: 400;
}

.av_font_icon.av-icon-style-border .av-icon-char:after {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: "";
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	top: 0;
	left: 0;
	padding: 0;
	z-index: -1;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
	opacity: 0;
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
}

/*iconlist animation*/
.avia_transform .av_font_icon {
	opacity: 0.1;
	-moz-transform: scale(0.5);
	-webkit-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
}

.avia_transform .avia_start_delayed_animation.av_font_icon {
	opacity: 1;
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* ======================================================================================================================================================
ICONBOX
====================================================================================================================================================== */
#top .iconbox {
	background: transparent;
}

.iconbox {
	margin-bottom: 30px;
	position: relative;
	clear: both;
}

.iconbox_icon {
	float: left;
	margin: 2px 10px 0 0;
	height: 23px;
	width: 23px;
	font-size: 23px;
	line-height: 18px;
}

a.iconbox_icon:hover {
	text-decoration: none;
}

.iconbox .iconbox_content .iconbox_content_title {
	border: medium none;
	padding: 2px 0 0 0;
	position: relative;
	margin: 0 0 16px 0;
	clear: none;
	overflow: hidden;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 16px;
}

#top .iconbox_top {
	margin-top: 20px;
	text-align: center;
}

.iconbox_top .iconbox_content {
	padding: 45px 20px 20px 20px;
	border-radius: 3px;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
}

.iconbox_top .iconbox_icon {
	float: none;
	position: absolute;
	left: 50%;
	top: -26px;
	margin: 0 0 0 -26px;
	padding: 15px;
	border-radius: 100px;
	-webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: content-box; /* Firefox, other Gecko */
	box-sizing: content-box;
	font-size: 20px;
	line-height: 24px;
}

/* ======================================================================================================================================================
ICONLIST
====================================================================================================================================================== */
.avia-icon-list-container {
	margin: 30px 0;
	clear: both;
}

.avia-icon-list {
	margin: 0;
	padding: 0;
}

.avia-icon-list li {
	margin: 0;
	padding: 0 0 30px 0;
	list-style-type: none;
	list-style-position: outside;
	clear: both;
	position: relative;
	min-height: 60px;
}

.avia-icon-list .iconlist_icon {
	height: 64px;
	width: 64px;
	line-height: 64px;
	font-size: 30px;
	text-align: center;
	border-radius: 500px;
	position: relative;
	float: left;
	margin-right: 30px;
	margin-left: 2px;
	z-index: 5;
	color: #fff;
}

.avia-icon-list a.iconlist_icon:hover {
	color: #fff;
	text-decoration: none;
}

.avia-icon-list .iconlist_title {
	text-transform: uppercase;
	top: 4px;
	margin-bottom: 0;
	position: relative;
}

#top .avia-icon-list .iconlist_title a:hover {
	text-decoration: underline;
}

.avia-icon-list .iconlist_content_wrap {
	overflow: hidden;
	min-height: 1.5em;
}

.avia-icon-list .iconlist-timeline {
	position: absolute;
	top: 1%;
	left: 32px;
	width: 1px;
	height: 99%;
	border-right-width: 1px;
	border-right-style: dashed;
}

.avia-icon-list .iconlist_content li {
	min-height: 0;
	padding: 0;
	list-style: disc inside;
}

.avia-icon-list li:last-child .iconlist-timeline {
	display: none;
}

/*right icons*/
.avia-icon-list-right {
	text-align: right;
}

.avia-icon-list-right .iconlist_icon {
	float: right;
	margin-left: 30px;
	margin-right: 0;
	margin-right: 2px;
}

.avia-icon-list-right .iconlist-timeline {
	left: auto;
	right: 32px;
}

/*iconlist animation*/
.avia_transform .avia-icon-list .iconlist_icon {
	opacity: 0.1;
	-moz-transform: scale(0.5);
	-webkit-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
}

.avia_transform .iconlist-timeline {
	height: 0%;
}

.avia_transform .avia-icon-list .avia_start_animation .iconlist_icon {
	opacity: 1;
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.avia_transform .avia-icon-list .avia_start_animation .iconlist-timeline {
	height: 100%;
}

/* ======================================================================================================================================================
HR
====================================================================================================================================================== */
.hr {
	clear: both;
	display: block;
	width: 100%;
	height: 25px;
	line-height: 25px;
	position: relative;
	margin: 30px 0;
	float: left;
}

.hr-inner {
	width: 100%;
	position: absolute;
	height: 1px;
	left: 0;
	top: 50%;
	width: 100%;
	margin-top: -1px;
	border-top-width: 1px;
	border-top-style: solid;
}

.hr-invisible,
.hr-invisible .hr-inner {
	margin: 0;
	border: none;
}

.hr-invisible {
	float: none;
}

.hr-full,
.hr-big {
	margin: 70px 0;
}

.hr-full .hr-inner {
	width: 300%;
	left: -100%;
}

.hr-full.hr-shadow .hr-inner {
	box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.sidebar_right .hr-full .hr-inner {
	left: auto;
	right: -50px;
}

.sidebar_left .hr-full .hr-inner {
	left: -50px;
}

.hr-short {
	height: 20px;
	line-height: 20px;
	margin: 30px 0;
	float: none;
}

.hr-short .hr-inner {
	width: 32%;
	left: 50%;
	margin-left: -16%;
}

.hr-short.hr-left .hr-inner {
	left: 0%;
	margin-left: 0%;
}

.hr-short.hr-right .hr-inner {
	left: auto;
	right: 0;
	margin-left: 0%;
}

.hr-short .hr-inner-style {
	border-radius: 20px;
	height: 9px;
	width: 9px;
	border-width: 2px;
	border-style: solid;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -5px;
	margin-top: -5px;
}

.hr-short.hr-left .hr-inner-style {
	left: 5px;
}

.hr-short.hr-right .hr-inner-style {
	left: auto;
	right: 0;
}

/* ======================================================================================================================================================
Button
====================================================================================================================================================== */

/*button element*/
.avia-button-wrap {
	display: inline-block;
}

.avia-button {
	color: #777;
	border-color: #e1e1e1;
	background-color: #f8f8f8;
}

body div .avia-button {
	border-radius: 3px;
	padding: 10px;
	font-size: 12px;
	text-decoration: none;
	display: inline-block;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	margin: 3px 0;
	line-height: 1.2em;
	position: relative;
	font-weight: normal;
	text-align: center;
	max-width: 100%;
}

.avia-button:hover {
	opacity: 0.9;
}

.avia-button:active {
	border-bottom-width: 0px;
	border-top-width: 1px;
	border-top-style: solid;
}

.avia-button.avia-color-theme-color-subtle {
	background-image: none;
}

.avia-button .avia_button_icon {
	position: relative;
	left: -0.3em;
}

.avia-button.avia-icon_select-no .avia_button_icon {
	display: none;
}

.avia-button.avia-color-grey,
.avia-button.avia-color-grey:hover {
	background-color: #555;
	border-color: #333333;
	color: #fff;
}

.avia-button.avia-color-black,
.avia-button.avia-color-black:hover {
	background-color: #2c2c2c;
	border-color: #000;
	color: #fff;
}

.avia-button.avia-color-red,
.avia-button.avia-color-red:hover {
	background-color: #b02b2c;
	border-color: #8b2121;
	color: #fff;
}

.avia-button.avia-color-orange,
.avia-button.avia-color-orange:hover {
	background-color: #edae44;
	border-color: #ca9336;
	color: #fff;
}

.avia-button.avia-color-green,
.avia-button.avia-color-green:hover {
	background-color: #83a846;
	border-color: #6f8f3b;
	color: #fff;
}

.avia-button.avia-color-blue,
.avia-button.avia-color-blue:hover {
	background-color: #7bb0e7;
	border-color: #6693c2;
	color: #fff;
}

.avia-button.avia-color-aqua,
.avia-button.avia-color-aqua:hover {
	background-color: #4ecac2;
	border-color: #3eaaa3;
	color: #fff;
}

.avia-button.avia-color-teal,
.avia-button.avia-color-teal:hover {
	background-color: #5f8789;
	border-color: #3f5e5f;
	color: #fff;
}

.avia-button.avia-color-purple,
.avia-button.avia-color-purple:hover {
	background-color: #745f7e;
	border-color: #514358;
	color: #fff;
}

.avia-button.avia-color-pink,
.avia-button.avia-color-pink:hover {
	background-color: #d65799;
	border-color: #bb4b85;
	color: #fff;
}

.avia-button.avia-color-silver,
.avia-button.avia-color-silver:hover {
	background-color: #dadada;
	border-color: #b4b4b4;
	color: #555;
}

.avia-button.avia-color-theme-color-subtle {
	border-width: 1px;
	border-style: solid;
}

#top .main_color .avia-button.avia-color-theme-color,
#top .main_color .avia-button.avia-color-theme-color:hover {
	color: #fff;
	/*color: rgba(0, 0, 0, 0.5);
text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1); */
}

.avia-button-center {
	display: block;
	text-align: center;
}

.avia-button-right {
	display: block;
	float: right;
}

.avia-button.avia-position-right {
	float: right;
	display: block;
}

.avia-button.avia-position-left {
	float: left;
	display: block;
}

.avia-button.avia-size-small {
	padding: 9px 10px 7px;
	font-size: 13px;
	min-width: 80px;
}

.avia-button.avia-size-medium {
	padding: 12px 16px 10px;
	font-size: 13px;
	min-width: 90px;
}

.avia-button.avia-size-large {
	padding: 15px 30px 13px;
	font-size: 13px;
	min-width: 127px;
}

/* ======================================================================================================================================================
Message Box
====================================================================================================================================================== */

.avia_message_box {
	text-align: center;
	position: relative;
	border-color: #e1e1e1;
	background-color: #f8f8f8;
	clear: both;
	margin: 30px 0;
}

div .avia_message_box .avia_message_box_title {
	background-color: rgba(0, 0, 0, 0.05);
	position: absolute;
	top: 0;
	left: 0;
	padding: 5px 20px;
	font-size: 11px;
	text-shadow: none;
}

.avia_message_box_content {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
}

.avia_message_box_icon {
	position: relative;
	left: -0.5em;
	font-size: 1.3em;
}

.avia_message_box.avia-size-normal {
	padding: 17px;
}

.avia_message_box.avia-size-large {
	padding: 35px;
}

.avia_message_box.avia-color-green,
.avia_message_box.avia-color-green a {
	background-color: #e0f1b5;
	border-color: #8baf5b;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
	color: #4f5f39;
}

.avia_message_box.avia-color-blue,
.avia_message_box.avia-color-blue a {
	background-color: #f5f6fc;
	border-color: #9dadbf;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
	color: #4671a3;
}

.avia_message_box.avia-color-red,
.avia_message_box.avia-color-red a {
	background-color: #fff8f4;
	border-color: #cda18f;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
	color: #af2c20;
}

.avia_message_box.avia-color-orange,
.avia_message_box.avia-color-orange a {
	background-color: #fffdf3;
	border-color: #e6bf4a;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
	color: #c96d11;
}

.avia_message_box.avia-color-grey,
.avia_message_box.avia-color-grey a {
	background-color: #333;
	border-color: #444;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 1);
	color: #fff;
}

.avia_message_box.avia-color-silver,
.avia_message_box.avia-color-silver a {
	background-color: #f8f8f8;
	border-color: #e1e1e1;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
	color: #444;
}

.avia_message_box_content p {
	display: inline;
}

#top .avia_message_box_content strong {
	color: inherit;
}

.avia_message_box a {
	text-decoration: underline;
}

#top .avia_message_box a:hover {
	color: inherit;
	opacity: 0.7;
}

.avia_message_box.avia-color-blue .avia_message_box_title {
	background: rgba(70, 113, 163, 0.08);
}

.avia_message_box.avia-color-red .avia_message_box_title {
	background: rgba(175, 44, 32, 0.06);
}

.avia_message_box.avia-color-orange .avia_message_box_title {
	background: rgba(250, 235, 125, 0.2);
}

.avia_message_box.avia-color-grey .avia_message_box_title {
	background: rgba(255, 255, 255, 0.05);
}

.avia_message_box.avia-icon_select-no .avia_message_box_icon {
	display: none;
}

.avia_message_box.avia-border-solid {
	border-width: 3px;
	border-style: solid;
}

.avia_message_box.avia-border-dashed {
	border-width: 1px;
	border-style: dashed;
}

/* ======================================================================================================================================================
Tabs
====================================================================================================================================================== */
#top .tabcontainer {
	background: transparent;
}

.tabcontainer {
	margin: 30px 0;
	position: relative;
	width: 100%;
	clear: both;
	overflow: hidden;
	background: transparent;
}

.tab_titles {
	position: relative;
	width: 100%;
	clear: both;
	float: left;
	z-index: 5;
}

.widget .tabcontainer {
	margin-top: 0px;
}

.js_active .tab_content {
	visibility: hidden;
	clear: both;
	padding: 10px 19px;
	overflow: auto;
	position: absolute;
	top: 0;
	z-index: 0;
	left: 120%;
	width: 100%;
}

.js_active .tab {
	cursor: pointer;
	margin: 0 -1px 0 0;
	display: block;
	float: left;
	z-index: 2;
	position: relative;
	padding: 12px 16px;
	top: 1px;
	font-size: 11px;
	-webkit-touch-callout: none;
	border-style: solid;
	border-width: 1px;
}

.js_active .top_tab .tab {
	border-bottom: none;
	padding: 12px 16px 14px 16px;
}

.js_active .active_tab {
	z-index: 4;
	position: relative;
	font-weight: bold;
}

.js_active .tab_titles .tab:first-child {
	border-top-left-radius: 2px;
}

.js_active .tab_titles .tab:last-child {
	border-top-right-radius: 2px;
}

.js_active .active_tab_content {
	display: block;
	visibility: visible;
	z-index: 3;
	position: relative;
	overflow: auto;
	border-style: solid;
	border-width: 1px;
	border-radius: 2px;
	left: 0;
	width: auto;
}

.tabcontainer .tab_icon {
	margin-right: 7px;
	font-size: 13px;
}

.tab_inner_content {
	margin: 11px 0;
	left: -200%;
	position: relative;
}

.active_tab_content .tab_inner_content {
	left: 0;
	position: relative;
}

/*sidebar tabs*/

.sidebar_tab .tab_titles {
	width: 30%;
	min-width: 170px;
	max-width: 250px;
	float: left;
	display: block;
}

.sidebar_tab .tab_icon {
	font-size: 14px;
}

.sidebar_tab_right .tab_titles {
	float: right;
}

.sidebar_tab .tab_titles .tab {
	width: 100%;
	margin: 0 0 -1px 0;
	top: 0;
}

.sidebar_tab .tab_content {
	overflow: hidden;
	clear: none;
	left: -1px;
}

.sidebar_tab_right .tab_content {
	left: 1px;
}

.js_active .sidebar_tab .tab_titles .tab:last-child {
	border-top-right-radius: 0;
	border-bottom-left-radius: 2px;
}

.sidebar_tab_left .active_tab.tab {
	width: 100.5%;
	border-right: none;
}

.sidebar_tab_right .active_tab.tab {
	width: 100.5%;
	border-left: none;
	left: -1px;
}

/*noborder sidebar tabs*/

.noborder_tabs.sidebar_tab_left .tab_content,
.noborder_tabs.sidebar_tab_right .tab_content {
	border: none;
	box-shadow: -8px 0px 20px -10px rgba(0, 0, 0, 0.2);
	left: 0;
	padding: 0px 0 0 30px;
}

.noborder_tabs.sidebar_tab_right .tab_content {
	box-shadow: 8px 0px 20px -10px rgba(0, 0, 0, 0.2);
	padding: 0px 30px 0 0;
}

.noborder_tabs.sidebar_tab_left .tab {
	border-left: none;
}

.noborder_tabs.sidebar_tab_right .tab {
	border-right: none;
}

.noborder_tabs .tab:first-child {
	border-top: none;
}

.noborder_tabs .tab:last-child {
	border-bottom: none;
}

.tabcontainer .tab.fullsize-tab {
	display: none;
}

@media only screen and (max-width: 767px) {
	.responsive .tabcontainer {
		border-width: 1px;
		border-style: solid;
		border-top: none;
		overflow: hidden;
	}

	.responsive .tabcontainer .tab_titles {
		display: none;
	}

	.responsive .tabcontainer .tab_content,
	.responsive .tabcontainer .tab {
		width: 100%;
		max-width: 100%;
		border-left: none;
		border-right: 0;
		left: 0;
		top: 0;
		min-height: 0 !important;
	}

	.responsive .tabcontainer .tab_content {
		border-bottom: none;
		padding: 15px 30px;
		clear: both;
	}

	.responsive .tabcontainer .tab.fullsize-tab {
		display: block;
		margin-bottom: -1px;
	}

	.responsive .top_tab .tab.fullsize-tab {
		margin-bottom: 0px;
	}
}

/* ======================================================================================================================================================
Accordion/Toggle
====================================================================================================================================================== */

/*toggle*/
.js_active .toggle_wrap {
	visibility: hidden;
	position: absolute;
	z-index: 0;
	width: 100%;
	left: 120%;
}

.active_tc.toggle_wrap {
	position: static;
	visibility: visible;
	left: 0;
	width: auto;
	z-index: 1;
	display: none;
}

.js_active .toggler {
	cursor: pointer;
	display: block;
	margin: 0;
	padding: 9px 3px 9px 35px;
	position: relative;
	top: 1px;
	border-style: solid;
	border-width: 1px;
	-webkit-touch-callout: none;
}

.togglecontainer {
	margin: 30px 0;
	position: relative;
	clear: both;
}

.togglecontainer .single_toggle:first-child .toggler,
.togglecontainer .taglist + .single_toggle .toggler {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.togglecontainer > div:last-of-type .toggler {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.togglecontainer > p.activeTitle {
	border-radius: 0;
}

.toggle_content {
	padding: 12px 30px 11px 30px;
	margin: 0px 0 5px 0;
	border-style: solid;
	border-width: 1px;
	border-top: none;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	overflow: hidden;
}

.toggle_icon {
	position: absolute;
	width: 15px;
	height: 15px;
	border-style: solid;
	border-width: 1px;
	top: 12px;
	left: 10px;
}

.toggle_icon .vert_icon {
	border-left-style: solid;
	border-left-width: 3px;
	position: absolute;
	left: 5px;
	top: 1px;
	height: 11px;
}

.toggle_icon .hor_icon {
	border-top-style: solid;
	border-top-width: 3px;
	position: absolute;
	top: 5px;
	left: 1px;
	width: 11px;
}

.activeTitle .toggle_icon .vert_icon {
	border: none;
}

.active_tc.toggle_wrap {
	display: block;
}

/*sorting*/
.taglist {
	margin-bottom: 5px;
}

.taglist .tag-seperator:last-child {
	display: none;
}

.taglist .tag-seperator {
	padding: 0px 4px;
}

.single_toggle {
	width: 100%;
	float: left;
	display: block;
	margin: 0 0 -2px 0;
	padding-bottom: 1px;
	overflow: hidden;
	position: relative;
}

/* ======================================================================================================================================================
Promo Box
====================================================================================================================================================== */

.av_promobox {
	position: relative;
	border-width: 1px;
	border-style: solid;
	overflow: hidden;
	padding: 20px 30px;
	clear: both;
	margin: 50px 0;
	width: 100%;
	float: left;
}

.flex_column > .av_promobox:first-child {
	margin-top: 0;
}

.av_promobox .avia-button {
	right: 30px;
	top: 50%;
	position: absolute;
	z-index: 2;
}

.avia-promocontent {
	margin-right: 200px;
	position: relative;
	z-index: 3;
}

.avia-promocontent p {
	font-size: 14px;
}

.avia-button-no .avia-promocontent {
	margin: 0;
}

.avia-button-no .avia-button {
	display: none;
}

.av_promobox .avia-button.avia-size-small {
	margin-top: -15px;
}

.av_promobox .avia-button.avia-size-medium {
	margin-top: -21px;
}

.av_promobox .avia-button.avia-size-large {
	margin-top: -23px;
}

@media only screen and (max-width: 767px) {
	.responsive .avia-promocontent {
		margin: 0;
	}

	.responsive .av_promobox .avia-button,
	.responsive .av_promobox .avia-button-wrap {
		width: 100%;
		margin-top: 4px;
		position: relative;
		top: 0;
		left: 0;
		text-align: center;
		float: none;
	}
}

/* ======================================================================================================================================================
Dropcaps
====================================================================================================================================================== */

.av_dropcap1 {
	display: block;
	float: left;
	font-size: 38px;
	line-height: 32px;
	margin: 4px 8px 0 0;
}

.av_dropcap2 {
	display: block;
	float: left;
	font-size: 18px;
	line-height: 35px;
	width: 35px;
	text-align: center;
	margin: 4px 8px 0 0;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	font-weight: bold;
}

/* ======================================================================================================================================================
Contact Form
====================================================================================================================================================== */

fieldset {
	border: none;
}

.avia_ajax_form {
	clear: both;
}

.avia_ajax_form fieldset p br {
	display: none;
}

.avia_ajax_form label {
	display: block;
	visibility: visible;
	position: relative;
}

/*dont delete. ie8 needs this separate*/
.avia_ajax_form label,
.modified_width:before {
	display: block;
	visibility: visible;
	position: relative;
	margin-bottom: 7px;
	font-weight: 600;
}

#ajaxresponse.hidden {
	visibility: visible;
	position: static;
	display: none;
}

#top .avia_ajax_form .text_input,
#top .avia_ajax_form .select,
#top .avia_ajax_form .text_area {
	width: 100%;
	margin-bottom: 0;
	display: inline;
	min-width: 100px;
	padding: 13px;
	border-radius: 2px;
}

#top select,
#top .avia_ajax_form .select,
#top .entry-content-wrapper select,
#top .mobileMenu {
	-webkit-appearance: none;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	background-image: url("../assets/images/mobileMenu.png");
	background-position: center right;
	background-repeat: no-repeat;
	border-radius: 2px;
}

#top select[multiple] {
	background-image: none;
}

#top .avia_ajax_form input[type="checkbox"] {
	float: left;
	margin-right: 8px;
	top: 2px;
	position: relative;
}

.value_verifier_label {
	position: absolute;
	bottom: 11px;
	left: 10px;
	font-size: 13px;
	line-height: 21px;
}

#top .avia_ajax_form .captcha {
	padding-left: 65px;
}

.avia_ajax_form p {
	position: relative;
	clear: both;
	float: left;
	width: 100%;
}

.avia_ajax_form p.hidden {
	position: absolute;
	width: 0px;
	left: 0;
	top: 0;
}

.avia_ajax_form .form_element_half {
	width: 49.5%;
	float: left;
	margin-right: 1%;
}

.avia_ajax_form .form_element_half_2 {
	clear: none;
	margin-right: 0;
}

.avia_ajax_form .button {
	margin: 0;
	padding: 16px 50px;
	border-radius: 2px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	font-weight: normal;
	font-size: 12px;
}

.form_element_half .button {
	width: 100%;
	padding: 14px 10px 15px;
}

.modified_width:before {
	display: block;
	content: "Submit Form";
	visibility: hidden;
}

#footer .avia_ajax_form textarea {
	height: 90px;
}

.avia_ajax_form p input,
.avia_ajax_form p textarea,
.avia_ajax_form p select,
.avia_ajax_form p .input_checkbox_label {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/*contact form datepicker*/

.avia-datepicker-div {
	background: #fff;
	border: 1px solid #e1e1e1;
}

.avia-datepicker-div a {
	color: #333;
	background-color: #f8f8f8;
}

.avia-datepicker-div a.ui-state-active {
	color: #8bba34;
}

.avia-datepicker-div a.ui-state-highlight {
	color: #8bba34;
}

.avia-datepicker-div a.ui-state-hover {
	color: #fff;
	background-color: #bbb;
}

.avia-datepicker-div .ui-datepicker-buttonpane button {
	background-color: #8bba34;
	color: #fff;
	border-color: #8bba34;
}

.avia-datepicker-div.ui-datepicker {
	width: 300px;
	padding: 20px;
	display: none;
	box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.2);
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-header {
	position: relative;
	padding: 0.2em 0;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-prev,
.avia-datepicker-div.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 2px;
	width: 50px;
	height: 1.8em;
	text-align: center;
	line-height: 1.8em;
	cursor: pointer;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-prev {
	left: 2px;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-next {
	right: 2px;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-title select {
	font-size: 1em;
	margin: 1px 0;
}

.avia-datepicker-div.ui-datepicker select.ui-datepicker-month-year {
	width: 100%;
}

.avia-datepicker-div.ui-datepicker select.ui-datepicker-month,
.avia-datepicker-div.ui-datepicker select.ui-datepicker-year {
	width: 49%;
}

.avia-datepicker-div.ui-datepicker table {
	width: 100%;
	font-size: 0.9em;
	border-collapse: collapse;
	margin: 0 0 0.4em;
}

.avia-datepicker-div.ui-datepicker th {
	padding: 0.7em 0.3em;
	text-align: center;
	font-weight: bold;
	border: 0;
}

.avia-datepicker-div.ui-datepicker td {
	border: 0;
	padding: 1px;
}

.avia-datepicker-div.ui-datepicker td span,
.ui-datepicker td a {
	display: block;
	padding: 0.2em;
	text-align: center;
	text-decoration: none;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: 0.7em 0 0 0;
	padding: 0 0.2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: 0.5em 0.2em 0.4em;
	cursor: pointer;
	padding: 10px 20px;
	width: auto;
	overflow: visible;
	border: none;
	border-radius: 3px;
}

.avia-datepicker-div.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}

/* RTL support */
.avia-datepicker-div.ui-datepicker-rtl {
	direction: rtl;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: right;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

.avia-datepicker-div.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.avia-datepicker-div.ui-datepicker-cover {
	display: none; /*sorry for IE5*/
	display/**/: block; /*sorry for IE5*/
	position: absolute; /*must have*/
	z-index: -1; /*must have*/
	top: -4px; /*must have*/
	left: -4px; /*must have*/
	width: 200px; /*must have*/
	height: 200px; /*must have*/
}

@media only screen and (max-width: 479px) {
	.responsive .avia_ajax_form .form_element_half {
		width: 100%;
		clear: both;
		margin-right: 0;
		float: none;
	}
}

/* ======================================================================================================================================================
Table
====================================================================================================================================================== */

/*data table*/

div .avia-table {
	background: transparent;
	width: 100%;
	clear: both;
	margin: 30px 0;
}

.avia-data-table .avia-heading-row .avia-desc-col {
	border-top: none;
	border-left: none;
}

.avia-data-table .avia-button-row td {
	text-align: center;
}

.avia-data-table .avia-center-col {
	text-align: center;
}

.avia-data-table td:before {
	display: none;
}

.avia-data-table .avia-pricing-row td,
.avia-data-table .avia-pricing-row th {
	text-align: center;
	font-size: 40px;
	line-height: 1.3em;
}

.avia-data-table .avia-desc-col {
	text-align: right;
}

.avia-data-table .avia-pricing-row small {
	display: block;
	font-size: 11px;
	font-style: italic;
	line-height: 1.7em;
}

.avia-data-table .avia-pricing-row .avia-desc-col {
	font-size: 14px;
	text-align: right;
}

@media only screen and (max-width: 767px) {
	.responsive div .avia-data-table table,
	.responsive div .avia-data-table tbody,
	.responsive div .avia-data-table tr,
	.responsive div .avia-data-table td,
	.responsive div .avia-data-table th {
		display: block;
		border-top: none;
		border-right: none;
		border-left: none;
		text-align: center;
	}

	.responsive .avia-data-table {
		border-style: solid;
		border-width: 1px;
	}

	.responsive .avia-data-table .avia-pricing-row .avia-desc-col {
		text-align: center;
	}

	.responsive .avia-data-table .avia-button-row,
	.responsive .avia-data-table tr:first-child th {
		display: none;
	}

	.responsive .avia-data-table td:before {
		display: block;
		font-style: italic;
		font-size: 11px;
	}

	.responsive .avia-data-table td {
		position: relative;
	}
}

/*pricing table*/

.avia-pricing-table-container {
	position: relative;
	clear: both;
	width: 100%;
	display: table;
	table-layout: fixed;
}

.pricing-table-wrap {
	display: table-cell;
}

.pricing-table {
	margin: 10px;
}

.pricing-table > li {
	list-style-type: none;
	list-style-position: outside;
	padding: 9px 12px;
	border-top-style: solid;
	border-top-width: 1px;
	border-left-style: solid;
	border-left-width: 1px;
	border-right-style: solid;
	border-right-width: 1px;
	margin: 0;
	text-align: center;
	position: relative;
}

.pricing-table > li:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.pricing-table > li:first-child,
.pricing-extra {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-top-style: solid;
	border-top-width: 1px;
	border-left-style: solid;
	border-left-width: 1px;
	border-right-style: solid;
	border-right-width: 1px;
}

.pricing-extra {
	display: none;
}

.avia-desc-col.pricing-table li {
	border-style: dashed;
	border-left: none;
	border-right: none;
	text-align: right;
}

.avia-desc-col.pricing-table .avia-button-row {
	border: none;
}

.avia-center-col.pricing-table {
	text-align: center;
}

.pricing-table li.avia-pricing-row {
	text-align: center;
	font-size: 60px;
	line-height: 1em;
	padding: 25px 12px;
	/* text-shadow: 0 2px 0 #C9C9C9, 0 3px 0 #BBB, 0 3px 0 #B9B9B9, 0 1px 0 #AAA, 0 1px 1px rgba(0, 0, 0, .1), 0 0 3px rgba(0, 0, 0, .1), 0 4px 10px rgba(0, 0, 0, .2); */
	font-weight: 600;
}

.pricing-table li.avia-pricing-row small {
	display: block;
	font-size: 16px;
	font-style: italic;
	line-height: 1.4em;
	font-weight: normal;
	letter-spacing: 1px;
	text-shadow: none;
}

.pricing-table.avia-highlight-col .pricing-extra {
	display: block;
	position: absolute;
	top: -20px;
	height: 25px;
	left: -1px;
	right: -1px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.pricing-table li.empty-table-cell {
	display: none;
}

.pricing-table.avia-desc-col li.empty-table-cell {
	display: block;
}

.fallback-table-val {
	visibility: hidden;
}

.pricing-table.avia-highlight-col {
	box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
	margin: 4px;
}

.pricing-table.avia-highlight-col > li:last-child {
	padding-bottom: 25px;
}

.pricing-table.avia-highlight-col .first-table-item {
	position: relative;
	top: -9px;
	z-index: 10;
}

.pricing-table li.avia-pricing-row .currency-symbol {
	font-size: 0.5em;
	position: relative;
	top: -0.6em;
	line-height: 1em;
	text-shadow: none;
	font-weight: normal;
}

.pricing-table li.avia-pricing-row small,
.pricing-table li.avia-pricing-row .currency-symbol {
	opacity: 0.4;
	color: #000;
	filter: alpha(opacity=40);
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 767px) {
	.responsive .avia-pricing-table-container,
	.responsive .pricing-table-wrap {
		display: block;
	}

	.responsive .pricing-table {
		display: block;
		margin-bottom: 40px;
	}

	.responsive .pricing-table.avia-desc-col {
		display: none;
	}
}

/* ======================================================================================================================================================
Post Slider
====================================================================================================================================================== */
.avia-content-slider {
	margin: 30px 0;
	clear: both;
}

.avia-content-slider,
.avia-content-slider-inner {
	position: relative;
	overflow: hidden;
	clear: both;
}

.avia-content-slider .slide-entry-title {
	font-size: 14px;
	line-height: 1.4em;
	margin: 0;
}

.avia-content-slider .slide-image,
.avia-content-slider .slide-image img {
	overflow: hidden;
	border-radius: 2px;
	display: block;
	position: relative;
}

.avia-content-slider .slide-image {
	margin-bottom: 6px;
}

.avia-content-slider .slide-entry {
	overflow: hidden;
}

.avia-content-slider .slide-entry-wrap {
	width: 100%;
	float: left;
	margin-bottom: 40px;
}

.avia-content-slider-active .slide-entry-wrap {
	position: absolute;
	top: 0;
	visibility: hidden;
	margin: 0;
}

.avia-content-slider .slide-entry-wrap:first-child {
	position: relative;
}

.avia-content-slider .slide-meta div {
	display: inline-block;
	font-size: 12px;
}

.avia-content-slider .slide-meta a {
	position: relative;
}

.avia-content-slider .slide-meta {
	position: relative;
	padding-bottom: 10px;
}

.avia-content-slider .slide-meta-del {
	margin: 0px 4px;
}

.slider-fallback-image {
	visibility: hidden;
}

.fake-thumbnail .slide-image {
	border-width: 1px;
	border-style: solid;
}

.fallback-post-type-icon {
	position: absolute;
	top: 49%;
	left: 50%;
	margin: -28px 0 0 -30px;
	height: 60px;
	width: 60px;
	line-height: 59px;
	font-size: 25px;
	text-align: center;
	border-radius: 100px;
}

.avia-content-slider .slide-image:hover .fallback-post-type-icon {
	opacity: 0;
}

.pagination-slider .pagination {
	padding: 1px 0 10px 0;
}

.avia_desktop .avia-content-slider .avia-slideshow-arrows a {
	opacity: 0;
}

#top .avia-content-slider:hover .avia-slideshow-arrows a {
	opacity: 1;
}

#top .avia-content-slider .avia-slideshow-arrows a {
	top: 38%;
	margin: -30px 0 0;
}

#top .avia-content-slider,
#top .avia-content-slider-inner,
#top .avia-content-slider-inner .slide-entry-wrap {
	/*fixes flickering issue when slider moves. usually other elements in other container flicker */
	-webkit-transform-style: preserve-3d;
}

@media only screen and (max-width: 767px) {
	.responsive #top .slide-entry {
		width: 48%;
		margin-left: 4%;
	}

	.responsive #top .avia-content-slider-even .slide-entry.slide-parity-odd,
	.responsive #top .avia-content-slider-odd .slide-entry.slide-parity-even {
		margin: 0;
		clear: both;
	}

	.responsive #top .avia-content-slider-odd .slide-entry.first {
		margin-left: 0;
		width: 100%;
	}

	.responsive .pricing-table {
		display: block;
		margin-bottom: 40px;
	}
}

/* ======================================================================================================================================================
Portfolio/Post Grid
====================================================================================================================================================== */
.sort_width_container {
	margin: 30px 0;
}

.entry-content-wrapper .sort_width_container:first-child {
	margin-top: 0;
}

.sort_width_container,
#js_sort_items {
	overflow: hidden;
	position: relative;
	clear: both;
}

#js_sort_items {
	visibility: hidden;
	font-size: 12px;
}

#js_sort_items a {
	display: block;
	float: left;
	text-decoration: none;
}

#js_sort_items a small {
	display: none;
}

#js_sort_items .text-sep {
	float: left;
	padding: 0 6px;
}

.grid-sort-container {
	clear: both;
	position: relative;
	z-index: 20;
}

.avia_sortable_active .isotope,
.avia_sortable_active .isotope .isotope-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration: 0.6s;
	-moz-transition-duration: 0.6s;
	-o-transition-duration: 0.6s;
	transition-duration: 0.6s;
}

.avia_sortable_active .isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}

.avia_sortable_active .isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-o-transition-property: top, left, opacity;
	transition-property: transform, opacity;
}

#top .isotope.no-transition,
#top .isotope.no-transition .isotope-item,
#top .isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}

.isotope_activated .isotope-item {
	margin-left: 0;
}

#top .isotope-item {
	z-index: 2;
}

#top .isotope-hidden {
	z-index: 1;
}

/*1 column entry*/
#top .isotope-item.av_fullwidth {
	margin-bottom: 20px;
}

/*special layout for 1 column entry*/
#top .isotope-item.special_av_fullwidth .av_table_col.grid-content {
	width: 30%;
}

#top .isotope-item.special_av_fullwidth .av_table_col .entry-title {
	margin: 10px 0 20px;
}

#top .isotope-item.special_av_fullwidth .av_table_col .grid-entry-excerpt {
	font-size: 12px;
	font-style: normal;
}

#top .isotope-item.special_av_fullwidth .av_table_col .avia-arrow {
	z-index: 5;
	margin: -5px 0 0 0;
	top: 50%;
	right: -5px;
	left: auto;
}

/*single entries*/
.grid-entry {
	padding-bottom: 1px;
	overflow: visible;
}

.grid-entry .avia-arrow {
	border: none;
	top: -4px;
}

.grid-entry .inner-entry {
	margin-right: 1px;
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1); /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2); */
	position: relative;
}

.grid-image {
	display: block;
	width: 100%;
	position: relative;
}

.grid-image img {
	width: 100%;
	display: block;
}

.grid-content {
	padding: 20px;
	position: relative;
}

.grid-entry-title {
	font-size: 13px;
	margin: 0;
	padding: 0;
	font-weight: 500;
}

.grid-entry-excerpt {
	font-size: 12px;
	font-style: italic;
	margin-bottom: -2px;
}

.with-excerpt-container .grid-entry {
	text-align: center;
}

.grid-circle .inner-entry {
	margin: 0;
	border-radius: 999px;
	overflow: hidden;
}

.grid-circle .grid-content {
	padding: 16px 35px 45px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
}

.pagination-portfolio .pagination {
	padding: 20px 0;
}

/*within color section*/
.avia-section .pagination-portfolio .pagination {
	padding: 16px;
}

/* pseudo 'ajax' portfolio */
.portfolio_preview_container {
	position: relative;
	clear: both;
	width: 100%;
	overflow: hidden;
	display: none;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 17px 1px;
	margin-bottom: 25px;
}

.portfolio-details-inner {
	display: none;
	clear: both;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
}

.portfolio-preview-title {
	font-size: 22px;
}

.ajax_slide {
	display: block;
	clear: both;
	width: 100%;
	overflow: hidden;
	position: absolute;
	left: 120%;
	top: 0;
	z-index: 1;
}

.ajax_slide.open_slide {
	display: block;
	position: relative;
	left: 0;
	z-index: 2;
}

.ajax_slide .inner_slide {
	display: table;
	position: relative;
	table-layout: fixed;
}

.av_table_col {
	display: table-cell;
	vertical-align: top;
	position: relative;
}

.portfolio-preview-image {
	width: 66.6%;
}

.portfolio-preview-content {
	width: 33.4%;
	padding: 63px 30px 30px 30px;
}

.portfolio_preview_container .portfolio-details-inner .no_portfolio_preview_gallery {
	width: 100%;
}

div .portfolio-preview-image .avia-slideshow {
	margin: 0;
}

div .portfolio-preview-image .avia-slideshow img {
	border-radius: 0;
}

#top .portfolio-preview-image .avia-gallery {
	padding: 0;
	margin-bottom: -1px;
}

#top .portfolio-preview-image .avia-gallery .avia-gallery-big {
	border-top: none;
	border-left: none;
	border-right: none;
	position: relative;
	margin-bottom: -1px;
	padding: 0;
	border-radius: 0;
}

#top .portfolio-preview-image .avia-gallery-thumb {
	position: relative;
	margin-right: -1px;
}

#top .portfolio-preview-image .avia-gallery-thumb img {
	border-left: none;
}

#top .portolio-preview-list-image,
#top .portolio-preview-list-image img {
	display: block;
	position: relative;
	width: 100%;
}

#top .portolio-preview-list-image {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

div .portfolio-preview-image {
	border-right-style: solid;
	border-right-width: 1px;
}

div .portfolio-preview-image:last-child {
	border: none;
}

.portfolio-preview-content .avia-arrow {
	top: 50%;
	left: -1px;
	margin-top: -6px;
	border-right: none;
	border-top: none;
	z-index: 510;
}

.open_container,
.open_container .portfolio-details-inner {
	display: block;
	height: auto;
}

.ajax_controlls {
	position: absolute;
	z-index: 100;
	right: 0;
	top: 0;
}

.ajax_controlls a {
	text-decoration: none;
	line-height: 40px;
	width: 40px;
	height: 40px;
	display: block;
	float: left;
	margin-left: -1px;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-radius: 0px;
	text-align: center;
}

.avia_loading_icon {
	background: #000 url("../images/layout/preload-dark-big.gif") no-repeat center center;
	height: 60px;
	width: 60px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
	display: none;
	z-index: 10000;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/*deactivate gallery animation*/
.avia_transform .portfolio-preview-image .avia-gallery-thumb img {
	opacity: 1;
}

.avia_transform .portfolio-preview-image .avia-gallery-thumb img.avia_start_animation {
	-webkit-animation: none;
	-moz-animation: none;
	-o-animation: none;
	animation: none;
}

/*different portfolio sizes*/
.js_active .grid-image {
	opacity: 0;
}

.fullsize .grid-col-4 .grid-image {
	height: 161px;
}

.fullsize .grid-col-3 .grid-image {
	height: 249px;
}

@media only screen and (min-width: 1140px) {
	.responsive .fullsize .grid-col-4 .grid-image {
		height: 183px;
	}

	.responsive .fullsize .grid-col-3 .grid-image {
		height: 276px;
	}
}

@media only screen and (min-width: 1340px) {
	.responsive_large .fullsize .grid-col-4 .grid-image {
		height: 215px;
	}

	.responsive_large .fullsize .grid-col-3 .grid-image {
		height: 325px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 989px) {
	.responsive .fullsize .grid-col-4 .grid-image {
		height: 121px;
	}

	.responsive .fullsize .grid-col-3 .grid-image {
		height: 183px;
	}
}

@media only screen and (max-width: 767px) {
	.responsive .fullsize .grid-col-4 .grid-image,
	.responsive .fullsize .grid-col-3 .grid-image {
		height: auto;
	}

	.responsive .portfolio-preview-image,
	.responsive .portfolio-preview-content {
		display: block;
		width: 100%;
		border-right: none;
	}

	.responsive .portfolio-preview-content {
		border-top-style: solid;
		border-top-width: 1px;
	}

	.responsive .ajax_slide .inner_slide {
		display: block;
	}

	.responsive .ajax_slide .av_table_col {
		display: block;
		padding: 30px;
	}
}

/* ======================================================================================================================================================
Masonry Grid
====================================================================================================================================================== */

.av-masonry {
	position: relative;
	overflow: hidden;
}

.av-masonry-container {
	width: 100.4%;
	float: left;
	clear: both;
	position: relative;
}

.av-masonry-entry {
	position: relative;
	display: block;
	width: 24.9%;
	float: left;
	clear: none;
	text-decoration: none;
	visibility: hidden;
	opacity: 0;
}

.av-masonry-entry.av-landscape-img {
	width: 49.8%;
}

.av-masonry-outerimage-container {
	overflow: hidden;
}

.av-masonry-outerimage-container,
.av-masonry-image-container {
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.av-masonry-image-container {
	text-align: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
}

.av-masonry-image-container img {
	display: block;
	margin: 0 auto;
}

.av-masonry-entry .av-inner-masonry-content {
	padding: 20px;
	z-index: 2;
	position: relative;
	width: 100%;
}

.av-masonry-entry .avia-arrow {
	border: none;
	top: -5px;
}

.av-masonry-item-no-image .avia-arrow {
	display: none;
}

.av-masonry-entry .av-masonry-entry-title {
	line-height: 1.3em;
	margin: 0;
	font-size: 15px;
}

.av-masonry-entry .av-masonry-entry-title + .av-masonry-entry-content {
	padding-top: 4px;
}

.av-masonry-date {
	font-size: 11px;
}

/*items loaded*/

.av-masonry-entry.av-masonry-item-loaded {
	opacity: 1;
	visibility: visible;
}

/*gap variations between elements: no, 1px and large*/

.av-large-gap.av-masonry {
	padding: 15px 0 0 15px;
}

.av-no-gap.av-fixed-size .av-masonry-entry .av-inner-masonry {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.av-1px-gap.av-fixed-size .av-masonry-entry .av-inner-masonry {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 1px;
	bottom: 1px;
}

.av-large-gap.av-fixed-size .av-masonry-entry .av-inner-masonry {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 15px;
	bottom: 15px;
}

.av-no-gap.av-flex-size .av-masonry-entry .av-inner-masonry {
	position: relative;
}

.av-1px-gap.av-flex-size .av-masonry-entry .av-inner-masonry {
	position: relative;
	margin-right: 1px;
	margin-bottom: 1px;
}

.av-large-gap.av-flex-size .av-masonry-entry .av-inner-masonry {
	position: relative;
	margin-right: 15px;
	margin-bottom: 15px;
}

/*flex size*/

.av-flex-size .av-masonry-image-container {
	background-image: none !important;
}

/*fixed sized masonry (aka perfect grid)*/
.av-fixed-size .av-masonry-image-container,
.av-fixed-size .av-masonry-outerimage-container {
	position: absolute;
}

.av-fixed-size .av-masonry-image-container img {
	display: none;
}

.av-fixed-size .av-masonry-entry .av-inner-masonry-sizer {
	width: 100%;
	padding-bottom: 80%;
	z-index: 1;
}

.av-fixed-size .av-masonry-entry.av-landscape-img .av-inner-masonry-sizer {
	padding-bottom: 40%;
}

.av-fixed-size .av-masonry-entry.av-portrait-img .av-inner-masonry-sizer {
	padding-bottom: 160%;
}

.av-fixed-size .av-masonry-entry.av-portrait-img.av-landscape-img .av-inner-masonry-sizer {
	padding-bottom: 80%;
}

#top .av-fixed-size .av-masonry-entry.av-masonry-item-no-image .av-inner-masonry-content {
	display: table;
	position: relative;
	height: 100%;
	width: 100%;
}

#top .av-fixed-size .av-masonry-entry.av-masonry-item-no-image .av-inner-masonry-content-pos {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.av-fixed-size .av-masonry-entry .av-inner-masonry-content,
.av-caption-on-hover .av-masonry-item-with-image.av-masonry-entry .av-inner-masonry-content {
	position: absolute;
	bottom: 0;
}

/*hover effect*/
#top .av-masonry-entry:hover {
	text-decoration: none;
}

.av-masonry-image-container,
.av-inner-masonry-content,
.av-masonry-pagination {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.avia_desktop .av-hover-overlay-active .av-masonry-image-container {
	opacity: 0.7;
}

.avia_desktop .av-hover-overlay-active .av-masonry-entry:hover .av-masonry-image-container {
	opacity: 1;
}

.avia_desktop .av-masonry-entry:hover .av-masonry-image-container {
	-webkit-transform: scale(1.05, 1.05);
	-moz-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.avia_desktop .av-caption-on-hover .av-masonry-item-with-image .av-inner-masonry-content {
	opacity: 0;
	filter: alpha(opacity=00);
}

.avia_desktop
	.av-caption-on-hover
	.av-masonry-item-with-image.av-masonry-entry:hover
	.av-inner-masonry-content {
	opacity: 1;
	filter: alpha(opacity=100);
}

.avia_desktop .av-inner-masonry-content {
	/*fixes flickering issue when caption/excerpt is visible by default and sorting is activated . may also try to replace it with -webkit-transform-style: preserve-3d;*/
	-webkit-backface-visibility: hidden;
}

.avia_desktop.csstransforms3d
	.av-caption-on-hover
	.av-masonry-entry.av-masonry-item-with-image
	.av-inner-masonry-content {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: center bottom;
	-moz-transform-origin: center bottom;
	transform-origin: center bottom;
	-webkit-transform: rotateX(120deg);
	-moz-transform: rotateX(120deg);
	transform: rotateX(120deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.avia_desktop.csstransforms3d
	.av-caption-on-hover
	.av-masonry-entry.av-masonry-item-with-image:hover
	.av-inner-masonry-content {
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.avia_desktop.csstransforms3d .av-masonry-entry:hover .av-icon-display {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	transform: rotateY(180deg);
	border-color: transparent;
}

/*need to make sure that the scale transform doesnt mess up the image*/
.avia_desktop.csstransforms3d .av-masonry-image-container {
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
}

/*masonry sorting*/

.av-masonry-sort {
	text-align: center;
	padding: 15px;
	visibility: hidden;
}

.av-masonry-sort a {
	text-decoration: none;
}

.av-sort-by-term > * {
	display: inline-block;
}

.av-no-gap .av-masonry-sort,
.av-1px-gap .av-masonry-sort {
	margin: 0 0 1px 0;
}

.av-large-gap .av-masonry-sort {
	margin: 0 15px 15px 0;
}

.avia-term-count {
	display: none;
}

/*masonry pagination*/
.av-masonry-pagination {
	clear: both;
	z-index: 2;
	bottom: 20px;
	right: 20px;
	padding: 20px;
	display: block;
}

#top .av-masonry-load-more {
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: 13px;
	visibility: hidden;
	opacity: 0;
	overflow: hidden;
}

#top .av-masonry-load-more.av-masonry-no-more-items {
	opacity: 0 !important;
	height: 0px;
	padding: 0px;
	filter: alpha(opacity=00);
}

#top .av-masonry-load-more:hover {
	letter-spacing: 0px;
}

.av-no-gap .av-masonry-load-more {
	margin: 1px 0 0 0;
	float: left;
	width: 100%;
}

.av-large-gap .av-masonry-pagination {
	margin: 0 15px 15px 0;
}

.av-masonry-pagination .pagination {
	padding: 5px;
}

.av-masonry-pagination .pagination-meta {
	line-height: 30px;
}

/*masonry post types and variations*/
/*product*/
.av-masonry-entry.sale .av-masonry-entry-title {
	padding-right: 48px;
}

#top .av-masonry-entry.type-product .av-masonry-image-container {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
}

#top .av-masonry-entry .price,
#top .av-masonry-entry .price span,
#top .av-masonry-entry del,
#top .av-masonry-entry ins {
	font-size: 14px;
}

#top .av-masonry-entry .onsale {
	top: 20px;
	right: 20px;
}

#top .av-masonry-entry .av-inner-masonry .av-masonry-overlay {
	opacity: 0;
	filter: alpha(opacity=0);
	width: 100%;
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#top .av-masonry-entry:hover .av-inner-masonry .av-masonry-overlay {
	opacity: 1;
	filter: alpha(opacity=100);
}

/*post types*/
#top .av-icon-display.av-masonry-media {
	position: absolute;
	left: 50%;
	margin-left: -30px;
	top: -75px;
	opacity: 0.7;
	filter: alpha(opacity=70);
}

#top .av-masonry-entry:hover .av-icon-display.av-masonry-media {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	transform: rotateY(0deg);
}

#top .av-masonry-entry.format-quote .av-icon-display {
	position: absolute;
	z-index: -1;
	border: none;
	background: rgba(0, 0, 0, 0);
	opacity: 0.1;
	filter: alpha(opacity=10);
	bottom: -20px;
	left: 0;
	font-size: 50px;
	line-height: 100px;
	width: 100px;
}

#top div .av-masonry-entry.format-quote:hover .av-icon-display {
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	transform: rotateY(0deg);
	opacity: 0.3;
	filter: alpha(opacity=30);
}

@media only screen and (min-width: 1800px) {
	.responsive.html_stretched .av-masonry-entry {
		width: 16.6%;
	}

	.responsive.html_stretched .av-masonry-entry.av-landscape-img {
		width: 33.2%;
	}
}

@media only screen and (min-width: 989px) and (max-width: 1340px) {
	.responsive .av-masonry-entry {
		width: 33.3%;
	}

	.responsive .av-masonry-entry.av-landscape-img {
		width: 66.6%;
	}
}

@media only screen and (max-width: 767px) {
	.responsive .av-masonry-entry {
		width: 100%;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.responsive .av-masonry-gallery .av-masonry-entry {
		width: 49.9%;
	}
}

@media only screen and (min-width: 767px) and (max-width: 989px) {
	.responsive .av-masonry-gallery .av-masonry-entry {
		width: 33.3%;
	}

	.responsive .av-masonry-gallery .av-masonry-entry.av-landscape-img {
		width: 66.6%;
	}
}

@media only screen and (min-width: 767px) and (max-width: 989px) {
	.responsive .av-masonry-entry {
		width: 49.9%;
	}

	.responsive .av-masonry-entry.av-landscape-img {
		width: 100%;
	}
}

/* ======================================================================================================================================================
Team Member
====================================================================================================================================================== */
.avia-team-member {
	margin: 30px 0;
	clear: both;
}

.team-member-name {
	margin-top: 7px;
}

.team-member-job-title {
	margin-top: -7px;
	font-size: 13px;
}

.team-social a {
	text-decoration: none;
	position: relative;
	border-radius: 30px;
	padding: 0 16px;
	background: #fff;
	margin: 3px;
	display: inline-block;
	height: 53px;
	line-height: 54px;
	width: 53px;
}

.team-img-container {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	text-align: center;
}

.team-img-container img {
	display: block;
	margin: 0 auto;
}

.avia-team-member .team-social {
	border: 7px solid rgba(0, 0, 0, 0.1);
	position: absolute;
	font-size: 20px;
	background: #fff;
	background: rgba(255, 255, 255, 0.7);
	opacity: 0;
	visibility: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	border-radius: 3px;
}

.team-social-inner {
	width: 100%;
	padding: 0 5%;
	position: absolute;
	top: 50%;
	margin-top: -26px;
	left: 0;
}

.team-img-container:hover .team-social {
	visibility: visible;
	opacity: 1;
}

.avia-tooltip {
	position: absolute;
	z-index: 9999999;
	padding: 12px;
	margin-top: -18px;
	margin-left: -4px;
	background: #000;
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	width: 140px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	font-size: 12px;
	text-align: center;
}

.avia-tooltip ul,
.avia-tooltip ol,
.avia-tooltip blockquote {
	text-align: left;
	list-style-position: inside;
}

.avia-tooltip .avia-arrow {
	background: rgba(0, 0, 0, 0.8);
	border: none;
}

.avia-tooltip .avia-arrow-wrap {
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: -20px;
	left: 50%;
	margin-left: -7px;
	overflow: hidden;
}

.avia-tooltip .inner_tooltip br:first-child {
	display: none;
}

.avia-team-member .plus-google:hover {
	color: #de5a49;
}

.avia-team-member .rss:hover {
	color: #ffa133;
}

.avia-team-member .facebook:hover {
	color: #37589b;
}

.avia-team-member .twitter:hover {
	color: #46d4fe;
}

.avia-team-member .mail:hover {
	color: #9fae37;
}

.avia-team-member .dribbble:hover {
	color: #e44885;
}

.avia-team-member .linkedin:hover {
	color: #419cca;
}

.avia-team-member .search:hover {
	color: #222222;
}

.avia-team-member .behance:hover {
	color: #008cfa;
}

.avia-team-member .flickr:hover {
	color: #ff0086;
}

.avia-team-member .forrst:hover {
	color: #234317;
}

.avia-team-member .myspace:hover {
	color: #000000;
}

.avia-team-member .tumblr:hover {
	color: #345574;
}

.avia-team-member .vimeo:hover {
	color: #31baff;
}

.avia-team-member .youtube:hover {
	color: #a72b1d;
}

.avia-team-member .pinterest:hover {
	color: #cb2027;
}

.avia-team-member .skype:hover {
	color: #12a5f4;
}

.avia-team-member .five_100_px:hover {
	color: #222222;
}

.avia-team-member .soundcloud:hover {
	color: #f76700;
}

.avia-team-member .xing:hover {
	color: #006567;
}

/*mobile from super small to tablet*/
@media only screen and (max-width: 989px) {
	.responsive .team-social a {
		padding: 0 8px;
		background: #fff;
		margin: 3px;
		margin-top: 11px;
		display: inline-block;
		height: 33px;
		line-height: 32px;
		width: 33px;
		border-style: solid;
		border-width: 1px;
	}

	.responsive #top .avia-team-member .team-img-container img {
		position: relative;
		z-index: 3;
	}

	.responsive #top .avia-team-member .team-social {
		border: 1px solid rgba(0, 0, 0, 0.1);
		position: relative;
		font-size: 20px;
		background: #fff;
		background: rgba(0, 0, 0, 0.03);
		opacity: 1;
		visibility: visible;
		top: auto;
		left: 0;
		right: 0;
		bottom: 5px;
		height: 60px;
		text-align: center;
		border-radius: 3px;
		border-top-right-radius: 0px;
		border-top-left-radius: 0px;
		font-size: 15px;
		z-index: 1;
	}
}

/* ======================================================================================================================================================
Sidebar
====================================================================================================================================================== */

.avia-builder-widget-area {
	position: relative;
}

.flex_column .widget:first-child,
.content .widget:first-child {
	padding-top: 0;
	border-top: none;
}

.flex_column .widget .widgettitle,
.content .widget .widgettitle {
	margin-top: 0.85em;
}

/* ======================================================================================================================================================
Testimonials
====================================================================================================================================================== */

.avia-testimonial {
	border-right-style: dashed;
	border-right-width: 1px;
	border-bottom-style: dashed;
	border-bottom-width: 1px;
	padding: 20px;
}

.avia-first-testimonial {
	clear: both;
}

.avia-testimonial-wrapper .avia-testimonial-row:last-child .avia-testimonial {
	border-bottom: none;
}

.avia-testimonial.avia-last-testimonial,
.avia-grid-1-testimonials .avia-testimonial {
	border-right: none;
}

.avia-testimonial-image img {
	border-radius: 100px;
}

.avia-testimonial-image {
	float: left;
	margin: 0 20px 0 0;
	width: 80px;
	height: 80px;
	border-radius: 100px;
	overflow: hidden;
}

.avia-testimonial-content {
	overflow: hidden;
}

.avia-testimonial-content p:first-child {
	margin-top: 0;
}

.avia-testimonial-meta {
	position: relative;
	overflow: hidden;
	margin-left: 100px;
}

.avia-testimonial-meta-mini {
	overflow: hidden;
}

.avia-testimonial-name {
	display: block;
}

.avia-testimonial-arrow-wrap {
	display: none;
}

.avia-testimonial-wrapper {
	display: table;
	margin: 30px 0;
	clear: both;
}

.avia-testimonial-row {
	display: table-row;
}

body div .avia-testimonial {
	display: table-cell;
	float: none;
}

.avia_transform .avia-testimonial .avia-testimonial-image {
	opacity: 0.2;
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
}

.avia_transform .avia_start_animation.avia-testimonial .avia-testimonial-image {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/*slider style*/
.avia-slider-testimonials.avia-testimonial-wrapper {
	display: block;
	clear: both;
	position: relative;
}

.avia-slider-testimonials.avia-testimonial-wrapper .avia-testimonial-row {
	display: block;
	width: 100%;
	position: relative;
}

.avia-slider-testimonials.avia-testimonial-wrapper .avia-testimonial {
	visibility: hidden;
	border: none;
	width: 100%;
	float: none;
	padding: 0;
	position: absolute;
	top: 0;
}

.js_active .avia-slider-testimonials.avia-testimonial-wrapper .avia-testimonial {
	opacity: 0;
}

.avia-slider-testimonials.avia-testimonial-wrapper .avia-testimonial:first-child {
	display: block;
	visibility: visible;
	position: relative;
}

.avia-slider-testimonials .avia-testimonial-meta {
	margin: 13px 0 0 20px;
	overflow: visible;
	font-size: 12px;
}

.avia-slider-testimonials .avia-testimonial-meta .avia-testimonial-image {
	margin: 0 10px 0 0;
	width: 40px;
	height: 40px;
}

.avia-slider-testimonials .avia-testimonial-content {
	padding: 15px;
	border-style: solid;
	border-width: 1px;
	border-radius: 2px;
}

.avia-slider-testimonials .avia-testimonial-content p:last-child {
	margin-bottom: 0;
}

.avia-slider-testimonials .avia-testimonial-arrow-wrap {
	display: block;
	position: absolute;
	top: -14px;
	left: 14px;
	overflow: hidden;
	width: 10px;
	height: 10px;
}

.avia-slider-testimonials .avia-testimonial-name {
	font-size: 14px;
}

@media only screen and (max-width: 767px) {
	.responsive .avia-testimonial-wrapper,
	.responsive .avia-testimonial-row,
	.responsive .avia-testimonial {
		display: block;
		width: 100%;
		float: none;
	}

	.responsive .avia-testimonial {
		padding: 20px 0;
		border-right: none;
		margin: 0;
	}

	.responsive #top .avia-testimonial {
		border-bottom-style: dashed;
		border-bottom-width: 1px;
	}

	.responsive #top .avia-testimonial-row:last-child .avia-testimonial:last-child {
		border: none;
	}

	.responsive #top .avia-slider-testimonials .avia-testimonial {
		border: none;
	}
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
	.responsive .avia-testimonial-image {
		margin: 0;
		width: 100%;
		float: none;
		text-align: center;
		margin-bottom: 15px;
		height: auto;
	}

	.responsive .avia-grid-testimonials .avia-testimonial-meta {
		margin-left: 0;
	}
}

/* ======================================================================================================================================================
Progress Bar
====================================================================================================================================================== */
.avia-progress-bar-container {
	margin: 15px 0;
	clear: both;
}

.avia-progress-bar {
	position: relative;
	margin-bottom: 4px;
}

.avia-progress-bar .progressbar-title-wrap {
	position: absolute;
	z-index: 2;
	bottom: 3px;
	left: 3px;
	color: white;
	background: black;
	background: rgba(0, 0, 0, 0.5);
	padding: 1px 10px 2px 10px;
	border-radius: 3px;
}

.progressbar-icon {
	float: left;
	margin-right: 5px;
}

.icon-bar-no .progressbar-icon {
	display: none;
}

.progressbar-title {
	float: left;
}

.avia-progress-bar .progress {
	width: 100%;
	height: 30px;
	line-height: 30px;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}

.avia-progress-bar div.progress .bar-outer,
.avia-progress-bar div.progress .bar {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-size: 32px 32px;
	border-radius: 3px;
}

.avia-progress-bar div.progress .bar {
	-webkit-animation: avia-bg-move 1.2s linear infinite; /* Safari 4+ */
	-moz-animation: avia-bg-move 1.2s linear infinite; /* Fx 5+ */
	-o-animation: avia-bg-move 1.2s linear infinite; /* Opera 12+ */
	animation: avia-bg-move 1.2s linear infinite; /* IE 10+ */
	height: 100%;
}

.avia-progress-bar div.progress {
	clear: both;
}

.avia_transform div.progress .bar-outer {
	width: 0;
}

.avia_transform .avia-progress-bar div.progress.avia_start_animation .bar-outer {
	width: 100%;
}

.grey-bar .bar {
	background: #555;
	background-image: -webkit-linear-gradient(
		-45deg,
		#666 25%,
		#444 25%,
		#444 50%,
		#666 50%,
		#666 75%,
		#444 75%,
		#444
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#666 25%,
		#444 25%,
		#444 50%,
		#666 50%,
		#666 75%,
		#444 75%,
		#444
	);
	background-image: -o-linear-gradient(
		-45deg,
		#666 25%,
		#444 25%,
		#444 50%,
		#666 50%,
		#666 75%,
		#444 75%,
		#444
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#666 25%,
		#444 25%,
		#444 50%,
		#666 50%,
		#666 75%,
		#444 75%,
		#444
	);
	background-image: linear-gradient(
		-45deg,
		#666 25%,
		#444 25%,
		#444 50%,
		#666 50%,
		#666 75%,
		#444 75%,
		#444
	);
}

.blue-bar .bar {
	background: #7bb0e7;
	background-image: -webkit-linear-gradient(
		-45deg,
		#96c8fb 25%,
		#74a6d9 25%,
		#74a6d9 50%,
		#96c8fb 50%,
		#96c8fb 75%,
		#74a6d9 75%,
		#74a6d9
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#96c8fb 25%,
		#74a6d9 25%,
		#74a6d9 50%,
		#96c8fb 50%,
		#96c8fb 75%,
		#74a6d9 75%,
		#74a6d9
	);
	background-image: -o-linear-gradient(
		-45deg,
		#96c8fb 25%,
		#74a6d9 25%,
		#74a6d9 50%,
		#96c8fb 50%,
		#96c8fb 75%,
		#74a6d9 75%,
		#74a6d9
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#96c8fb 25%,
		#74a6d9 25%,
		#74a6d9 50%,
		#96c8fb 50%,
		#96c8fb 75%,
		#74a6d9 75%,
		#74a6d9
	);
	background-image: linear-gradient(
		-45deg,
		#96c8fb 25%,
		#74a6d9 25%,
		#74a6d9 50%,
		#96c8fb 50%,
		#96c8fb 75%,
		#74a6d9 75%,
		#74a6d9
	);
}

.green-bar .bar {
	background: #555;
	background-image: -webkit-linear-gradient(
		-45deg,
		#a5ca68 25%,
		#83a846 25%,
		#83a846 50%,
		#a5ca68 50%,
		#a5ca68 75%,
		#83a846 75%,
		#83a846
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#a5ca68 25%,
		#83a846 25%,
		#83a846 50%,
		#a5ca68 50%,
		#a5ca68 75%,
		#83a846 75%,
		#83a846
	);
	background-image: -o-linear-gradient(
		-45deg,
		#a5ca68 25%,
		#83a846 25%,
		#83a846 50%,
		#a5ca68 50%,
		#a5ca68 75%,
		#83a846 75%,
		#83a846
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#a5ca68 25%,
		#83a846 25%,
		#83a846 50%,
		#a5ca68 50%,
		#a5ca68 75%,
		#83a846 75%,
		#83a846
	);
	background-image: linear-gradient(
		-45deg,
		#a5ca68 25%,
		#83a846 25%,
		#83a846 50%,
		#a5ca68 50%,
		#a5ca68 75%,
		#83a846 75%,
		#83a846
	);
}

.orange-bar .bar {
	background: #edae44;
	background-image: -webkit-linear-gradient(
		-45deg,
		#edae44 25%,
		#ffcf66 25%,
		#ffcf66 50%,
		#edae44 50%,
		#edae44 75%,
		#ffcf66 75%,
		#ffcf66
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#edae44 25%,
		#ffcf66 25%,
		#ffcf66 50%,
		#edae44 50%,
		#edae44 75%,
		#ffcf66 75%,
		#ffcf66
	);
	background-image: -o-linear-gradient(
		-45deg,
		#edae44 25%,
		#ffcf66 25%,
		#ffcf66 50%,
		#edae44 50%,
		#edae44 75%,
		#ffcf66 75%,
		#ffcf66
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#edae44 25%,
		#ffcf66 25%,
		#ffcf66 50%,
		#edae44 50%,
		#edae44 75%,
		#ffcf66 75%,
		#ffcf66
	);
	background-image: linear-gradient(
		-45deg,
		#edae44 25%,
		#ffcf66 25%,
		#ffcf66 50%,
		#edae44 50%,
		#edae44 75%,
		#ffcf66 75%,
		#ffcf66
	);
}

.black-bar .bar {
	background: #222;
	background-image: -webkit-linear-gradient(
		-45deg,
		#222 25%,
		#444 25%,
		#444 50%,
		#222 50%,
		#222 75%,
		#444 75%,
		#444
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#222 25%,
		#444 25%,
		#444 50%,
		#222 50%,
		#222 75%,
		#444 75%,
		#444
	);
	background-image: -o-linear-gradient(
		-45deg,
		#222 25%,
		#444 25%,
		#444 50%,
		#222 50%,
		#222 75%,
		#444 75%,
		#444
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#222 25%,
		#444 25%,
		#444 50%,
		#222 50%,
		#222 75%,
		#444 75%,
		#444
	);
	background-image: linear-gradient(
		-45deg,
		#222 25%,
		#444 25%,
		#444 50%,
		#222 50%,
		#222 75%,
		#444 75%,
		#444
	);
}

.red-bar .bar {
	background: #b02b2c;
	background-image: -webkit-linear-gradient(
		-45deg,
		#b02b2c 25%,
		#d24d4e 25%,
		#d24d4e 50%,
		#b02b2c 50%,
		#b02b2c 75%,
		#d24d4e 75%,
		#d24d4e
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#b02b2c 25%,
		#d24d4e 25%,
		#d24d4e 50%,
		#b02b2c 50%,
		#b02b2c 75%,
		#d24d4e 75%,
		#d24d4e
	);
	background-image: -o-linear-gradient(
		-45deg,
		#b02b2c 25%,
		#d24d4e 25%,
		#d24d4e 50%,
		#b02b2c 50%,
		#b02b2c 75%,
		#d24d4e 75%,
		#d24d4e
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#b02b2c 25%,
		#d24d4e 25%,
		#d24d4e 50%,
		#b02b2c 50%,
		#b02b2c 75%,
		#d24d4e 75%,
		#d24d4e
	);
	background-image: linear-gradient(
		-45deg,
		#b02b2c 25%,
		#d24d4e 25%,
		#d24d4e 50%,
		#b02b2c 50%,
		#b02b2c 75%,
		#d24d4e 75%,
		#d24d4e
	);
}

.teal-bar .bar {
	background: #5f8789;
	background-image: -webkit-linear-gradient(
		-45deg,
		#5f8789 25%,
		#7fa9ab 25%,
		#7fa9ab 50%,
		#5f8789 50%,
		#5f8789 75%,
		#7fa9ab 75%,
		#7fa9ab
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#5f8789 25%,
		#7fa9ab 25%,
		#7fa9ab 50%,
		#5f8789 50%,
		#5f8789 75%,
		#7fa9ab 75%,
		#7fa9ab
	);
	background-image: -o-linear-gradient(
		-45deg,
		#5f8789 25%,
		#7fa9ab 25%,
		#7fa9ab 50%,
		#5f8789 50%,
		#5f8789 75%,
		#7fa9ab 75%,
		#7fa9ab
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#5f8789 25%,
		#7fa9ab 25%,
		#7fa9ab 50%,
		#5f8789 50%,
		#5f8789 75%,
		#7fa9ab 75%,
		#7fa9ab
	);
	background-image: linear-gradient(
		-45deg,
		#5f8789 25%,
		#7fa9ab 25%,
		#7fa9ab 50%,
		#5f8789 50%,
		#5f8789 75%,
		#7fa9ab 75%,
		#7fa9ab
	);
}

.aqua-bar .bar {
	background: #4ecac2;
	background-image: -webkit-linear-gradient(
		-45deg,
		#4ecac2 25%,
		#6fece4 25%,
		#6fece4 50%,
		#4ecac2 50%,
		#4ecac2 75%,
		#6fece4 75%,
		#6fece4
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#4ecac2 25%,
		#6fece4 25%,
		#6fece4 50%,
		#4ecac2 50%,
		#4ecac2 75%,
		#6fece4 75%,
		#6fece4
	);
	background-image: -o-linear-gradient(
		-45deg,
		#4ecac2 25%,
		#6fece4 25%,
		#6fece4 50%,
		#4ecac2 50%,
		#4ecac2 75%,
		#6fece4 75%,
		#6fece4
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#4ecac2 25%,
		#6fece4 25%,
		#6fece4 50%,
		#4ecac2 50%,
		#4ecac2 75%,
		#6fece4 75%,
		#6fece4
	);
	background-image: linear-gradient(
		-45deg,
		#4ecac2 25%,
		#6fece4 25%,
		#6fece4 50%,
		#4ecac2 50%,
		#4ecac2 75%,
		#6fece4 75%,
		#6fece4
	);
}

.purple-bar .bar {
	background: #745f7e;
	background-image: -webkit-linear-gradient(
		-45deg,
		#745f7e 25%,
		#967f9f 25%,
		#967f9f 50%,
		#745f7e 50%,
		#745f7e 75%,
		#967f9f 75%,
		#967f9f
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#745f7e 25%,
		#967f9f 25%,
		#967f9f 50%,
		#745f7e 50%,
		#745f7e 75%,
		#967f9f 75%,
		#967f9f
	);
	background-image: -o-linear-gradient(
		-45deg,
		#745f7e 25%,
		#967f9f 25%,
		#967f9f 50%,
		#745f7e 50%,
		#745f7e 75%,
		#967f9f 75%,
		#967f9f
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#745f7e 25%,
		#967f9f 25%,
		#967f9f 50%,
		#745f7e 50%,
		#745f7e 75%,
		#967f9f 75%,
		#967f9f
	);
	background-image: linear-gradient(
		-45deg,
		#745f7e 25%,
		#967f9f 25%,
		#967f9f 50%,
		#745f7e 50%,
		#745f7e 75%,
		#967f9f 75%,
		#967f9f
	);
}

.pink-bar .bar {
	background: #d65799;
	background-image: -webkit-linear-gradient(
		-45deg,
		#d65799 25%,
		#f879bb 25%,
		#f879bb 50%,
		#d65799 50%,
		#d65799 75%,
		#f879bb 75%,
		#f879bb
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#d65799 25%,
		#f879bb 25%,
		#f879bb 50%,
		#d65799 50%,
		#d65799 75%,
		#f879bb 75%,
		#f879bb
	);
	background-image: -o-linear-gradient(
		-45deg,
		#d65799 25%,
		#f879bb 25%,
		#f879bb 50%,
		#d65799 50%,
		#d65799 75%,
		#f879bb 75%,
		#f879bb
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#d65799 25%,
		#f879bb 25%,
		#f879bb 50%,
		#d65799 50%,
		#d65799 75%,
		#f879bb 75%,
		#f879bb
	);
	background-image: linear-gradient(
		-45deg,
		#d65799 25%,
		#f879bb 25%,
		#f879bb 50%,
		#d65799 50%,
		#d65799 75%,
		#f879bb 75%,
		#f879bb
	);
}

.silver-bar .bar {
	background: #d7d7d7;
	background-image: -webkit-linear-gradient(
		-45deg,
		#d7d7d7 25%,
		#f9f9f9 25%,
		#f9f9f9 50%,
		#d7d7d7 50%,
		#d7d7d7 75%,
		#f9f9f9 75%,
		#f9f9f9
	);
	background-image: -moz-linear-gradient(
		-45deg,
		#d7d7d7 25%,
		#f9f9f9 25%,
		#f9f9f9 50%,
		#d7d7d7 50%,
		#d7d7d7 75%,
		#f9f9f9 75%,
		#f9f9f9
	);
	background-image: -o-linear-gradient(
		-45deg,
		#d7d7d7 25%,
		#f9f9f9 25%,
		#f9f9f9 50%,
		#d7d7d7 50%,
		#d7d7d7 75%,
		#f9f9f9 75%,
		#f9f9f9
	);
	background-image: -ms-linear-gradient(
		-45deg,
		#d7d7d7 25%,
		#f9f9f9 25%,
		#f9f9f9 50%,
		#d7d7d7 50%,
		#d7d7d7 75%,
		#f9f9f9 75%,
		#f9f9f9
	);
	background-image: linear-gradient(
		-45deg,
		#d7d7d7 25%,
		#f9f9f9 25%,
		#f9f9f9 50%,
		#d7d7d7 50%,
		#d7d7d7 75%,
		#f9f9f9 75%,
		#f9f9f9
	);
}

/* ======================================================================================================================================================
Image
====================================================================================================================================================== */
.avia_image,
.avia_image img {
	border-radius: 3px;
	display: block;
	position: relative;
}

.avia_image.avia-align-center {
	display: block;
	margin: 0 auto 10px auto;
	text-align: center;
}

.avia_image.avia-align-center img {
	margin: 0 auto;
	display: inline-block;
}

.avia_image.avia-align-left {
	display: block;
	float: left;
	margin-right: 15px;
	margin-top: 5px;
}

.avia_image.avia-align-right {
	display: block;
	float: right;
	margin-left: 15px;
	margin-top: 5px;
}

.avia_image + br,
.avia_image img + br {
	display: none;
}

.avia-align-center.avia_image.avia-builder-el-no-sibling {
	margin-bottom: 0;
	margin-top: 0;
}

.avia_transform .avia_animated_image.avia_image {
	opacity: 0;
}

.avia_transform .avia_start_animation.top-to-bottom.avia_image {
	opacity: 1;
}

.avia_transform .avia_start_animation.bottom-to-top.avia_image {
	opacity: 1;
}

.avia_transform .avia_start_animation.left-to-right.avia_image {
	opacity: 1;
}

.avia_transform .avia_start_animation.right-to-left.avia_image {
	opacity: 1;
}

/* ======================================================================================================================================================
Video
====================================================================================================================================================== */
.avia-video,
.avia-iframe-wrap {
	clear: both;
	position: relative;
	margin-bottom: 20px;
}

/*responsive iframe trick*/
.avia-video iframe,
.js_active .avia-iframe-wrap iframe,
div .avia-video .avia-iframe-wrap {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0;
}

.avia-video-custom {
	height: 0;
}

.avia-video-16-9,
.js_active .avia-iframe-wrap {
	padding-bottom: 56.25%;
	height: 0;
}

.avia-video-4-3 {
	padding-bottom: 75%;
	height: 0;
}

/*html 5 video - always has 100 width and natural height. behaves like image when it comes to aspect ratio*/

video {
	width: 100%;
	height: auto;
}

div .avia-video .mejs-container,
div .avia-video .mejs-container video {
	height: 100% !important;
	position: absolute;
}

/* ======================================================================================================================================================
Gallery
====================================================================================================================================================== */
#top div .avia-gallery {
	overflow: hidden;
	padding-bottom: 2px;
	clear: both;
}

#top div .avia-gallery img {
	float: left;
	border-style: solid;
	border-width: 1px;
	padding: 7px;
	width: 100%;
	border-radius: 0;
}

#top div .avia-gallery .avia-gallery-big {
	display: block;
	overflow: hidden;
	padding: 7px;
	margin-bottom: -1px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	border-style: solid;
	border-width: 1px;
}

#top div .avia-gallery .avia-gallery-big-inner {
	display: block;
	overflow: hidden;
	height: 100%;
}

#top div .avia-gallery .avia-gallery-big img {
	padding: 0;
	border: none;
}

#top .avia-gallery .avia-gallery-thumb a {
	float: left;
	width: 20%;
	opacity: 1;
	display: block;
}

#top .avia-gallery .avia-gallery-thumb a:hover {
	opacity: 0.5;
}

#top .avia-gallery .avia-gallery-caption {
	display: none;
}

#top div .avia-gallery .avia-gallery-big-no-crop-thumb {
	text-align: center;
}

#top div .avia-gallery .avia-gallery-big-no-crop-thumb img {
	clear: both;
	float: none;
	text-align: center;
}

/*gallery animation*/
.avia_transform .avia-gallery-thumb img {
	opacity: 0.1;
}

.avia_transform .avia-gallery-thumb img.avia_start_animation {
	opacity: 1;
}

@media only screen and (max-width: 767px) {
	.responsive .avia-gallery-thumb img {
		padding: 3px;
	}
}

#top .avia-slideshow-arrows a.next-slide {
	right: 0;
}

.avia-slideshow-arrows a:before {
	visibility: visible;
	display: block;
	position: absolute;
	z-index: 100;
	background: #aaa;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 3px;
	text-align: center;
	line-height: 62px;
	color: #fff;
}

.prev-slide:before {
	text-indent: -2px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.next-slide:before {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	text-indent: 0px;
}

.avia-slideshow-dots {
	position: relative;
	z-index: 200;
	width: 100%;
	bottom: 0;
	text-align: center;
	left: 0;
	height: 0px;
}

.avia-slideshow-dots a {
	display: inline-block;
	height: 13.5px;
	width: 13.5px;
	border-radius: 14px;
	background: #000;
	opacity: 0.6;
	filter: alpha(opacity=60);
	text-indent: 300%;
	overflow: hidden;
	margin: 0 1px;
	padding: 7px;
	position: relative;
	bottom: 33px;
}

.avia-slideshow-dots a.active,
.avia-slideshow-dots a:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
	background: #fff;
}

.avia-caption {
	position: absolute;
	z-index: 10;
	bottom: 17px;
	left: 17px;
	padding: 10px 20px;
	color: #fff;
	border-radius: 3px;
}

.avia-slideshow .avia-caption .avia-caption-title {
	color: #fff;
}

.js_active .avia-slideshow li:first-child {
	visibility: hidden;
}

/* hide controlls on desktop */
.avia_desktop .av_slideshow.avia-slideshow .avia-slideshow-controls a {
	opacity: 0;
	filter: alpha(opacity=0);
}

.avia_desktop .av_slideshow.avia-slideshow:hover .avia-slideshow-controls a {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.avia_desktop .av_slideshow.avia-slideshow:hover .avia-slideshow-controls a:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

/*avia-small-width-slider*/

#top .avia-small-width-slider .avia-slideshow-arrows a {
	width: 30px;
	height: 30px;
	margin: -15px 5px 0;
	line-height: 32px;
	font-size: 15px;
}

#top .avia-small-width-slider .avia-slideshow-arrows a:before {
	line-height: 32px;
}

#top .avia-super-small-width-slider .avia-slideshow-dots {
	display: none;
}

/*video slides*/
#top .av-video-slide .mejs-poster {
	width: 100% !important;
	height: 100% !important;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
}

#top .av-video-slide .mejs-poster img {
	display: none;
}

#top .av-video-slide .avia-iframe-wrap {
	padding: 0;
	height: 100%;
	margin: 0;
}

#top .av-video-slide iframe,
#top .av-video-slide embed,
#top .av-video-slide video {
	max-width: none;
	max-height: none;
	width: 100%;
	height: 100%;
	position: relative;
}

#top .av-video-slide .caption_fullwidth {
	top: 0;
	left: 0;
	right: 0;
	bottom: 40px;
}

#top .av-video-slide .mejs-container {
	height: 100% !important;
}

#top .av-video-slide .me-plugin {
	width: 100%;
	height: 100%;
}

#top .av-video-slide.av-video-service-vimeo iframe {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

#top .av-video-slide.av-video-service-vimeo .mejs-controls {
	display: none;
}

#top .av-video-slide .mejs-overlay-button {
	display: none;
}

/* ======================================================================================================================================================
Slideshow Fullwidth
====================================================================================================================================================== */
.avia-slideshow-inner li {
	-webkit-transform-style: preserve-3d; /*fixes flickering issue when slider moves. usually other elements in other container flicker */
}

.avia-fullwidth-slider {
	border-top-style: solid;
	border-top-width: 1px;
}

div.slider-not-first {
	border-top: none;
}

.avia-slide-wrap {
	position: relative;
	display: block;
}

.avia-slide-wrap > p {
	margin: 0;
	padding: 0;
}

.av_slideshow_full.avia-slideshow {
	margin: 0;
}

#top .av_slideshow_full .avia-slideshow-arrows a {
	margin: -50px 0 0 0;
	width: 35px;
	text-align: center;
	height: 100px;
	line-height: 102px;
	opacity: 0.5;
}

#top .av_slideshow_full .avia-slideshow-arrows a:hover {
	opacity: 1;
}

#top .av_slideshow_full .next-slide:before {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	line-height: 102px;
}

#top .av_slideshow_full .prev-slide:before {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	line-height: 102px;
}

.av_slideshow_full li img {
	border-radius: 0px;
}

/*slideshow captions Fullscreen slider*/
.caption_fullwidth {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
	z-index: 10;
}

.slideshow_caption {
	z-index: 100;
	width: 42%;
	position: absolute;
	bottom: 0;
	top: auto;
	height: 100%;
	display: block;
	text-decoration: none;
}

div .slideshow_caption h2 {
	text-transform: uppercase;
}

div .slideshow_caption,
div .slideshow_caption a,
div .slideshow_caption h2,
div .slideshow_caption h2 .special_amp,
div .slideshow_caption strong,
div .slideshow_caption h2 strong {
	color: #fff;
}

.slideshow_inner_caption {
	position: relative;
	display: table;
	height: 100%;
	width: 100%;
}

.slideshow_align_caption {
	display: table-cell;
	vertical-align: middle;
	position: relative;
}

.av_slideshow_full .container.caption_container {
	position: absolute;
	top: 0;
	z-index: 5;
	height: 100%;
	left: 50%;
	margin-left: -465px;
	overflow: visible;
}

.caption_left .slideshow_caption {
	left: 0;
}

.caption_right .slideshow_caption {
	right: 0;
}

.caption_bottom .slideshow_caption {
	left: 0;
	width: 100%;
	bottom: 0;
	height: auto;
}

#top div .caption_center .slideshow_caption {
	width: 100%;
	text-align: center;
}

.caption_bottom .slideshow_caption .slideshow_inner_caption {
	display: block;
	height: auto;
	bottom: 0;
	position: absolute;
	padding: 0;
	width: 100%;
}

.caption_bottom .slideshow_caption .slideshow_inner_caption .slideshow_align_caption {
	padding: 20px 0;
	display: block;
}

/*caption frame*/

.caption_framed .slideshow_caption .avia-caption-content p,
.caption_framed .slideshow_caption .avia-caption-title,
.avia-caption .avia-caption-content p,
.avia-caption .avia-caption-title {
	background: #000;
	filter: alpha(opacity=80);
	background: rgba(0, 0, 0, 0.5);
	display: inline-block;
	margin: 0 0 1px 0;
	padding: 10px 15px;
}

#top .avia-caption-content .wp-smiley {
	display: none;
}

.av_slideshow_full .container.caption_container {
	position: absolute;
	top: 0;
	z-index: 5;
	height: 100%;
	left: 50%;
	margin-left: -465px;
	overflow: visible;
}

.csstransitions .av_slideshow_full .avia-caption-content,
.csstransitions .av_slideshow_full .avia-caption-title,
.csstransitions .av_fullscreen .avia-caption-content,
.csstransitions .av_fullscreen .avia-caption-title,
.csstransitions .av_fullscreen .avia-slideshow-button {
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	visibility: hidden;
}

.csstransitions .av_slideshow_full .active-slide .avia-caption-content,
.csstransitions .av_fullscreen .active-slide .avia-caption-content {
	visibility: visible;
}

.csstransitions .av_fullscreen .active-slide .avia-slideshow-button {
	visibility: visible;
}

.csstransitions .av_slideshow_full .active-slide .avia-caption-title,
.csstransitions .av_fullscreen .active-slide .avia-caption-title {
	visibility: visible;
}

.csstransitions .av_slideshow_full.avia-fade-slider .active-slide .avia-caption-content,
.csstransitions .av_fullscreen.avia-fade-slider .active-slide .avia-caption-content {
	visibility: visible;
}

.csstransitions .av_slideshow_full.avia-fade-slider .active-slide .avia-caption-title,
.csstransitions .av_fullscreen.avia-fade-slider .active-slide .avia-caption-title {
	visibility: visible;
}

.csstransitions .av_fullscreen.avia-fade-slider .active-slide .avia-slideshow-button {
	visibility: visible;
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
	.responsive #top .slideshow_caption {
		left: 0;
		width: 100%;
		padding: 0;
	}

	.responsive #top .slideshow_caption h2 {
		font-size: 20px;
	}

	.responsive .av_slideshow_full .container.caption_container {
		margin-left: -138px;
	}
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.responsive #top .slideshow_caption {
		left: 0;
		width: 100%;
		padding: 0;
	}

	.responsive #top .slideshow_caption h2 {
		font-size: 20px;
	}

	.responsive .av_slideshow_full .container.caption_container {
		margin-left: -216px;
	}
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 989px) {
	.responsive .av_slideshow_full .container.caption_container {
		margin-left: -342px;
	}
}

@media only screen and (min-width: 1140px) {
	.responsive .av_slideshow_full .container.caption_container {
		margin-left: -515px;
	}
}

@media only screen and (min-width: 1340px) {
	.responsive_large .av_slideshow_full .container.caption_container {
		margin-left: -605px;
	}
}

/* ======================================================================================================================================================
Slideshow Fullscreen
====================================================================================================================================================== */

.avia-fullscreen-slider .avia_loading_icon {
	position: absolute;
}

.avia-fullscreen-slider .avia-slideshow {
	margin: 0;
	height: 1500px;
	min-height: 250px;
}

.avia-fullscreen-slider .avia-slideshow-inner {
	width: 100%;
	height: 100%;
}

.avia-fullscreen-slider .avia-slideshow > ul > li {
	width: 100%;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
}

.avia-fullscreen-slider .avia-slide-wrap {
	display: block;
	width: 100%;
	height: 100%;
}

.avia-fullscreen-slider .caption_container {
	height: 100%;
}

.avia-fullscreen-slider .caption_right {
	text-align: right;
}

.avia-fullscreen-slider .caption_bottom .slideshow_caption {
	bottom: 40px;
}

.avia-fullscreen-slider .avia-slideshow-controls a {
	opacity: 0;
	filter: alpha(opacity=00);
}

.avia-fullscreen-slider:hover .avia-slideshow-arrows a,
.avia-fullscreen-slider:hover .avia-slideshow-dots a:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.avia-fullscreen-slider:hover .avia-slideshow-dots a {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

#top .avia-slideshow-button {
	text-transform: uppercase;
	color: #fff;
	border-radius: 3px;
	border: 3px solid #fff;
	padding: 7px 16px;
	margin-top: 20px;
	display: inline-block;
	text-decoration: none;
	font-weight: bold;
	background-color: rgba(0, 0, 0, 0.2);
}

#top .avia-slideshow-button.avia-button-dark {
	color: #000;
	color: rgba(0, 0, 0, 0.6);
	border-color: #000;
	border-color: rgba(0, 0, 0, 0.6);
	background-color: rgba(255, 255, 255, 0.1);
}

#top .avia-slideshow-button:hover {
	opacity: 0.7;
}

@media only screen and (max-width: 767px) {
	.avia-fullscreen-slider .avia-slideshow {
		min-height: 150px;
	}
}

/* ======================================================================================================================================================
Layer Slider
====================================================================================================================================================== */
.ls-container.ls-fullwidth {
	margin-bottom: 0 !important;
}

.avia-layerslider {
	border-top-style: solid;
	border-top-width: 1px;
	overflow: hidden;
}

#top .avia-layerslider .ls-wp-container .ls-layer h1,
#top .avia-layerslider .ls-wp-container .ls-layer h2,
#top .avia-layerslider .ls-wp-container .ls-layer h3,
#top .avia-layerslider .ls-wp-container .ls-layer h4,
#top .avia-layerslider .ls-wp-container .ls-layer h5,
#top .avia-layerslider .ls-wp-container .ls-layer h6 {
	line-height: 1em;
}

#top .ls-nav-prev,
#top .ls-nav-next {
	display: block;
	text-decoration: none;
	color: #fff;
	position: absolute;
	width: 60px;
	text-align: center;
	height: 60px;
	line-height: 62px;
	font-size: 25px;
	top: 50%;
	margin: -30px 15px 0;
	background: transparent;
	color: #fff;
	visibility: hidden;
	opacity: 0.5;
	z-index: 10000;
	left: 30px;
}

.ls-inner div,
.ls-inner img {
	-webkit-backface-visibility: hidden;
}

#top .ls-nav-next {
	left: auto;
	right: 30px;
}

#top .ls-nav-prev:before {
	text-indent: -4px;
}

#top .ls-nav-prev:hover,
#top .ls-nav-next:hover {
	opacity: 1;
}

#top .ls-nav-prev:before,
#top .ls-nav-next:before {
	visibility: visible;
	display: block;
	position: absolute;
	z-index: 100;
	background: #000;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 3px;
	text-align: center;
	line-height: 62px;
}

#top .ls-bottom-slidebuttons a,
#top .ls-nav-start,
#top .ls-nav-stop {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 30px;
	background: #000;
	opacity: 0.3;
	filter: alpha(opacity=30);
	border: 2px solid #fff !important;
	text-indent: 300%;
	overflow: hidden;
	text-indent: 0;
	z-index: 10000;
}

#top .ls-bottom-slidebuttons {
	height: 30px;
}

#top .ls-bottom-slidebuttons a.ls-nav-active,
#top .ls-bottom-slidebuttons a:hover,
#top a.ls-nav-start:hover,
#top a.ls-nav-stop:hover,
#top a.ls-nav-start-active,
#top a.ls-nav-stop-active {
	opacity: 0.6;
	filter: alpha(opacity=60);
	background: #000;
}

#top .ls-nav-start,
#top .ls-nav-stop {
	position: relative;
	height: 23px;
	width: 23px;
	margin: 0px 5px;
	top: -35px;
}

#top .ls-nav-start:before,
#top .ls-nav-stop:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	color: #fff;
	line-height: 23px;
}

#top .ls-nav-start:before {
	text-indent: 1px;
}

div .ls-fullwidth .ls-thumbnail-hover {
	bottom: 39px;
	padding: 2px;
	margin-left: 2px;
}

div .ls-fullwidth .ls-bottom-slidebuttons,
div .ls-fullwidth div .ls-nav-start,
div .ls-fullwidth .ls-nav-stop,
div .ls-fullwidth .ls-nav-sides {
	top: -40px;
}

#top .ls-wp-container {
	margin: 0px auto !important;
}

.ls-bottom-nav-wrapper {
	position: relative;
}

html * div .ls-wp-container .ls-layer > *,
body * div .ls-wp-container .ls-layer > *,
#ls-global * div .ls-wp-container .ls-layer > * {
	line-height: 1em;
}

.ls-thumbnail-wrapper {
	bottom: 120px;
	opacity: 1 !important;
	display: block !important;
}

/*layerslider default iconfont values. overwriten in case user applies a custom font value*/
#top .ls-nav-prev:before {
	content: "\E87c";
}

#top .ls-nav-next:before {
	content: "\E87d";
}

#top .ls-nav-start:before {
	content: "\E897";
}

#top .ls-nav-stop:before {
	content: "\E899";
}

#top .ls-bottom-slidebuttons a,
#top .ls-nav-next:before,
#top .ls-nav-prev:before,
#top .ls-nav-start:before,
#top .ls-nav-stop:before {
	font-family: "entypo-fontello";
}

/* ======================================================================================================================================================
Partner/Logo Slider + Content Slider
====================================================================================================================================================== */

/*shared styles*/
#top .avia-smallarrow-slider {
	position: relative;
	overflow: visible;
}

#top .avia-smallarrow-slider .avia-slideshow-arrows {
	position: relative;
	width: 51px;
	top: -3px;
	left: 0;
	height: 24px;
}

#top .avia-smallarrow-slider .avia-slideshow-arrows a {
	opacity: 1;
	margin: 0;
	width: 24px;
	height: 24px;
	line-height: 25px;
	font-size: 10px;
	top: 0;
}

#top .avia-smallarrow-slider .avia-slideshow-arrows a:hover {
	opacity: 0.5;
}

#top .avia-smallarrow-slider .avia-slideshow-arrows a:before {
	line-height: 25px;
	border-radius: 1px;
}

#top .avia-smallarrow-slider .slide-image,
#top .avia-logo-element-container img {
	margin: 0 auto;
	display: block;
	width: 100%;
}

#top .avia-smallarrow-slider-heading {
	display: table;
	width: 100%;
}

#top .avia-smallarrow-slider-heading > div {
	display: table-cell;
}

#top .avia-smallarrow-slider-heading h3 {
	top: -3px;
	position: relative;
}

#top .avia-logo-grid .slide-entry-wrap {
	margin: 0;
}

#top .avia-smallarrow-slider .avia-slideshow-dots {
	position: relative;
	width: 100%;
	left: 0;
	bottom: 0;
	margin-top: 10px;
}

#top .avia-smallarrow-slider .avia-slideshow-dots a {
	border-style: solid;
	border-width: 1px;
	bottom: 0;
}

#top .avia-smallarrow-slider .avia-slideshow-dots a.active,
#top .avia-logo-element-container .avia-slideshow-dots a:hover {
	background: #aaa;
}

/*content only*/
#top .avia-content-slider-element-container .avia-slideshow-dots {
	left: 0;
	text-align: right;
	width: auto;
}

/*partner only*/
#top .avia-logo-element-container .slide-entry {
	padding: 1px;
}

#top .avia-logo-element-container img {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}

#top .av-border-deactivate.avia-logo-element-container img {
	box-shadow: none;
}

#top .avia-logo-element-container .slide-image {
	overflow: visible;
}

@media only screen and (max-width: 767px) {
	.responsive #top .avia-smallarrow-slider .flex_column {
		margin: 0px;
		width: 50%;
	}
}

#top .avia-content-slider-element-container .avia-slideshow-dots a.active,
#top .avia-content-slider-element-container .avia-slideshow-dots a:hover {
	background: #aaa;
}

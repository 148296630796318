///* News
.news-container {
	a.read-more {
		color: #ed7104;
		&:hover, &:focus, &:active {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.news-list {
	margin-top: 20px;
	.loading {
		text-align: center;
		padding: 2em 0;
		color: #e1e1e1;
	}

	.post {
		padding-bottom: 0;
		border-bottom: 1px solid #e1e1e1;
		margin-bottom: 10px;
		h6 {
			margin: 0;
		}
		p {
			margin: 0;
		}
		a, article {
			display: block;
			time, .date {
				font-size: 12px;
				color: #999;
				font-style: italic;
			}
			.read-more {
				color: #ed7104;
				display: block;
				margin-top: 8px;
				text-align: right;
			}
			&:hover {
				text-decoration: none;
				cursor: pointer;
				background: transparent !important;
				color: #333 !important;
				border: none;
				padding: 0;
				.read-more {
					text-decoration: underline;
				}
				p {
					text-decoration: none;
				}
			}
			&:focus {
				.title, .date {
					color: #fff;
				}
			}
		}

		img {
			float: left;
			margin: 5px 10px 0 0;
		}
	}
}